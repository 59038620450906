import { enqueueSnackbar } from 'notistack';

export const handleGraphqlErrors = (
  e: any,
  fallbackMessage = 'Oops! Something went wrong. Please try again later.'
) => {
  if (!e) {
    enqueueSnackbar(fallbackMessage, { variant: 'error' });
    return;
  }

  const gqlErrorMessage = e?.graphQLErrors?.[0]?.message || e?.message;
  const networkErrorMessage = e?.networkError?.result?.errors?.[0]?.message || e?.response?.data?.error?.message;

  const errorMessage = networkErrorMessage || gqlErrorMessage || fallbackMessage;

  if (process.env.REACT_APP_ENV === 'development') console.log('Response:', JSON.stringify(e, null, 2));

  enqueueSnackbar(errorMessage, { variant: 'error' });
};
