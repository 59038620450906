// eslint-disable-next-line import/default
import Charts from 'react-apexcharts';
import React, { useCallback } from 'react';
import { Skeleton, Card } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { DownloadReport } from '../../shared/download-report';
import { convertAmount } from '../../../utils/convert-amount';
import { useFunnelMetricsQuery, RequestType } from '../../../graphql';

const steps: any = {
  CHECKOUT: 'Checkout',
  LOGIN: 'Login',
  ADDRESS: 'Address',
  PAYMENT: 'Payment',
  PAYMENT_INITIATED: 'Initiated',
  ORDER: 'Orders'
};
type Props = {
  dateRange: any;
};

export const ConversionFunnel: React.FC<Props> = ({ dateRange }) => {
  const { data: graphData, loading } = useFunnelMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(dateRange.startDate).toISOString(),
        end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  const options: any = {
    chart: {
      animations: {
        enabled: false
      },
      type: 'area',
      offsetY: 10,
      stacked: false,
      zoom: {
        enabled: false
      },
      toolbar: {
        show: false
      }
    },
    tooltip: {
      enabled: false
    },
    markers: {
      enabled: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight',
      width: 2,
      show: true,
      colors: ['#2F72FF'],
      point: {}
    },

    legend: {
      show: false
    },
    xaxis: {
      min: (val: any) => {
        return val.index;
      },
      show: false,
      labels: {
        show: false
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    fill: {
      type: 'solid',
      colors: [
        'rgba(47, 114, 255, 0.167)',
        'rgba(47, 114, 255, 0.333)',
        'rgba(47, 114, 255, 0.5)',
        'rgba(47, 114, 255, 0.666)',
        'rgba(47, 114, 255, 0.823)',
        'rgba(47, 114, 255, 1)'
      ]
    },
    yaxis: {
      show: false,
      min: 0
    },
    grid: {
      show: false
    }
  };

  const calculateData = useCallback(() => {
    const data = graphData?.funnelMetrics?.current;

    return {
      series: [
        {
          name: 'Checkout',
          data: [data?.CHECKOUT || 0, data?.LOGIN || 0],
          x: 0
        },
        {
          name: 'Login',
          data: [null, data?.LOGIN || 0, data?.ADDRESS || 0],
          x: 1
        },
        {
          name: 'Address',
          data: [null, null, data?.ADDRESS || 0, data?.PAYMENT || 0]
        },
        {
          name: 'Payment',
          data: [null, null, null, data?.PAYMENT || 0, data?.PAYMENT_INITIATED || 0]
        },
        {
          name: 'Payment Initiated',
          data: [null, null, null, null, data?.PAYMENT_INITIATED || 0, data?.ORDER || 0]
        },
        {
          name: 'Orders',
          data: [null, null, null, null, null, data?.ORDER || 0, data?.ORDER || 0]
        }
        // {
        //   name: 'Orders',
        //   data: [null, null, null, null, totalOrders || 0, totalOrders || 0]
        // }
      ]
    };
  }, [graphData]);

  return (
    <Card className="relative col-span-1 sm:col-span-2 min-h-[15rem] overflow-x-scroll sm:overflow-hidden scrollbar-hide">
      <div className="pt-4 px-4 flex items-center justify-between sticky left-0 sm:static">
        <div className="text-black font-medium flex items-center">
          <div>Sales Conversion Funnel</div>
          <ButtonWithInfo
            heading="Sales Conversion Funnel"
            description={
              <div>
                <div className="mb-1">Representing the funnel from checkout to order.</div>
                <div className="mb-1">
                  Click on <span className="text-[#2F72FF] italic">Get report</span> to download the complete analysis
                  including user information.
                </div>
                <div>
                  <span className="font-medium">Note:</span> Checkout is the number of clicks on checkout button while
                  others are the count of unique visitors after login.
                </div>
              </div>
            }
            calculation={false}
          >
            <Unicons.UilInfoCircle size={15} className="ml-1.5" />
          </ButtonWithInfo>
        </div>
        {!loading && <DownloadReport dateRange={dateRange} requestType={RequestType.Funnel} />}
      </div>

      <div className="w-[650px] sm:w-auto">
        <div className="flex justify-between font-medium border-dotted text-[rgba(45,45,45,0.5)] px-4 mt-4">
          {Object.entries(steps).map(([key, value]: any) => {
            const funnelMetrics = graphData?.funnelMetrics?.current;
            const funnelMetricValue = (funnelMetrics as any)?.[key as keyof string] || 0;
            return (
              <div key={key} className="flex flex-col pt-2">
                {value}
                <div className="font-bold text-3xl text-black pt-2">
                  {!loading ? (
                    graphData?.funnelMetrics?.current && convertAmount(funnelMetricValue)
                  ) : (
                    <Skeleton size={'label'} className="w-full !m-0" />
                  )}
                </div>
              </div>
            );
          })}
          <div className="pt-2 invisible">Last</div>
        </div>
        {loading ? (
          <div className="p-4">
            <Skeleton className="w-full !m-0 h-[200px]" />
          </div>
        ) : (
          <>
            {graphData?.funnelMetrics?.current && graphData?.funnelMetrics?.current?.CHECKOUT !== 0 ? (
              <Charts options={options} series={calculateData().series} type="area" height={200} />
            ) : (
              <div className="text-center my-20">
                <div className="text-gray-300 text-base">Not Available</div>
              </div>
            )}
          </>
        )}
      </div>
    </Card>
  );
};
