import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { DiscountDialog } from '../discount-dialog';
import React, { FC, useMemo, useState } from 'react';
import { DiscountAmountSetup } from './discount-amount-setup';
import { DiscountFields } from '../../../types/coupon-data-types';
import { handleGraphqlErrors } from '../../../utils/graphql-errors';
import { DiscountEvent, EventCategory, trackEvents } from '../../../analytics';
import { getCouponInitialValues } from '../../../utils/coupons/coupon-initial-values';
import { discountAmountDataHandler } from '../../../utils/coupons/coupon-data-handler';
import { CouponType, useCreateCouponMutation, useUpdateCouponMutation } from '../../../graphql';
import { DiscountAmountSchema } from '../../../utils/validation-schemas/discount/discount-amount';
import { DiscountRequirement } from '../../shared/discount/discount-condition/discount-requirement';
import { DiscountUsageLimit } from '../../shared/discount/discount-usage-limit/discount-usage-limit';
import { DiscountEligibility } from '../../shared/discount/discount-eligibility/discount-eligibility';

type props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  couponDetails: any;
  setCouponDetails: (value: any) => void;
  refetch: () => void;
};

export const DiscountAmountDialog: FC<props> = ({ open, setOpen, couponDetails, setCouponDetails, refetch }) => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const [createCouponMutation] = useCreateCouponMutation();
  const [updateCoupon] = useUpdateCouponMutation();

  const handleSave = async (values: any) => {
    const newValues = cloneDeep(values);
    const body = discountAmountDataHandler(newValues);

    try {
      if (couponDetails?.id) {
        await updateCoupon({
          variables: {
            data: body,
            couponId: couponDetails.id
          }
        });
        trackEvents(EventCategory.COUPONS, DiscountEvent.DISCOUNT_EDITING_COMPLETED, {
          type: body.coupon_type
        });
      } else {
        await createCouponMutation({
          variables: {
            data: body
          }
        });
        trackEvents(EventCategory.COUPONS, DiscountEvent.DISCOUNT_ADDITION_COMPLETED, {
          type: body.coupon_type
        });
      }
      setOpen(false);
      setCouponDetails(null);
      enqueueSnackbar(`Hurray! Coupon ${couponDetails?.id ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e: any) {
      setOpen(true);
      body.application.products = couponDetails?.application?.products || [];
      body.application.collections = couponDetails?.application?.collections || [];
      body.purchase_requirement.products = couponDetails?.purchase_requirement?.products || [];
      body.purchase_requirement.collections = couponDetails?.purchase_requirement?.collections || [];
      setCouponDetails(body);

      handleGraphqlErrors(e);
    } finally {
      setActiveStep(0);
    }
  };

  const initialValues = useMemo(() => getCouponInitialValues(couponDetails, CouponType.CartAmount), [couponDetails]);

  return (
    <Formik<DiscountFields>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={DiscountAmountSchema}
      onSubmit={handleSave}
    >
      {({ errors, submitForm, resetForm, isSubmitting, setFieldTouched }) => {
        return (
          <>
            <DiscountDialog
              open={open}
              setOpen={setOpen}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              type={CouponType.CartAmount}
              errors={errors}
              discountDetails={couponDetails}
              setDiscountDetails={setCouponDetails}
              isSubmitting={isSubmitting}
              resetForm={resetForm}
              submitForm={submitForm}
              setFieldTouched={setFieldTouched}
              content={
                <>
                  {activeStep === 0 && <DiscountAmountSetup />}
                  {activeStep === 1 && <DiscountRequirement />}
                  {activeStep === 2 && <DiscountEligibility />}
                  {activeStep === 3 && <DiscountUsageLimit />}
                </>
              }
            />
          </>
        );
      }}
    </Formik>
  );
};
