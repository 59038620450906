import React from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { ReactComponent as PMUpiIcon } from '../../assets/images/pm-upi-bold.svg';
import { ReactComponent as PMCardIcon } from '../../assets/images/pm-card-bold.svg';
import { ReactComponent as PMEmiIcon } from '../../assets/images/pm-emi-bold.svg';
import { ReactComponent as PMWalletIcon } from '../../assets/images/pm-wallet-bold.svg';
import { ReactComponent as PMPayLaterIcon } from '../../assets/images/pm-pay-later-bold.svg';
import { ReactComponent as PMNetBankingIcon } from '../../assets/images/pm-net-banking-bold.svg';
import { ReactComponent as Razorpay } from '../../assets/images/razorpay-logo.svg';
import { ReactComponent as Cashfree } from '../../assets/images/cashfree-logo.svg';
import { ReactComponent as PhonePe } from '../../assets/images/phonepe-logo.svg';
import { ReactComponent as PayU } from '../../assets/images/payu-logo.svg';
import { ReactComponent as Easebuzz } from '../../assets/images/easebuzz-logo.svg';
import { ReactComponent as CCAvenue } from '../../assets/images/cc-avenue-logo.svg';
import { ReactComponent as Snapmint } from '../../assets/images/snapmint-logo.svg';

export const settingsHeader = [
  { title: 'Store Details', icon: <Unicons.UilStore />, route: '/settings/store' },
  { title: 'Payments', icon: <Unicons.UilCreditCard />, route: '/settings/payment' },
  { title: 'Taxes & Duties', icon: <Unicons.UilReceiptAlt />, route: '/settings/taxes' },
  { title: 'Integrations', icon: <Unicons.UilApps />, route: '/settings/integrations' },
  // { title: 'Billing & Usage', icon: <Unicons.UilBill />, route: '/settings/billing-usage' },
  { title: 'Users & Permissions', icon: <Unicons.UilLockAccess />, route: '/settings/account' },
  { title: 'Change Password', icon: <Unicons.UilLockAlt />, route: '/settings/change-password' }
];

export const changePasswordFields = {
  OLDPASSWORD: {
    primaryKey: 'oldPassword',
    value: 'Old Password'
  },
  NEWPASSWORD: {
    primaryKey: 'newPassword',
    value: 'New Password'
  },
  CONFIRMNEWPASSWORD: {
    primaryKey: 'confirmNewPassword',
    value: 'Confirm Password'
  },
  CHANGEPASSWORD: {
    primaryKey: 'changePasword',
    value: 'Change Password'
  }
};

export type PaymentMethodData = {
  [key: string]: {
    title: string;
    Icon: React.FC | string;
    paymentGateways: Array<string>;
  };
};

export const PaymentProviderIcons = {
  RAZORPAY: Razorpay,
  CCAVENUE: CCAvenue,
  PHONEPE: PhonePe,
  CASHFREE: Cashfree,
  EASEBUZZ: Easebuzz,
  PAYU: PayU,
  SNAPMINT: Snapmint
};

export const PaymentProviderFields = {
  RAZORPAY: {
    isAccountIdRequired: true,
    accountIdFieldName: 'Merchant ID',
    apiKeyFieldName: 'Key ID',
    apiSecretFieldName: 'Key Secret'
  },
  CCAVENUE: {
    isAccountIdRequired: true,
    accountIdFieldName: 'Merchant ID',
    apiKeyFieldName: 'Access Code',
    apiSecretFieldName: 'Working Key'
  },
  PHONEPE: {
    isAccountIdRequired: true,
    accountIdFieldName: 'Merchant ID',
    apiKeyFieldName: 'Key Index',
    apiSecretFieldName: 'API Key'
    // apiKeyFieldName: '-',
    // apiSecretFieldName: '-'
  },
  CASHFREE: {
    isAccountIdRequired: false,
    accountIdFieldName: '-',
    apiKeyFieldName: 'App ID',
    apiSecretFieldName: 'Secret Key'
  },
  EASEBUZZ: {
    isAccountIdRequired: false,
    accountIdFieldName: '-',
    apiKeyFieldName: 'Key',
    apiSecretFieldName: 'Salt'
  },
  PAYU: {
    isAccountIdRequired: false,
    accountIdFieldName: '-',
    apiKeyFieldName: 'Key',
    apiSecretFieldName: 'Salt'
  },
  SNAPMINT: {
    isAccountIdRequired: true,
    accountIdFieldName: 'Merchant ID',
    apiKeyFieldName: 'Merchant Key',
    apiSecretFieldName: 'Merchant Token'
  }
};

export const razorpayProdRedirect = (merchantId: string) =>
  `https://auth.razorpay.com/authorize?client_id=OcwEQqeXw36jtP&response_type=code&redirect_uri=https://api.flexype.in/v1/public/pg/razorpay/oauth/redirect&scope=read_write&state=${merchantId}`;
export const razorpayDevRedirect = (merchantId: string) =>
  `https://auth.razorpay.com/authorize?client_id=OcwEQI0OUoWUtE&response_type=code&redirect_uri=https://api.flexype.dev/v1/public/pg/razorpay/oauth/redirect&scope=read_write&state=${merchantId}`;

export enum PaymentProvider {
  NONE = 'NONE',
  RAZORPAY = 'RAZORPAY',
  CCAVENUE = 'CCAVENUE',
  PHONEPE = 'PHONEPE',
  EASEBUZZ = 'EASEBUZZ',
  CASHFREE = 'CASHFREE',
  PAYU = 'PAYU',
  CASH_ON_DELIVERY = 'CASH_ON_DELIVERY'
}

export const pathMap: Record<string, string> = {
  '/settings': 'Settings',
  '/settings/store': 'Store Details',
  '/settings/payment': 'Payment Methods',
  '/settings/taxes': 'Taxes & Duties',
  '/settings/integrations': 'Integrations',
  // '/settings/billing-usage': 'Billing & Usage',
  '/settings/account': 'Users & Permissions',
  '/settings/change-password': 'Change Password',
  '/settings/integrations/meta': 'Meta Pixels',
  '/settings/integrations/google-ads': 'Google Ads',
  '/settings/integrations/google-analytics': 'Google Analytics',
  '/settings/integrations/acr': 'Abandoned Cart Webhooks',
  '/settings/integrations/11za': '11za'
};

export const getPageName = (pathname: string, isMobile: boolean): string => {
  if (!isMobile) {
    return 'Settings';
  }
  return pathMap[pathname] || 'Unknown Page';
};

const paymentMethodProviders = [
  PaymentProvider.NONE,
  PaymentProvider.CCAVENUE,
  PaymentProvider.RAZORPAY,
  PaymentProvider.CASHFREE,
  PaymentProvider.EASEBUZZ,
  PaymentProvider.PAYU
];

const paymentMethodProvidersONLINE = [PaymentProvider.NONE, PaymentProvider.PHONEPE];

export const paymentMethods: PaymentMethodData = {
  UPI: {
    title: 'UPI / QR Code',
    Icon: PMUpiIcon,
    paymentGateways: paymentMethodProviders
  },
  EMI: {
    title: 'EMIs',
    Icon: PMEmiIcon,
    paymentGateways: paymentMethodProviders
  },
  ONLINE: {
    title: 'ONLINE',
    Icon: 'https://static.flexype.in/assets/checkout/UPI.gif',
    paymentGateways: paymentMethodProvidersONLINE
  },
  CARD: {
    title: 'Debit/Credit cards',
    Icon: PMCardIcon,
    paymentGateways: paymentMethodProviders
  },
  WALLET: {
    title: 'Wallets',
    Icon: PMWalletIcon,
    paymentGateways: paymentMethodProviders
  },
  PAY_LATER: {
    title: 'Pay Later',
    Icon: PMPayLaterIcon,
    paymentGateways: paymentMethodProviders
  },
  NET_BANKING: {
    title: 'Netbanking',
    Icon: PMNetBankingIcon,
    paymentGateways: paymentMethodProviders
  }
};

export const indianStates = [
  { state_code: 'DEFAULT', state: 'India' },
  { state_code: 'AN', state: 'Andaman and Nicobar Islands' },
  { state_code: 'AP', state: 'Andhra Pradesh' },
  { state_code: 'AR', state: 'Arunachal Pradesh' },
  { state_code: 'AS', state: 'Assam' },
  { state_code: 'BR', state: 'Bihar' },
  { state_code: 'CH', state: 'Chandigarh' },
  { state_code: 'CG', state: 'Chhattisgarh' },
  { state_code: 'DN', state: 'Dadra and Nagar Haveli' },
  { state_code: 'DD', state: 'Daman and Diu' },
  { state_code: 'DL', state: 'Delhi' },
  { state_code: 'GA', state: 'Goa' },
  { state_code: 'GJ', state: 'Gujarat' },
  { state_code: 'HR', state: 'Haryana' },
  { state_code: 'HP', state: 'Himachal Pradesh' },
  { state_code: 'JK', state: 'Jammu and Kashmir' },
  { state_code: 'JH', state: 'Jharkhand' },
  { state_code: 'KA', state: 'Karnataka' },
  { state_code: 'KL', state: 'Kerala' },
  { state_code: 'LA', state: 'Ladakh' },
  { state_code: 'LD', state: 'Lakshadweep' },
  { state_code: 'MP', state: 'Madhya Pradesh' },
  { state_code: 'MH', state: 'Maharashtra' },
  { state_code: 'MN', state: 'Manipur' },
  { state_code: 'ML', state: 'Meghalaya' },
  { state_code: 'MZ', state: 'Mizoram' },
  { state_code: 'NL', state: 'Nagaland' },
  { state_code: 'OR', state: 'Odisha' },
  { state_code: 'PY', state: 'Puducherry' },
  { state_code: 'PB', state: 'Punjab' },
  { state_code: 'RJ', state: 'Rajasthan' },
  { state_code: 'SK', state: 'Sikkim' },
  { state_code: 'TN', state: 'Tamil Nadu' },
  { state_code: 'TS', state: 'Telangana' },
  { state_code: 'TR', state: 'Tripura' },
  { state_code: 'UP', state: 'Uttar Pradesh' },
  { state_code: 'UK', state: 'Uttarakhand' },
  { state_code: 'WB', state: 'West Bengal' }
];
