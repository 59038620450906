import React from 'react';
import { Button, Card, Chip, Label, RadioGroup, RadioGroupItem, Switch } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { ConsiderationType, RtoSuitQuery } from '../../../graphql';
import Dropzone from 'react-dropzone';
import { useFormikContext } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { pinCodesUpload } from '../../../network/shipping/shipping';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import { AccordionContent, AccordionItem, AccordionTrigger } from '../../flexyui/Accordion';
import { EventCategory, RtoSuiteEvents, trackEvents } from '../../../analytics';

const FilterPinCodes = () => {
  const { values, setFieldValue } = useFormikContext<RtoSuitQuery>();
  const { setShowActions, setLoadingActions } = useSaveCancelButtons();

  const pinCodesUploadMutation = async (formData: FormData) => {
    const response = await pinCodesUpload(formData);
    return response;
  };

  const handleFileChange = async (file: File[]) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('file_purpose', 'COD_FILTER_PIN_CODES');
      if (file[0].size > 1024 * 1024 * 10) {
        enqueueSnackbar('Oops! File size exceeds the limit (10 MB).', {
          variant: 'error'
        });
        return;
      }
      try {
        const response = await pinCodesUploadMutation(formData);
        setFieldValue('codAvailabilityConfig.pincode_filter.resource_id', response.data.resource_id);
        setFieldValue('codAvailabilityConfig.pincode_filter.resource_item.file_original_name', file[0].name);
        enqueueSnackbar('Hurray! Pin Codes are uploaded successfully', {
          variant: 'success'
        });
        trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.ADD_PINCODE_FILTER);
      } catch (error) {
        // resetForm();
        setShowActions(false);
        setLoadingActions(false);
        enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
          variant: 'error'
        });
      }
    }
  };

  return (
    <AccordionItem value="item-1" className="border-b">
      <div className="px-4">
        <AccordionTrigger>
          <div className="flex flex-col items-start space-y-1">
            <Label size="md" className="text-sm cursor-pointer flex items-center gap-2">
              <div>Filter based on PIN Codes</div>
              {values?.codAvailabilityConfig?.pincode_filter?.enabled && (
                <Chip
                  text={values?.codAvailabilityConfig?.pincode_filter?.enabled ? 'Enabled' : 'Disabled'}
                  variant={values?.codAvailabilityConfig?.pincode_filter?.enabled ? 'success' : 'draft'}
                />
              )}
            </Label>
            <Label className="text-[#888D9B] text-left max-w-[95%] sm:max-w-full" size={'sm'}>
              Choose a set of pin codes to include or exclude from cash on delivery orders.
            </Label>
          </div>
        </AccordionTrigger>
      </div>
      <AccordionContent className="bg-white p-3 border-t border-[#E3E3E3]">
        <div className="flex items-center gap-2 mb-4 ml-0.5">
          <Switch
            checked={values?.codAvailabilityConfig?.pincode_filter?.enabled}
            onCheckedChange={(value) => {
              setFieldValue('codAvailabilityConfig.pincode_filter.enabled', value);
              trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.PINCODE_FILTER_STATUS_CHANGED);
            }}
            id="codAvailabilityConfig.pincode_filter.enabled"
          />
          <Label size={'paragraph'} className="text-[#595F74]" htmlFor="codAvailabilityConfig.pincode_filter.enabled">
            Enable PIN Code filtering
          </Label>
        </div>
        <Card className="w-full sm:w-[45%] flex items-center justify-between p-3 mb-2">
          <div className="p-2 w-fit rounded-full border border-muted">
            {values?.codAvailabilityConfig?.pincode_filter?.resource_item ? (
              <Unicons.UilFileAlt className="text-[#595F74]" />
            ) : (
              <Unicons.UilExport className="text-[#595F74]" />
            )}
          </div>
          <div className="flex flex-col">
            <Label variant={'default'} size={'paragraph'} className="font-medium">
              {values?.codAvailabilityConfig?.pincode_filter?.resource_item?.file_original_name
                ? values?.codAvailabilityConfig?.pincode_filter?.resource_item?.file_original_name
                : 'Upload CSV'}
            </Label>
            <Label size={'sm'} className="text-[#888D9B]">
              {values?.codAvailabilityConfig?.pincode_filter?.resource_item
                ? 'PIN Codes'
                : 'Upload file from your computer.'}
            </Label>
          </div>
          <Dropzone
            onDrop={(acceptedFiles) => {
              handleFileChange(acceptedFiles);
            }}
            maxFiles={1}
            multiple={false}
            accept={{
              'text/csv': ['.csv']
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                {values?.codAvailabilityConfig?.pincode_filter?.resource_item ? (
                  <div className="flex space-x-3">
                    {values?.codAvailabilityConfig?.pincode_filter?.resource_item?.download_url ? (
                      <a
                        href={values?.codAvailabilityConfig?.pincode_filter?.resource_item?.download_url}
                        download={values?.codAvailabilityConfig?.pincode_filter?.resource_item?.file_original_name}
                        onError={(e) => {
                          console.log('Download error', e);
                        }}
                      >
                        <Button size="icon" variant="icon">
                          <Unicons.UilDownloadAlt />
                        </Button>
                      </a>
                    ) : null}
                    <Button
                      size="icon"
                      variant="icon"
                      onClick={() => {
                        // TODO check this properly
                        setFieldValue('codAvailabilityConfig.pincode_filter.resource_id', null);
                        setFieldValue('codAvailabilityConfig.pincode_filter.resource_item', null);
                        trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.REMOVE_PINCODE_FILTER);
                      }}
                    >
                      <Unicons.UilTrashAlt />
                    </Button>
                  </div>
                ) : (
                  <Button size={'md'} variant={'outline'} {...getRootProps()}>
                    {'Upload'}
                    <input name="pincode" {...getInputProps()} accept=".csv" />
                  </Button>
                )}
              </>
            )}
          </Dropzone>
        </Card>
        <Label className="text-[#595F74] text-xs font-normal">
          Please make sure that the file you provided is in the right format.{' '}
          <Label className="font-medium text-xs underline cursor-pointer">
            <a href="https://static.flexype.in/assets/checkout/pincodes_example.csv" download={'pincodes_sample'}>
              Download a sample file.
            </a>
          </Label>
        </Label>
        <div className="mb-3 mt-4">
          <Label size="md" className="mb-2 text-sm">
            PIN Code Type
          </Label>
          <RadioGroup
            className="mt-1"
            defaultValue={ConsiderationType.AllowSelectionsOnly}
            value={
              values?.codAvailabilityConfig?.pincode_filter?.consideration_type || ConsiderationType.AllowSelectionsOnly
            }
            onValueChange={(value: ConsiderationType) => {
              setFieldValue('codAvailabilityConfig.pincode_filter.consideration_type', value);
              trackEvents(EventCategory.RTO_SUITE, RtoSuiteEvents.PINCODE_FILTER_TYPE, {
                consideration_type: value
              });
            }}
          >
            <div className="flex items-start space-x-2">
              <RadioGroupItem
                value={ConsiderationType.AllowSelectionsOnly}
                id="codAvailabilityConfig.pincode_filter.consideration_type.allowSelectionsOnly"
                className="mt-0.5"
              />
              <Label
                size={'paragraph'}
                className="text-[#595F74]"
                htmlFor="codAvailabilityConfig.pincode_filter.consideration_type.allowSelectionsOnly"
              >
                Allow COD orders only from these PIN Codes
              </Label>
            </div>
            <div className="flex items-start space-x-2">
              <RadioGroupItem
                value={ConsiderationType.BlockSelections}
                id="codAvailabilityConfig.pincode_filter.consideration_type.blockSelections"
                className="mt-0.5"
              />
              <Label
                size={'paragraph'}
                className="text-[#595F74]"
                htmlFor="codAvailabilityConfig.pincode_filter.consideration_type.blockSelections"
              >
                Block COD orders from these PIN Codes
              </Label>
            </div>
          </RadioGroup>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default FilterPinCodes;
