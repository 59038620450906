import mixpanel from 'mixpanel-browser';
import { EventCategory, SessionEvents } from './events';

export const setMixpanelMerchantGroup = (merchantId: string, merchantProperties = {}) => {
  mixpanel.set_group('merchant', merchantId);

  if (Object.keys(merchantProperties).length) {
    mixpanel.get_group('merchant', merchantId).set(merchantProperties);
  }
};

export const setMixpanelUser = (userId: string, userProperties = {}) => {
  mixpanel.identify(userId);

  if (Object.keys(userProperties).length) {
    mixpanel.people.set(userProperties);
  }
};

export const setMixpanelSuperProperties = (properties: Record<string, any>) => {
  if (!properties || typeof properties !== 'object') return;

  mixpanel.register(properties);
};

export const trackSessionStart = (properties = {}) => {
  mixpanel.track(SessionEvents.SESSION_START, {
    category: EventCategory.SESSION,
    ...properties
  });
};

export const trackSessionEnd = (properties = {}) => {
  mixpanel.track(SessionEvents.SESSION_END, {
    category: EventCategory.SESSION,
    ...properties
  });
};

export const trackPageView = (event: string, properties = {}) => {
  mixpanel.track(event, {
    category: EventCategory.PAGE_VIEW,
    ...properties
  });
};

export const trackEvents = (category: EventCategory, event: any, properties = {}) => {
  mixpanel.track(event, {
    category,
    ...properties
  });
};
