/* eslint-disable camelcase */
import { isEqual } from 'lodash';
import classNames from 'classnames';
import { Skeleton } from '../../flexyui';
import { Input } from '../../flexyui/Input';
import { enqueueSnackbar } from 'notistack';
import { Button } from '../../flexyui/Button';
import { PriceDetails } from './price-details';
import { Checkbox } from '../../flexyui/Checkbox';
import React, { useEffect, useState } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle
} from '../../flexyui/AlertDialog';
import {
  CalculateLineItem,
  Summary,
  useAddVariantToOrderMutation,
  useDeleteLineItemMutation,
  useEditLineItemQuantityMutation,
  useEndSessionBySavingMutation,
  useStartOrderEditSessionMutation
} from '../../../graphql';
import { OrderSummary } from './order-summary';
import { ApplyDiscount } from './apply-discount';
import * as Unicons from '@iconscout/react-unicons';
import { LineItemSkeleton } from './line-item-skeleton';
import { OrderEditProductSelectionDialog } from './product-selection';
import { EventCategory, OrderEditEvents, trackEvents } from '../../../analytics';
import ConfirmationDialog from '../../shared/confirmation-dialog/confirmation-dialog';
import { ReactComponent as LoadingIcon } from '../../../assets/images/loading.svg';

type Props = {
  order: any;
  refetch: () => void;
  open: boolean;
  setOpen: (value: boolean) => void;
};

export const OrderEdit: React.FC<Props> = ({ order, refetch, open, setOpen }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [sendInvoice, setSendInvoice] = useState<boolean>(false);
  const [openProductSelection, setOpenProductSelection] = useState<boolean>(false);
  const [calculatedOrderId, setCalculatedOrderId] = useState<string>('');
  const [initialCalculatedLineItems, setInitialCalculatedLineItems] = useState<Array<CalculateLineItem>>([]);
  const [calculatedLineItems, setCalculatedLineItems] = useState<Array<CalculateLineItem>>([]);
  const [orderEditSummary, setOrderEditSummary] = useState<Summary>({});
  const [discountLineItem, setDiscountLineItem] = useState<any>(null);
  const [selectedVariantIds, setSelectedVariantIds] = useState<Array<number>>([]);
  const [openApplyDiscount, setOpenApplyDiscount] = useState<boolean>(false);
  const [editReason, setEditReason] = useState<string>('');
  const [selectionSearch, setSelectionSearch] = useState<string>('');

  const [startOrderEditSession, { loading: startOrderEditSessionLoading }] = useStartOrderEditSessionMutation();
  const [addVariantToOrder, { loading: addVariantToOrderLoading }] = useAddVariantToOrderMutation();
  const [deleteLineItem, { loading: deleteLineItemLoading }] = useDeleteLineItemMutation();
  const [editLineItemQuantity, { loading: editLineItemQuantityLoading }] = useEditLineItemQuantityMutation();
  const [endSessionBySaving, { loading: endSessionBySavingLoading }] = useEndSessionBySavingMutation();

  const handleErrors = (errors: any[] | null | undefined) => {
    if ((errors && errors?.length) || 0 > 0) {
      errors?.forEach((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
    }
  };
  const initializeOrderEditSession = async () => {
    try {
      await startOrderEditSession({
        variables: {
          orderId: order?.platform_order_id
        }
      }).then((res) => {
        const responseData = res?.data?.startOrderEditSession;

        if (responseData) {
          const { calculated_line_items, calculated_order_id, summary } = responseData;
          if (calculated_line_items) {
            const newcalculatedLineItems = calculated_line_items?.reverse() as CalculateLineItem[];
            setInitialCalculatedLineItems(newcalculatedLineItems);
            setCalculatedLineItems(newcalculatedLineItems);
          }
          if (calculated_order_id) setCalculatedOrderId(calculated_order_id);
          if (summary) setOrderEditSummary(summary);

          trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.SESSION_START, {
            orderId: order?.platform_order_id
          });
        }
      });
    } catch (error) {
      console.error('Error starting order edit session:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };
  const handleAddVariantToOrder = async () => {
    try {
      for (const selectedVariantId of selectedVariantIds) {
        await addVariantToOrder({
          variables: {
            calculatedOrderId: calculatedOrderId,
            variantId: `gid://shopify/ProductVariant/${selectedVariantId}`
          }
        }).then((res) => {
          const responseData = res?.data?.addVariantToOrder;

          if (responseData) {
            const { calculated_line_items, summary, errors } = responseData;
            if (calculated_line_items) {
              setCalculatedLineItems(calculated_line_items?.reverse() as CalculateLineItem[]);
            }
            if (summary) setOrderEditSummary(summary);
            handleErrors(errors);
          }

          trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.ADD_PRODUCT, {
            calculatedOrderId: calculatedOrderId,
            variantId: `gid://shopify/ProductVariant/${selectedVariantId}`
          });
        });
      }
    } catch (error) {
      console.error('Error while adding variant:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setSelectedVariantIds([]);
  };
  const handleDeleteLineItem = async (lineItem: CalculateLineItem) => {
    try {
      await deleteLineItem({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id
        }
      }).then((res) => {
        const responseData = res?.data?.deleteLineItem;

        if (responseData) {
          const { calculated_line_items, summary, errors } = responseData;
          if (calculated_line_items) {
            setCalculatedLineItems(calculated_line_items?.reverse() as CalculateLineItem[]);
          }
          if (summary) setOrderEditSummary(summary);
          handleErrors(errors);

          trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.REMOVE_PRODUCT, {
            calculatedOrderId: calculatedOrderId,
            calculatedLineItemId: lineItem.calculated_line_item_id
          });
        }
      });
    } catch (error) {
      console.error('Error while deleting variant:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setSelectedVariantIds([]);
  };
  const handleEditLineItemQuantity = async (lineItem: CalculateLineItem, functionality: string) => {
    const updatedQuantity = functionality === 'decrement' ? lineItem?.quantity - 1 : lineItem?.quantity + 1;

    try {
      await editLineItemQuantity({
        variables: {
          calculatedOrderId: calculatedOrderId,
          calculatedLineItemId: lineItem.calculated_line_item_id,
          quantity: updatedQuantity
        }
      }).then((res) => {
        const responseData = res?.data?.editLineItemQuantity;

        if (responseData) {
          const { calculated_line_items, summary, errors } = responseData;
          if (calculated_line_items) {
            setCalculatedLineItems(calculated_line_items?.reverse() as CalculateLineItem[]);
          }
          if (summary) setOrderEditSummary(summary);
          handleErrors(errors);

          trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.EDIT_QUANTITY, {
            calculatedOrderId: calculatedOrderId,
            calculatedLineItemId: lineItem.calculated_line_item_id,
            quantity: updatedQuantity
          });
        }
      });
    } catch (error) {
      console.error('Error while changing variant quantity:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }

    setSelectedVariantIds([]);
  };
  const handleEndSessionBySaving = async () => {
    try {
      await endSessionBySaving({
        variables: {
          calculatedOrderId: calculatedOrderId,
          sendInvoiceToCustomer: sendInvoice,
          reasonForEdit: editReason
        }
      }).then((res) => {
        const responseData = res?.data?.endSessionBySaving;
        if (responseData) {
          refetch();
          setOpen(false);

          trackEvents(EventCategory.ORDERS_EDIT, OrderEditEvents.SESSION_END, {
            orderId: order?.platform_order_id,
            calculatedOrderId: calculatedOrderId,
            sendInvoiceToCustomer: sendInvoice,
            reasonForEdit: editReason
          });
        }
      });
    } catch (error) {
      console.error('Error while saving order edit session:', error);
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const isDisabled =
    startOrderEditSessionLoading ||
    addVariantToOrderLoading ||
    deleteLineItemLoading ||
    editLineItemQuantityLoading ||
    endSessionBySavingLoading;

  useEffect(() => {
    if (open) initializeOrderEditSession();
    else {
      setCalculatedOrderId('');
      setCalculatedLineItems([]);
    }
  }, [open]);

  useEffect(() => {
    if (selectedVariantIds.length > 0) handleAddVariantToOrder();
  }, [selectedVariantIds]);

  useEffect(() => {
    if (selectionSearch.length > 0) setOpenProductSelection(true);
    else setOpenProductSelection(false);
  }, [selectionSearch]);

  return (
    <>
      <AlertDialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <AlertDialogContent className="!gap-0" size="lg">
          <div>
            <AlertDialogHeader>
              <AlertDialogTitle className="pl-4 pr-2.5 py-2.5">
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 font-semibold">
                    <span>Edit Order</span>
                    <span className="font-normal">{order?.name}</span>
                  </div>
                  <Button
                    size="icon"
                    variant="ghost"
                    onClick={() => {
                      if (!isEqual(initialCalculatedLineItems, calculatedLineItems)) setShowModal(true);
                      else setOpen(false);
                    }}
                  >
                    <Unicons.UilTimes className="text-[#2F72FF]" />
                  </Button>
                </div>
              </AlertDialogTitle>
              <AlertDialogDescription className="!p-0 !m-0">
                <div className="w-full h-[80vh] flex box-border">
                  <div className="w-[60%] border-r-[1px] p-4">
                    <div className="border-[1px] rounded-xl">
                      <div className="text-base text-black font-semibold p-4 flex items-center justify-between">
                        <div className="!w-[calc(100%-90px)]">
                          <Input
                            type="text"
                            placeholder="Search Products..."
                            className="!m-0 rounded-lg font-normal"
                            value={selectionSearch}
                            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                              setSelectionSearch(e.target.value);
                            }}
                          />
                        </div>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => setOpenProductSelection(true)}
                          disabled={isDisabled}
                        >
                          Browse
                        </Button>
                      </div>
                      <hr />
                      <div className="h-[calc(80vh-105px)] overflow-y-auto scroll-smooth">
                        {addVariantToOrderLoading && <LineItemSkeleton />}
                        {startOrderEditSessionLoading && [0, 1, 2].map((index) => <LineItemSkeleton key={index} />)}
                        {!isDisabled && calculatedLineItems?.length === 0 && (
                          <div className="w-full h-[80%] flex items-center justify-center">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => setOpenProductSelection(true)}
                              disabled={isDisabled}
                            >
                              <Unicons.UilPlus size={18} className="mr-2" />
                              Add products
                            </Button>
                          </div>
                        )}

                        {calculatedLineItems
                          ?.filter((each) => each?.quantity > 0)
                          ?.map((data) => (
                            <div key={data?.calculated_line_item_id} className="p-4 border-b-[1px] last:border-b-0">
                              <div className="flex items-center justify-between ">
                                <div className="flex items-center justify-between w-[70%]">
                                  <div className="flex items-center gap-2">
                                    <div className="w-[48px] flex justify-center">
                                      {data?.image ? (
                                        <img src={data?.image} className="h-[48px] rounded-md" />
                                      ) : (
                                        <div className="border rounded-lg border-black-200 border-solid p-2">
                                          <Unicons.UilImage style={{ height: '1.5rem', width: '1.5rem' }} />
                                        </div>
                                      )}
                                    </div>
                                    <div className="text-[#121B38]">
                                      <div className="font-semibold sm:max-w-[265px] xl:max-w-[320px] truncate">
                                        {data?.title}
                                      </div>
                                      {(data?.number_of_variants || 1) > 1 ? (
                                        <div className="bg-[#D9D9D9] py-0.5 px-2 rounded-lg w-fit text-xs mt-0.5">
                                          {data?.variant_title}
                                        </div>
                                      ) : (
                                        <PriceDetails data={data} className="" />
                                      )}
                                    </div>
                                  </div>
                                  <div className="text-black font-medium">
                                    {data?.discount?.amount_after_discount !== null &&
                                    Number(data?.discount?.amount_after_discount) === 0
                                      ? 'Free'
                                      : `₹${(
                                          Number(data?.discount?.amount_after_discount ?? data?.price) *
                                          Number(data?.quantity)
                                        ).toFixed(2)}`}
                                  </div>
                                </div>
                                <div className="flex flex-row-reverse items-center gap-4 w-[30%]">
                                  <Button
                                    size="icon"
                                    variant="icon"
                                    disabled={isDisabled}
                                    className="px-2 py-[1.2rem] rounded-xl hover:bg-[#f7f7f7]"
                                    onClick={() => handleDeleteLineItem(data)}
                                  >
                                    <Unicons.UilTrashAlt
                                      size={'26'}
                                      className="p-[2px] text-[#595F74] cursor-pointer"
                                    />
                                  </Button>
                                  {data?.is_editable ? (
                                    <div className="flex items-center justify-center px-2 rounded-xl border border-input">
                                      <Unicons.UilMinus
                                        size={'20'}
                                        className={`text-[#595F74] cursor-pointer ${
                                          data?.quantity === 1 || (isDisabled && 'pointer-events-none')
                                        }`}
                                        onClick={() => {
                                          if (data?.quantity !== 1 && !isDisabled)
                                            handleEditLineItemQuantity(data, 'decrement');
                                        }}
                                      />
                                      <Input
                                        type="number"
                                        name="quantity"
                                        className="!m-0 !px-0 text-center w-[40px] border-0 text-black focus-visible:ring-0 focus-visible:ring-offset-0 !bg-white !cursor-auto"
                                        value={data?.quantity}
                                        disabled
                                      />
                                      <Unicons.UilPlus
                                        size={'20'}
                                        className={`text-[#595F74] cursor-pointer ${isDisabled && 'pointer-events-none'}`}
                                        onClick={() => {
                                          if (!isDisabled) handleEditLineItemQuantity(data, 'increment');
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <Input
                                      type="number"
                                      name="quantity"
                                      className="!m-0 rounded-xl w-[70px] text-center text-black focus-visible:ring-0 bg-white !px-3"
                                      value={data?.quantity}
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>
                              {(data?.number_of_variants || 1) > 1 && (
                                <PriceDetails data={data} className="mt-1 pl-[57px]" />
                              )}
                              {data?.is_editable && (
                                <div
                                  className={classNames(
                                    'text-[#2F72FF] text-sm pl-[57px] mt-1.5 cursor-pointer w-fit ',
                                    {
                                      'pointer-events-none': isDisabled
                                    }
                                  )}
                                  onClick={() => {
                                    setDiscountLineItem(data);
                                    setOpenApplyDiscount(true);
                                  }}
                                >
                                  {data?.calculated_discount_application_id === null
                                    ? 'Apply discount'
                                    : 'Edit discount'}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-[40%] p-4">
                    <div className="border-[1px] rounded-xl mb-4">
                      <div className="text-black font-semibold px-4 py-2">Summary</div>
                      <hr />
                      <div className="px-4 py-4 text-[#121B38]">
                        {isDisabled ? (
                          [0, 1, 2, 3].map((index) => (
                            <div key={`${index}_summary`} className="flex items-center justify-between pb-2">
                              <Skeleton size="md" className="w-32" />
                              <Skeleton size="md" />
                            </div>
                          ))
                        ) : (
                          <OrderSummary summary={orderEditSummary} />
                        )}
                      </div>
                    </div>
                    <div className="border-[1px] rounded-xl mb-4">
                      <div className="text-black font-semibold px-4 py-2">Reason for edit</div>
                      <hr />
                      <div className="p-3">
                        <Input
                          type="text"
                          name="update_reason"
                          className="!m-0 rounded-md text-black"
                          value={editReason}
                          onChange={(e) => setEditReason(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex items-center gap-2 mb-0.5">
                        <Checkbox
                          id="send-invoice"
                          checked={sendInvoice}
                          onChange={() => setSendInvoice(!sendInvoice)}
                        />
                        <label className="text-black" htmlFor="send-invoice">
                          Send invoice to customer
                        </label>
                      </div>
                      {!sendInvoice && (
                        <div className="text-[rgb(89,95,116)] leading-4 pl-6 text-xs">
                          Your customer won’t be notified of these changes.
                        </div>
                      )}
                    </div>
                    <Button
                      variant={'default'}
                      size={'md'}
                      onClick={handleEndSessionBySaving}
                      disabled={isDisabled || isEqual(initialCalculatedLineItems, calculatedLineItems)}
                      className={`w-full max-w-full rounded-lg ${endSessionBySavingLoading && '!bg-primary'}`}
                    >
                      {endSessionBySavingLoading ? (
                        <LoadingIcon height={20} className={'animate-spin text-white'} />
                      ) : (
                        'Update Order'
                      )}
                    </Button>
                  </div>
                </div>
              </AlertDialogDescription>
            </AlertDialogHeader>
          </div>
        </AlertDialogContent>
      </AlertDialog>

      <ApplyDiscount
        lineItem={discountLineItem}
        calculatedOrderId={calculatedOrderId}
        setDiscountLineItem={setDiscountLineItem}
        setCalculatedLineItems={setCalculatedLineItems}
        openApplyDiscount={openApplyDiscount}
        setOpenApplyDiscount={setOpenApplyDiscount}
        setOrderEditSummary={setOrderEditSummary}
      />

      <OrderEditProductSelectionDialog
        key={'OrderEditProductSelection'}
        search={selectionSearch}
        open={openProductSelection}
        setOpen={(val: boolean) => {
          setOpenProductSelection(val);
          setSelectionSearch('');
        }}
        preSelections={calculatedLineItems}
        onSave={(value: any) => {
          setSelectedVariantIds(value);
          setSelectionSearch('');
        }}
      />

      <ConfirmationDialog
        showModal={showModal}
        setShowModal={setShowModal}
        onSave={() => setOpen(false)}
        headerText="Discard changes"
        text="This action will discard changes. Are you sure you want to continue?"
        confirmButtonText="Discard"
        confirmActionVariant="destructive"
        loading={false}
      />
    </>
  );
};
