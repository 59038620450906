/* eslint-disable camelcase */
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { StepContent } from './step-content';
import React, { useEffect, useMemo } from 'react';
import { StepCallback } from '@/src/pages/onboarding/onboarding';
import { BusinessCategory, RegistrationType } from '../../constants/onboarding';
import { useGetBusinessDetailQuery, useUpdateBusinessDetailMutation } from '../../graphql';
import { Label, Select as SelectCN, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../flexyui';

type Props = {
  activeStep: number;
  setStepCallback: (callback: StepCallback) => void;
  setDisabled: (disabled: boolean) => void;
};

export const BusinessType: React.FC<Props> = ({ activeStep, setStepCallback, setDisabled }) => {
  const { data, refetch } = useGetBusinessDetailQuery();
  const [updatedata] = useUpdateBusinessDetailMutation();

  const handleSave = async (values: any) => {
    try {
      await updatedata({
        variables: {
          detail: values
        }
      });

      await refetch();
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  const initialValues = useMemo(() => {
    return {
      category: data?.getBusinessDetail?.category || '',
      registration_type: data?.getBusinessDetail?.registration_type || '',
      legal_name: data?.getBusinessDetail?.legal_name || null,
      trade_name: data?.getBusinessDetail?.trade_name || null,
      GSTIN: data?.getBusinessDetail?.GSTIN || null,
      company_name_on_gst: data?.getBusinessDetail?.company_name_on_gst || null,
      address: {
        address1: data?.getBusinessDetail?.address?.address1 || null,
        address2: data?.getBusinessDetail?.address?.address2 || null,
        city: data?.getBusinessDetail?.address?.city || null,
        state: data?.getBusinessDetail?.address?.state || null,
        country: 'India',
        postal_code: data?.getBusinessDetail?.address?.postal_code || null
      }
    };
  }, [data?.getBusinessDetail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    onSubmit: handleSave
  });

  useEffect(() => {
    setStepCallback(formik.submitForm);
  }, [setStepCallback]);

  useEffect(() => {
    const { category, registration_type } = formik.values;
    setDisabled(!category || !registration_type);
  }, [formik.values]);

  return (
    <>
      <StepContent activeStep={activeStep} />
      <Label className="required-field">Category</Label>
      <SelectCN
        value={formik.values.category}
        onValueChange={(value) => {
          formik.setFieldValue('category', value);
        }}
      >
        <SelectTrigger className="h-10 mt-1 mb-4 w-full">
          <SelectValue placeholder="Select a category" />
        </SelectTrigger>
        <SelectContent side="bottom" longList={true}>
          {BusinessCategory.map((category) => (
            <SelectItem key={category} value={category}>
              {category}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectCN>

      <Label className="required-field">Registration type</Label>
      <SelectCN
        value={formik.values.registration_type}
        onValueChange={(value) => {
          formik.setFieldValue('registration_type', value);
        }}
      >
        <SelectTrigger className="h-10 mt-1 mb-4 w-full">
          <SelectValue placeholder="Choose a registration type" />
        </SelectTrigger>
        <SelectContent side="bottom" longList={true}>
          {RegistrationType.map((category) => (
            <SelectItem key={category} value={category}>
              {category}
            </SelectItem>
          ))}
        </SelectContent>
      </SelectCN>
    </>
  );
};

export default BusinessType;
