import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

export const OnboardingSteps = [
  {
    step: 0,
    title: 'Business Type',
    description: 'Specify your business registration type and category to personalize your settings and preferences.',
    icon: <Unicons.UilBriefcaseAlt size={18} />,
    isOptional: false
  },
  {
    step: 1,
    title: 'Business Details & Logo',
    description: 'Provide key business details, including name, address, and upload your business logo.',
    icon: <Unicons.UilFileUploadAlt size={18} />,
    isOptional: false
  },
  {
    step: 2,
    title: 'POC & Users Permissions',
    description: 'Set contact person, add team members, and assign roles with appropriate permissions.',
    icon: <Unicons.UilUsersAlt size={18} />,
    isOptional: true
  },
  {
    step: 3,
    title: 'Payment Providers & Methods',
    description: 'Configure payment gateways, methods, and ensure seamless payment options for your customers.',
    icon: <Unicons.UilTransaction size={18} />,
    isOptional: true
  }
];

export const RegistrationType = [
  'Registered Business – Regular',
  'Registered Business – Composition',
  'Unregistered business'
];

export const BusinessCategory = [
  'Accessories',
  'Apparel',
  'Appliance',
  'Art, Stationary & Books',
  'Automotive Parts',
  'Baby',
  'Beauty & Cosmetics',
  'Electronics',
  'Eyewear',
  'F&B',
  'Footwear',
  'Gifting',
  'Health & Wellness',
  'Home & Kitchen',
  'Jewellery',
  'Luggage & Bags',
  'Luxury',
  'Pet',
  'Sports & Fitness',
  'Toys',
  'Others'
];
