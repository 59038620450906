/* eslint-disable import/default */
import dayjs from 'dayjs';
import { Card } from '../../flexyui';
import Charts from 'react-apexcharts';
import React, { useCallback } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { DashboardGraphTitle } from '../title/title';
import { options } from '../../../constants/dashboard';
import { PerformanceMetricsQuery } from '../../../graphql';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { convertAmount, calculateIncrement } from '../../../utils/convert-amount';
import { SkeletonDashboard } from '../../shared/skeleton-dashboard/skeleton-dashboard';

type Props = {
  metricsData?: PerformanceMetricsQuery['performanceMetrics'];
  loading: boolean;
};

export const TotalOrders: React.FC<Props> = ({ metricsData, loading }) => {
  const calculateData = useCallback(() => {
    const dates: any = [];
    const currentData = metricsData?.graph?.current?.map((item: any) => {
      dates.push(dayjs.utc(item?.date_time).format('MMM D h:mm A'));
      return Math.round(item?.total_orders);
    });

    return {
      series: [
        {
          name:
            dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D') !==
            dayjs.utc(metricsData?.metrics?.current?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.metrics?.current?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D')}`,
          data: currentData || []
        }
      ],
      dates
    };
  }, [metricsData]);

  options.xaxis.tickAmount = calculateData().dates.length < 30 ? 3 : 4;
  options.xaxis.categories =
    calculateData().dates.length === 24
      ? calculateData().dates.map((date: any) => dayjs(date).format('MMM D h:mm A'))
      : calculateData().dates.map((date: any) => dayjs(date).format('MMM D'));

  return (
    <Card>
      <div className="font-medium text-black pt-4 px-4 flex items-center">
        <div>Total Orders</div>
        <ButtonWithInfo
          heading="Total Orders"
          description="Total number of orders made via FlexyPe channel."
          calculation={false}
          align="center"
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      {loading ? (
        <SkeletonDashboard heightGraph="h-[50px]" />
      ) : (
        <>
          <DashboardGraphTitle
            loading={loading}
            value={convertAmount(Number(metricsData?.metrics?.current?.total_orders || '0'))}
            title="Total Orders"
            percentageChange={calculateIncrement(
              Number(metricsData?.metrics?.current?.total_orders),
              Number(metricsData?.metrics?.previous?.total_orders)
            )}
          />
          {metricsData?.metrics?.current?.start ? (
            <Charts options={options} series={calculateData().series} type="area" height={90} />
          ) : (
            <div className="text-center my-16">
              <div className="text-gray-300 text-base">Not Available</div>
            </div>
          )}
        </>
      )}
    </Card>
  );
};
