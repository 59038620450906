import React, { useMemo } from 'react';

type Props = {
  name: string;
  size?: 'SMALL' | 'LARGE';
};

export const CustomAvatar: React.FC<Props> = ({ name, size = 'LARGE' }) => {
  const calculateLuminance = (rgb: string) => {
    const [r, g, b] = rgb
      .match(/\d+/g)
      ?.map(Number)
      .map((c) => c / 255) || [0, 0, 0];

    const [lumR, lumG, lumB] = [r, g, b].map((c) => (c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)));

    return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
  };

  const generateColorFromText = (name: string) => {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    const r = (Math.abs((hash >> 24) & 255) % 200) + 55;
    const g = (Math.abs((hash >> 16) & 255) % 200) + 55;
    const b = (Math.abs((hash >> 8) & 255) % 200) + 55;

    const neonFactor = 1.2;
    const normalize = (value: number) => Math.min(255, Math.floor(value * neonFactor));

    return `rgb(${normalize(r)}, ${normalize(g)}, ${normalize(b)})`;
  };

  const getTextColor = (backgroundColor: string) => {
    const luminance = calculateLuminance(backgroundColor);
    return luminance > 0.5 ? 'black' : 'white';
  };

  const getInitials = () => {
    const words = name.trim().split(' ');
    if (words.length === 1) return words[0].slice(0, 2).toUpperCase();
    return words[0][0].toUpperCase() + (words[1][0] ? words[1][0].toUpperCase() : '');
  };

  const initials = getInitials();
  const backgroundColor = useMemo(() => generateColorFromText(name), [name]);
  const color = useMemo(() => getTextColor(backgroundColor), [backgroundColor]);

  return (
    <div
      className="flex items-center justify-center"
      style={{
        color,
        backgroundColor,
        width: size === 'SMALL' ? '25px' : '35px',
        height: size === 'SMALL' ? '25px' : '35px',
        borderRadius: size === 'SMALL' ? '6px' : '12px',
        fontSize: size === 'SMALL' ? '11px' : '14px',
        fontWeight: size === 'SMALL' ? 400 : 500
      }}
    >
      {initials}
    </div>
  );
};
