import * as Yup from 'yup';

export const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string().min(8, 'Password must be at least 8 characters long').required('New password is required'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password'), undefined], 'Passwords must match')
    .required('Please confirm your new password')
});

export const PasswordSchema = Yup.object({
  new_password: Yup.string()
    .required('New password is required')
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/\d/, 'Password must contain at least one number'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password'), undefined], 'Passwords must match')
    .required('Please confirm your new password')
});
