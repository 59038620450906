import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import { MetaPixelCard } from '../meta-pixels/meta-pixel-card';
import { Button, Card, Chip, Skeleton } from '../../../../flexyui';
import { MetaPixelDialog } from '../meta-pixels/meta-pixel-dialog';
import { Pixel, useMetaAnalyticsQuery } from '../../../../../graphql';
import { EventCategory, IntegrationEvent, trackEvents } from '../../../../../analytics';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';

type Props = {
  handleAnalyticsStatusChange: (value: boolean, analytics: string, refetch: () => void) => void;
};

export const MetaPixels: React.FC<Props> = ({ handleAnalyticsStatusChange }) => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useMetaAnalyticsQuery();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [pixelToEdit, setPixelToEdit] = useState<Pixel | null>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const handleEditClick = (pixel: Pixel) => {
    setPixelToEdit(pixel);
    setShowModal(true);
  };

  const handleMetaPixelStatus = () => {
    if (data?.metaAnalytics?.pixels?.length === 0) handleAnalyticsStatusChange(true, 'meta_pixels', refetch);
    if (data?.metaAnalytics?.pixels?.length === 1) handleAnalyticsStatusChange(false, 'meta_pixels', refetch);
  };

  return (
    <>
      <div className="items-center font-semibold text-lg my-2 hidden sm:flex">
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
          onClick={() => navigate('/settings/integrations')}
        >
          <Unicons.UilArrowLeft className="text-[#2A324C]" />
        </Button>
        <div className="ml-1.5">Meta Pixels</div>
      </div>

      <Card className="p-3 pl-4">
        <div className="flex items-center">
          <div>Enable Meta Pixels</div>
          <div className="ml-2 hidden sm:block">
            {loading ? (
              <Skeleton variant="circular" className="!m-0 w-[70px]" />
            ) : (
              <Chip
                text={data?.metaAnalytics?.enabled ? 'Enabled' : 'Disabled'}
                variant={data?.metaAnalytics?.enabled ? 'success' : 'draft'}
              />
            )}
          </div>
          <div className="flex items-center justify-end flex-1">
            {loading ? (
              <Skeleton className="w-[95px] h-[36px] !m-0" />
            ) : (
              <Button
                size={'md'}
                onClick={() => setShowConfirmationDialog(true)}
                variant={data?.metaAnalytics?.enabled ? 'outline' : 'default'}
                disabled={data?.metaAnalytics?.pixels?.length === 0}
              >
                {data?.metaAnalytics?.enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <Card className="mt-4 p-4">
        <div className="flex items-center justify-between mb-4">
          <div>
            <div className="font-medium">Pixels</div>
            <div className="text-[#888D9B] text-xs">
              Add your tracking pixels to monitor and optimize user interactions.
            </div>
          </div>
          {(data?.metaAnalytics?.pixels?.length || 0) > 0 && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setPixelToEdit(null);
                setShowModal(true);
              }}
            >
              <Unicons.UilPlus size={18} className="mr-2" />
              Add new pixel
            </Button>
          )}
        </div>
        <div>
          {loading && (
            <div className="border border-muted px-4 py-4 rounded-[6px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-5 w-5" />
                  <Skeleton className="w-[150px] !m-0" />
                </div>
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-8 w-8" />
                  <Skeleton className="!m-0 h-8 w-8" />
                </div>
              </div>
              <div className="mt-4">
                <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
              </div>
            </div>
          )}

          {data?.metaAnalytics?.pixels?.length === 0 ? (
            <div className="text-center border border-muted px-4 py-8 rounded-[6px]">
              <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>
                <Unicons.UilPlus size={18} className="mr-2" />
                Add new pixel
              </Button>
            </div>
          ) : (
            data?.metaAnalytics?.pixels?.map((pixel) => (
              <MetaPixelCard
                pixel={pixel}
                handleEditClick={handleEditClick}
                refetch={refetch}
                handleMetaPixelStatus={handleMetaPixelStatus}
              />
            ))
          )}
        </div>
      </Card>

      <MetaPixelDialog
        open={showModal}
        setOpen={setShowModal}
        pixelData={pixelToEdit}
        setPixelData={setPixelToEdit}
        handleMetaPixelStatus={handleMetaPixelStatus}
        refetch={refetch}
      />

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={() => {
          handleAnalyticsStatusChange(!data?.metaAnalytics?.enabled, 'meta_pixels', refetch);
          setShowConfirmationDialog(false);
          trackEvents(EventCategory.INTEGRATIONS, IntegrationEvent.META_STATUS_CHANGED);
        }}
        text={`This action will ${data?.metaAnalytics?.enabled ? 'disable' : 'enable'} the Meta Pixels`}
      />
    </>
  );
};
