import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label
} from '../../../../flexyui';
import {
  Partnered,
  useCreateWebhookMutation,
  useUpdateWebhookMutation,
  WebhookConfig,
  WebhookType
} from '../../../../../graphql';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { getWebhookSchema } from '../../../../../utils/validation-schemas/integrations';
import { EventCategory, IntegrationEvent, trackEvents } from '../../../../../analytics';
import { ReactComponent as LoadingIcon } from '../../../../../assets/images/loading.svg';

type Props = {
  type: WebhookType;
  partnered: Partnered;
  open: boolean;
  setOpen: (value: boolean) => void;
  webhookData: WebhookConfig | null;
  setWebhookData: (value: WebhookConfig | null) => void;
  refetch: () => void;
};

export const WebhooksDialog: React.FC<Props> = ({
  type,
  partnered,
  open,
  setOpen,
  webhookData,
  setWebhookData,
  refetch
}) => {
  const [createWebhook] = useCreateWebhookMutation();
  const [updateWebhook] = useUpdateWebhookMutation();

  const handleSave = async (values: any) => {
    try {
      if (webhookData) {
        await updateWebhook({
          variables: {
            webhookId: webhookData.id,
            config: values
          }
        });
        trackEvents(EventCategory.INTEGRATIONS, IntegrationEvent.EDIT_WEBHOOK, {
          partnered
        });
      } else {
        await createWebhook({
          variables: {
            config: {
              type,
              partnered,
              ...values
            }
          }
        });
        trackEvents(EventCategory.INTEGRATIONS, IntegrationEvent.ADD_WEBHOOK, {
          partnered
        });
      }
      enqueueSnackbar(`Webhook has been ${webhookData ? 'updated' : 'added'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
      setWebhookData(null);
    }
  };

  const initialValues = useMemo(() => {
    return {
      enabled: webhookData?.enabled ?? true,
      url: webhookData?.url || '',
      api_secret: webhookData?.api_secret || ''
    };
  }, [webhookData]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={true}
      validateOnMount={true}
      validateOnBlur={true}
      validationSchema={getWebhookSchema(type, partnered)}
      onSubmit={handleSave}
    >
      {({ values, errors, setFieldValue, touched, getFieldProps, submitForm, resetForm, isSubmitting }) => {
        return (
          <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
            <DialogContent className="!gap-0" size="sm" close={true}>
              <div>
                <DialogHeader>
                  <DialogTitle>
                    <p>{webhookData ? 'Edit' : 'Add'} Webhook Configuration</p>
                  </DialogTitle>
                  <DialogDescription className="!p-0 !m-0">
                    <div className="p-4">
                      {partnered === Partnered.None && (
                        <div className="mb-3">
                          <Label size="paragraph">URL</Label>
                          <Input
                            {...getFieldProps('url')}
                            value={`https://${values.url || ''}`}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (!inputValue.startsWith('https://')) return;

                              const updatedValue = inputValue.slice(8);
                              setFieldValue('url', updatedValue);
                            }}
                            error={touched.url && !!errors.url}
                            errorMessage={errors.url}
                            className="text-[#121b38] mt-1"
                          />
                        </div>
                      )}

                      <div>
                        <Label size="paragraph">API Secret</Label>
                        <Input
                          {...getFieldProps('api_secret')}
                          error={touched.api_secret && !!errors.api_secret}
                          errorMessage={errors.api_secret}
                          className="text-[#121b38] mt-1"
                        />
                      </div>
                    </div>
                  </DialogDescription>
                </DialogHeader>
              </div>
              <DialogFooter>
                <Button
                  variant={'outline'}
                  size={'md'}
                  onClick={() => {
                    setWebhookData(null);
                    setOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant={'default'}
                  size={'md'}
                  type="submit"
                  disabled={Object.keys(errors).length > 0 || isSubmitting}
                  onClick={async () => {
                    await submitForm();
                    resetForm();
                  }}
                >
                  {isSubmitting ? (
                    <LoadingIcon height={20} className={'animate-spin text-white'} />
                  ) : webhookData ? (
                    'Save'
                  ) : (
                    'Add'
                  )}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};
