import React from 'react';
import { useFormikContext } from 'formik';
import * as Unicons from '@iconscout/react-unicons';
import { PartialReferenceCodAmount, RtoSuitQuery } from '../../../graphql';
import { Card, Input, Label, RadioGroup, RadioGroupItem, Skeleton, Switch } from '../../flexyui';
import { EventCategory, PartialCodEvents, trackEvents } from '../../../analytics';

const PartialCod = ({ rtoSuiteLoading }: any) => {
  const { values, setFieldValue, getFieldProps } = useFormikContext<RtoSuitQuery>();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseFloat(e.target.value);
    const isValidValue =
      values?.partialCod?.reference_amount === PartialReferenceCodAmount.FixedAmount
        ? numericValue > 0
        : numericValue > 0 && numericValue <= 100;

    setFieldValue('partialCod.prepayable_quantity', isValidValue ? numericValue : '');
  };

  return (
    <Card className="p-4 mt-4">
      <div className="flex flex-col space-y-1 mb-4">
        <Label size="md" className="text-base">
          Partial COD
        </Label>
        <Label className="text-[#888D9B]" size={'sm'}>
          Accept partial payment for COD orders and avoid heavy RTO
        </Label>
      </div>
      {rtoSuiteLoading ? (
        <>
          <div className="flex items-center gap-2">
            <Skeleton className="!m-0 w-8 rounded-xl" />
            <Skeleton className="w-[150px] !m-0" />
          </div>
          <div className="mt-4">
            <Skeleton className="w-[450px] !m-0 !mb-3" />
            {[0, 1, 2].map((index) => (
              <div key={index} className="flex items-center gap-2 mb-2">
                <Skeleton className="!m-0 w-4 rounded-2xl" />
                <Skeleton className="w-[300px] !m-0" />
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="flex items-center mb-4 gap-2">
            <Switch
              checked={values?.partialCod?.enabled || false}
              onCheckedChange={(value) => {
                setFieldValue('partialCod.enabled', value);
                trackEvents(EventCategory.PARTIAL_COD, PartialCodEvents.PARTIAL_COD_STATUS_CHANGED);
              }}
              id="partialCod.enabled"
            />
            <Label size="paragraph" className="text-[#595F74]" htmlFor="partialCod.enabled">
              Enable Partial COD
            </Label>
          </div>
          <RadioGroup
            className="mb-2"
            value={values?.partialCod?.reference_amount || ''}
            onValueChange={(value) => {
              setFieldValue('partialCod.reference_amount', value);
              trackEvents(EventCategory.PARTIAL_COD, PartialCodEvents.PARTIAL_COD_TYPE, {
                reference_amount: value
              });
            }}
          >
            <div className="flex items-start space-x-2">
              <RadioGroupItem
                value={PartialReferenceCodAmount.FixedAmount}
                id={PartialReferenceCodAmount.FixedAmount}
                className="mt-0.5"
              />
              <Label size="paragraph" htmlFor={PartialReferenceCodAmount.FixedAmount} className="text-[#595F74]">
                Charge a fixed amount
              </Label>
            </div>
            <div className="flex items-start space-x-2">
              <RadioGroupItem
                value={PartialReferenceCodAmount.Cod}
                id={PartialReferenceCodAmount.Cod}
                className="mt-0.5"
              />
              <Label size="paragraph" htmlFor={PartialReferenceCodAmount.Cod} className="text-[#595F74]">
                Charge only COD amount
              </Label>
            </div>
            <div className="flex items-start space-x-2">
              <RadioGroupItem
                value={PartialReferenceCodAmount.TotalIncCod}
                id={PartialReferenceCodAmount.TotalIncCod}
                className="mt-0.5"
              />
              <Label htmlFor={PartialReferenceCodAmount.TotalIncCod} size="paragraph" className="text-[#595F74]">
                Charge percentage of the total order value (including of COD charges)
              </Label>
            </div>
            <div className="flex items-start space-x-2">
              <RadioGroupItem
                value={PartialReferenceCodAmount.TotalExcCod}
                id={PartialReferenceCodAmount.TotalExcCod}
                className="mt-0.5"
              />
              <Label htmlFor={PartialReferenceCodAmount.TotalExcCod} size="paragraph" className="text-[#595F74]">
                Charge percentage of the total order value (excluding of COD charges)
              </Label>
            </div>
          </RadioGroup>
          <div className="flex flex-col gap-2 sm:flex-row sm:gap-6">
            <div className="max-w-[180px] ">
              <Label size={'sm'} className="text-[#595F74]">
                Minimum Order Value{' '}
              </Label>
              <Input
                {...getFieldProps('partialCod.min_order_amount')}
                type="number"
                name={'partialCod.min_order_amount'}
                placeholder="Minimum Order Value"
                errorMessage="Min order value is required"
                icon={<Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const numericValue = parseFloat(e.target.value);
                  const isValidValue = numericValue >= 0;
                  setFieldValue('partialCod.min_order_amount', isValidValue ? numericValue : '');
                }}
              />
            </div>
            {values?.partialCod?.reference_amount !== PartialReferenceCodAmount.Cod ? (
              <div className="max-w-[180px]">
                <Label size={'sm'} className="text-[#595F74]">
                  Partial Amount{' '}
                  {values?.partialCod?.reference_amount !== PartialReferenceCodAmount.FixedAmount && 'Percentage'}
                </Label>
                <Input
                  {...getFieldProps('partialCod.prepayable_quantity')}
                  type="number"
                  name={'partialCod.prepayable_quantity'}
                  placeholder="Partial Amount Percentage"
                  min={0}
                  iconPosition={
                    values?.partialCod?.reference_amount === PartialReferenceCodAmount.FixedAmount ? 'start' : 'end'
                  }
                  icon={
                    values?.partialCod?.reference_amount === PartialReferenceCodAmount.FixedAmount ? (
                      <Unicons.UilRupeeSign width={18} height={18} className="mr-3 text-[#2A324C]" />
                    ) : (
                      <Unicons.UilPercentage width={18} height={18} className="text-[#2A324C]" />
                    )
                  }
                  onChange={handleInputChange}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </Card>
  );
};

export default PartialCod;
