import { useEffect } from 'react';
import { PageEvents } from './events';
import { trackPageView } from './mixpanel';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const pathToEventMap: { [key: string]: PageEvents } = {
      '/': PageEvents.HOME,
      '/orders': PageEvents.ORDERS,
      '/shipping': PageEvents.SHIPPING,
      '/coupons': PageEvents.COUPONS,
      '/payment-offers': PageEvents.PAYMENT_OFFERS,
      '/upsell/products': PageEvents.PRODUCT_UPSELLING,
      '/upsell/collection': PageEvents.COLLECTION_UPSELLING,
      '/customisation/general': PageEvents.GENERAL_CUSTOMISATION,
      '/customisation/login': PageEvents.LOGIN_CUSTOMISATION,
      '/customisation/address': PageEvents.ADDRESS_CUSTOMISATION,
      '/customisation/payments': PageEvents.PAYMENT_CUSTOMISATION,
      '/rto-suite': PageEvents.RTO_SUITE,
      '/settings/store': PageEvents.STORE_DETAILS,
      '/settings/payment': PageEvents.PAYMENTS,
      '/settings/taxes': PageEvents.TAXES_DUTIES,
      '/settings/integrations': PageEvents.INTEGRATIONS,
      '/settings/integrations/meta': PageEvents.META_PIXELS_INTEGRATIONS,
      '/settings/integrations/google-ads': PageEvents.GOOGLE_ADS_INTEGRATIONS,
      '/settings/integrations/google-analytics': PageEvents.GOOGLE_ANALYTICS_INTEGRATIONS,
      '/settings/integrations/11za': PageEvents.ELEVEN_ZA_INTEGRATIONS,
      '/settings/integrations/acr': PageEvents.ACR_INTEGRATIONS,
      '/settings/billing-usage': PageEvents.BILLING_USAGE,
      '/settings/account': PageEvents.USERS_PERMISSIONS,
      '/settings/change-password': PageEvents.CHANGE_PASSWORD
    };

    const currentPath = location.pathname;

    const event = pathToEventMap[currentPath];
    if (event) {
      trackPageView(event);
    }
  }, [location]);
};
