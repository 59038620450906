import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WebhooksCard } from './webhooks-card';
import { WebhooksDialog } from './webhooks-dialog';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Card, Skeleton } from '../../../../flexyui';
import { Partnered, useGetWebhookQuery, WebhookConfig, WebhookType } from '../../../../../graphql';

type Props = {
  type: WebhookType;
  partnered: Partnered;
};

export const Webhooks: React.FC<Props> = ({ type, partnered }) => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useGetWebhookQuery({
    variables: {
      type,
      partnered
    }
  });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [webhookToEdit, setWebhookToEdit] = useState<WebhookConfig | null>(null);

  const handleEditClick = (webhook: WebhookConfig) => {
    setWebhookToEdit(webhook);
    setShowModal(true);
  };

  const getTitle = () => {
    if (type === WebhookType.Acr && partnered === Partnered.None) return 'Abandoned Cart Webhooks';
    if (type === WebhookType.Acr && partnered === Partnered['11Za']) return '11za';
  };

  return (
    <>
      <div className="items-center font-semibold text-lg my-2 hidden sm:flex">
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
          onClick={() => navigate('/settings/integrations')}
        >
          <Unicons.UilArrowLeft className="text-[#2A324C]" />
        </Button>
        <div className="ml-1.5">{getTitle()}</div>
      </div>

      <Card className="mt-4 p-4">
        <div className="flex items-center justify-between mb-4">
          <div>
            <div className="font-medium">Webhook</div>
            <div className="text-[#888D9B] text-xs">Set up webhooks to capture data and recover lost sales.</div>
          </div>
          {(data?.getWebhook?.length || 0) > 0 && partnered === Partnered.None && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setWebhookToEdit(null);
                setShowModal(true);
              }}
            >
              <Unicons.UilPlus size={18} className="mr-2" />
              Add webhook
            </Button>
          )}
        </div>
        <div>
          {loading && (
            <div className="border border-muted px-4 py-4 rounded-[6px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-5 w-5" />
                  <Skeleton className="w-[150px] !m-0" />
                </div>
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-8 w-8" />
                  <Skeleton className="!m-0 h-8 w-8" />
                </div>
              </div>
              <div className="mt-4">
                <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
              </div>
            </div>
          )}

          {data?.getWebhook?.length === 0 ? (
            <div className="text-center border border-muted px-4 py-8 rounded-[6px]">
              <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>
                <Unicons.UilPlus size={18} className="mr-2" />
                Add webhook
              </Button>
            </div>
          ) : (
            data?.getWebhook?.map((webhook) => (
              <WebhooksCard
                type={type}
                partnered={partnered}
                webhook={webhook as WebhookConfig}
                handleEditClick={handleEditClick}
                refetch={refetch}
              />
            ))
          )}
        </div>
      </Card>

      <WebhooksDialog
        type={type}
        partnered={partnered}
        open={showModal}
        setOpen={setShowModal}
        webhookData={webhookToEdit}
        setWebhookData={setWebhookToEdit}
        refetch={refetch}
      />
    </>
  );
};
