import {
  DataTableColumnHeader,
  DataTable,
  Input,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Select as SelectCN
} from '../../components/flexyui';
import dayjs from 'dayjs';
import debounce from 'debounce';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import * as Unicons from '@iconscout/react-unicons';
import { CopyEmail } from '../../components/orders/copy-email';
import { useOrdersLazyQuery, SearchField } from '../../graphql';
import React, { useEffect, useState, useCallback } from 'react';
import { formatIndianRupees } from '../../utils/format-currency';
import { OrderDetails } from '../../components/orders/order-details';
import { Title } from '../../components/shared/dashboard-title/title';
import { Drawer, DrawerContent } from '../../components/flexyui/Drawer';
import { EventCategory, OrderEvents, trackEvents } from '../../analytics';
import { DatePicker } from '../../components/shared/date-picker/date-picker';
import { convertCountry, capitalizeFirstLetter } from '../../utils/convert-currency';
import { PaymentStatus } from '../../components/orders/payment-status/payment-status';
import { DatePickerDisplayButton } from '../../components/shared/date-picker/date-picker-display';

export const Orders: React.FC = () => {
  const [page, setPage] = useState<number>(0);
  const [rows, setRows] = useState<any>(null);
  const [rowPerPage, setRowPerPage] = useState<number>(25);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [showOrderDetail, setShowOrderDetail] = useState<boolean>(false);
  const savedRanges = localStorage.getItem('date-range');
  const [ranges, setRanges] = useState(
    savedRanges
      ? JSON.parse(savedRanges)
      : {
          startDate: DateTime.now()
            .minus({
              days: 7
            })
            .toISODate(),
          endDate: DateTime.now().toISODate()
        }
  );

  useEffect(() => {
    localStorage.setItem('date-range', JSON.stringify(ranges));
  }, [ranges]);

  const [searchedValue, setSearchedValue] = useState({
    input: '',
    filter: SearchField.All,
    skip: 0,
    limit: rowPerPage
  });

  const [showDateRange, setShowDateRange] = useState(false);
  const [getOrders, { data: orderData, loading }] = useOrdersLazyQuery({
    variables: {
      query: '',
      searchField: SearchField.All,
      dateRange: {
        start: new Date(ranges.startDate).toISOString(),
        end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
      },
      skip: 0,
      limit: rowPerPage
    }
  });

  const handleChangeRowsPerPageByValue = (value: any) => {
    setRowPerPage(parseInt(value, 10));
    setPage(0);
  };

  const handleCloseDatePicker = () => {
    setShowDateRange(!showDateRange);
  };
  const handleShowOrderDetail = () => setShowOrderDetail(!showOrderDetail);

  const handleSearch = (searchParams: any, ranges: any, skip: number, limit: number) => {
    getOrders({
      variables: {
        query: searchParams.input,
        searchField: searchParams.filter,
        dateRange: {
          start: new Date(ranges.startDate).toISOString(),
          end: new Date(`${ranges.endDate}T23:59:59.999Z`).toISOString()
        },
        skip,
        limit
      }
    });

    if (searchParams.input)
      trackEvents(EventCategory.ORDERS, OrderEvents.FILTERED_SEARCH, {
        query: searchParams.input,
        filterType: searchParams.filter
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    const rowData = orderData?.orders.data.map((row) => {
      return {
        order_id: row.name,
        customer: row?.customer?.last_name,
        email: row?.customer?.email,
        phone: row.customer?.phone,
        amount: row.order_invoice?.total_price,
        'payment-mode': row.order_invoice?.payment_method
          ? row.order_invoice?.payment_method?.toUpperCase()
          : row.order_invoice?.payment_provider === 'CASH_ON_DELIVERY'
            ? 'COD'
            : '-',
        'payment-status': row?.order_invoice?.payment_state,
        'date-created': row.created_at,
        row
      };
    });
    setRows(rowData);
  }, [orderData]);

  const onRowClickHandler = (order: any) => {
    setSelectedOrder(order.row);
    handleShowOrderDetail();

    trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER);
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    debouncedSearch(searchedValue, ranges, rowPerPage * page, rowPerPage);
  }, [ranges, searchedValue, rowPerPage, page]);

  const handleUserInput = (e: any, type: string) => {
    setSearchedValue((prevState) => {
      return {
        ...prevState,
        [type]: e.target.value
      };
    });
  };
  const handleUserSelect = (value: any, type: string) => {
    setSearchedValue((prevState) => {
      return {
        ...prevState,
        [type]: value
      };
    });
  };

  const columns: ColumnDef<any, any>[] = [
    {
      accessorKey: 'order_id',
      enablePinning: true,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Order ID" className="w-full flex justify-start" />
      ),
      cell: ({ row: order }) => {
        return (
          <div className="py-2 px-3">
            <Link
              to={order.original.row?.platform_order_admin_url}
              target="_blank"
              onClick={(e) => {
                e.stopPropagation();
                trackEvents(EventCategory.ORDERS, OrderEvents.VIEW_ORDER_IN_SHOPIFY);
              }}
              className="text-[#2F72FF] font-semibold hover:underline"
            >
              {order.original?.row?.name}
            </Link>
          </div>
        );
      }
    },
    {
      accessorKey: 'customer',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Customer" className="" />,
      cell: ({ row: order }) => {
        return (
          <div className="py-2 px-3">
            {order?.original.row.customer?.first_name} {order?.original.row.customer?.last_name}
          </div>
        );
      }
    },
    {
      accessorKey: 'email',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Email" className="" />,
      cell: ({ row: order }) => {
        return <CopyEmail email={order?.original.email} />;
      }
    },
    {
      accessorKey: 'phone',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Phone" className="" />,
      cell: ({ row: order }) => {
        return (
          <div className="py-2 px-3">
            {order?.original.phone ? order?.original.phone : <div className="text-center">-</div>}
          </div>
        );
      }
    },
    {
      accessorKey: 'amount',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Amount" className="text-right flex justify-end" />
      ),
      cell: ({ row: order }) => {
        return (
          <div className="text-right min-w-fit py-2 px-3 flex justify-end">
            <div>{convertCountry(order.original.row?.order_invoice?.currency)}</div>
            <div>{formatIndianRupees(order.original.amount)}</div>
          </div>
        );
      }
    },
    {
      accessorKey: 'payment-mode',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Payment Mode" className="flex justify-center" />
      ),
      id: 'Payment Mode',
      cell: ({ row: order }) => {
        return <div className="text-center py-2 px-3">{order.original['payment-mode']}</div>;
      }
    },
    {
      accessorKey: 'payment-status',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title="Payment Status" className="flex justify-center" />
      ),
      id: 'Payment status',
      cell: ({ row: order }) => {
        return (
          <div className="flex py-2 px-3 justify-center">
            <PaymentStatus text={order.original['payment-status']} />
          </div>
        );
      }
    },
    {
      accessorKey: 'date-created',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Date Created" className="!" />,
      id: 'Date created',
      cell: ({ row }) => {
        return <div className="py-2 px-3">{dayjs(row.original.row.created_at).format('MMM D YYYY, hh:mm A')}</div>;
      }
    }
  ];

  const removeUnderScoreAndCapitalize = (word: string) => {
    if (word.includes('_')) {
      const formattedOrderId = word
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char?.toUpperCase())
        .replace(/\B\w/g, (char) => char.toLowerCase());
      return formattedOrderId;
    }
    return capitalizeFirstLetter(word);
  };

  const updateOrderEditRow = (orderName: string, orderDetails: any) => {
    const rowData = rows.map((row: any) => {
      if (orderName === row.order_id) {
        return {
          ...row,
          amount: orderDetails.order_invoice?.total_price,
          'payment-status': orderDetails?.order_invoice?.payment_state
        };
      } else return row;
    });
    setRows(rowData);
  };

  return (
    <>
      <div className="px-4">
        <Title title="Orders" />
        <div className="flex flex-col justify-between gap-2 items-end mb-2 sm:mb-4 sm:flex-row sm:items-center">
          <div className="flex items-center">
            <Input
              placeholder={`${
                searchedValue.filter === 'ALL'
                  ? 'Search'
                  : `Search by ${removeUnderScoreAndCapitalize(searchedValue.filter)}`
              }`}
              value={searchedValue.input}
              onChange={(e) => handleUserInput(e, 'input')}
              icon={<Unicons.UilSearch size={18} />}
              iconPosition={'start'}
              className="min-w-full mr-5"
            />
            <SelectCN
              value={searchedValue.filter}
              onValueChange={(value) => {
                handleUserSelect(value, 'filter');
              }}
              defaultValue={searchedValue.filter}
            >
              <SelectTrigger className="h-10 w-[100px] ml-3">
                <SelectValue placeholder={searchedValue.filter} />
              </SelectTrigger>
              <SelectContent side="top">
                {Object.values(SearchField).map((filter) => (
                  <SelectItem key={filter} value={filter}>
                    {removeUnderScoreAndCapitalize(filter)}
                  </SelectItem>
                ))}
              </SelectContent>
            </SelectCN>
          </div>
          <DatePickerDisplayButton setShowDateRange={setShowDateRange} showDateRange={showDateRange} ranges={ranges} />
        </div>

        <DataTable
          columnData={columns}
          data={rows}
          showFilterInput={false}
          showPagination={true}
          onRowClick={onRowClickHandler}
          enableRowSelection={false}
          rowsPerPageOptions={[25, 50, 100]}
          rowsPerPage={rowPerPage}
          count={orderData?.orders?.page_info?.total_count || 0}
          page={page}
          setPage={setPage}
          onRowsPerPageChange={handleChangeRowsPerPageByValue}
        />
      </div>

      <DatePicker
        open={showDateRange}
        onClose={handleCloseDatePicker}
        setDateRange={(ranges: any) => setRanges(ranges)}
      />

      <Drawer direction="right" open={showOrderDetail} onOpenChange={(value: boolean) => setShowOrderDetail(value)}>
        <DrawerContent>
          <OrderDetails
            order={selectedOrder}
            handleShowOrderDetail={handleShowOrderDetail}
            updateOrderEditRow={updateOrderEditRow}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};
