/* eslint-disable import/default */
import React from 'react';
import Charts from 'react-apexcharts';
import { Skeleton, Card } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { PaymentMetricsQuery } from '../../../graphql';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { Select as SelectCN, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../flexyui/Select/index';

const Payments: Record<string, string> = {
  ALL: 'All',
  UPI: 'UPI',
  CARD: 'Card',
  NET_BANKING: 'Net Banking',
  EMI: 'EMI',
  WALLET: 'Wallet',
  PAY_LATER: 'Pay Later',
  ONLINE: 'PhonePe',
  PARTIAL_COD: 'Partial COD',
  SNAPMINT: 'Snapmint',
  COD: 'COD',
  REDIRECT_SHOPIFY: 'Redirect Shopify'
};

type Props = {
  paymentGraphData: PaymentMetricsQuery | undefined;
  loading: boolean;
};

export const PaymentMethod: React.FC<Props> = ({ paymentGraphData, loading }) => {
  const excludedKeys = ['ALL', 'REDIRECT_SHOPIFY'];

  const activePaymentMethods = Object.keys(paymentGraphData?.paymentMetrics?.current || {}).filter(
    (key) => !excludedKeys.includes(key)
  );

  const getPaymentName = (data: string[]) => {
    return data.map((item) => Payments[item] || item);
  };

  const percentageSplit = activePaymentMethods.map(
    (data) =>
      paymentGraphData?.paymentMetrics?.current?.[data as keyof typeof paymentGraphData.paymentMetrics.current]
        ?.percentage || 0
  );

  const showGraph = percentageSplit.some((percentage) => percentage > 0);

  const options: any = {
    colors: showGraph
      ? ['#3cb44b', '#2F72FF', '#887310', '#BD69FF', '#ffe119', '#A32C06', '#283559', '#ff4b60', '#42d4f4', '#f58231']
      : ['#A0A4AF'],

    legend: {
      position: 'right',
      verticalAlign: 'bottom'
    },
    labels: showGraph ? getPaymentName(activePaymentMethods) : ['No data available'],
    plotOptions: {
      pie: {
        expandOnClick: true,
        donut: {
          size: '30%'
        }
      }
    },
    dataLabels: {
      enabled: showGraph
    },
    plugins: {
      legend: {
        show: showGraph,
        position: showGraph && 'bottom',
        horizontalAlign: 'left'
      }
    },
    chart: {
      type: 'donut',
      height: 100
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'right'
          }
        }
      }
    ]
  };

  return (
    <Card>
      <div className="font-medium text-black pt-4 px-4 mb-4 flex items-center">
        <div>Payment Method Usage</div>
        <ButtonWithInfo
          heading="Payment Method Usage"
          description="Distribution of different payment methods used by customers including Cash on Delivery (COD)."
          calculation={false}
          align="center"
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      <SelectCN
        value={'All'}
        defaultValue={'All'}
        // onValueChange={(value) => {
        //   handlePaymentChange(value, 'filter');
        // }}
      >
        <SelectTrigger className="h-8 w-[8rem] ml-4 my-2">
          <SelectValue placeholder={'Hello'} />
        </SelectTrigger>
        <SelectContent side="bottom">
          <SelectItem value={'All'}>All</SelectItem>
        </SelectContent>
      </SelectCN>
      {loading ? (
        <div className="p-4 flex justify-between items-center gap-6">
          <Skeleton className="w-[200px] h-[200px] rounded-full" />
          <div className="flex-1">
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} className="w-full !m-0 !mb-2" />
            ))}
          </div>
        </div>
      ) : (
        <>
          {showGraph ? (
            <Charts options={options} series={percentageSplit} type="donut" />
          ) : (
            <Charts options={options} series={[100]} type="donut" colors={'#A0A4AF'} />
          )}
        </>
      )}
    </Card>
  );
};
