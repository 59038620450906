import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import { UilCloudUpload } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useMutation } from '@tanstack/react-query';
import { imageUpload } from '../../../network/customization/customization';
import { AppDispatch, RootState } from '../../../store';
import { setGeneralCustomization, setLoginCustomization, setPage } from '../../../store/customization-slice';
import { Card, Input, Label, Separator, Skeleton, Switch } from '../../flexyui';
import { LayoutCard } from '../../dashboard/layout-card/layout-card';
import { LayoutComponent, useGeneralQuery, useUpdateGeneralCustomisationMutation } from '../../../graphql';
import GeneralTabLoading from './loading-screen/general-loading-tab';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import ErrorHandling from '../../error-handling/error-handling';
import { enqueueSnackbar } from 'notistack';
import { generalCustomizationLoginData, replaceAndCapitalize } from '../../../constants/dashboard';
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { EventCategory, GeneralCustomizationEvents, trackEvents } from '../../../analytics';

export interface Item {
  position: number;
  name: LayoutComponent;
}

export type FileData = {
  image: string;
  filename: string;
};

const GeneralTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [dragEnter, setDragEnter] = useState<boolean>(false);
  const [dragAccepted, setDragAccepted] = useState<boolean>(false);
  const [activeId, setActiveId] = useState(null);
  const { data: generalData, loading: generalLoading, error: generalError, refetch } = useGeneralQuery();
  const [updateGeneralCustomisationMutation] = useUpdateGeneralCustomisationMutation();
  const customization = useSelector((state: RootState) => state.customization.general);
  const {
    logo,
    color_scheme: { primary_color: primaryColor, text_color: textColor, upselling_color: upsellingColor },
    layout_order: LayoutOrder,
    item_quantity_control: itemQuantityControl,
    show_estimated_time: showEstimatedTime,
    auto_redirect_shopify_checkout: autoRedirectShopifyCheckout,
    redirect_shopify_checkout: redirectShopifyCheckout,
    discount_on_mrp: discountOnMrp,
    show_additional_details: showAdditionalDetails
  } = useSelector((state: RootState) => state.customization.general);
  const [cards, setCards] = useState<Item[]>(LayoutOrder);
  const [selectImage, setSelectImage] = useState<FileData | null>(null);
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();

  const handleFileChange = (file: File[]) => {
    if (file) {
      const FR = new FileReader();

      FR.readAsDataURL(file[0]);
      FR.addEventListener('load', function (evt) {
        const data = {
          ...customization,
          logo: String(evt?.target?.result) || ''
        };

        setSelectImage({
          image: String(evt?.target?.result) || '',
          filename: file[0].name
        });

        dispatch(setGeneralCustomization(data));
        trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.LOGO_UPLOAD);
      });
    }
  };
  const handleColorChangePrimary = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorscheme = e?.target.value;
    const data = {
      ...customization,
      color_scheme: {
        ...customization.color_scheme,
        primary_color: colorscheme
      }
    };
    dispatch(setGeneralCustomization(data));
  };
  const handleColorChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorscheme = e?.target.value;
    const data = {
      ...customization,
      color_scheme: {
        ...customization.color_scheme,
        text_color: colorscheme
      }
    };

    dispatch(setGeneralCustomization(data));
  };
  const handleColorChangeUpsellingButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorscheme = e?.target.value;
    const data = {
      ...customization,
      color_scheme: {
        ...customization.color_scheme,
        upselling_color: colorscheme
      }
    };

    dispatch(setGeneralCustomization(data));
  };

  // TODO: Don't remove have to work on next version

  // const handleLayoutStyle = (value: string) => {
  //   const data = {
  //     ...customization,
  //     layout_style: value
  //   };
  //   dispatch(setGeneralCustomization(data));
  // };

  const handleLayoutOrder = () => {
    const order = cards.map((card: Item, index: number) => [{ ...card, position: index }]);
    let orderedCards: Item[] = [];

    Object.values(order).forEach((array) => {
      orderedCards = orderedCards.concat(array);
    });
    const data = {
      ...customization,
      layout_order: [...orderedCards]
    };
    dispatch(setGeneralCustomization(data));
  };

  const handleItemQuantityControl = (value: boolean) => {
    const data = {
      ...customization,
      item_quantity_control: value
    };
    dispatch(setGeneralCustomization(data));
    trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.QUANTITY_CHANGE);
  };

  const handleAdditionalDetails = (value: boolean) => {
    const data = {
      ...customization,
      show_additional_details: value
    };
    dispatch(setGeneralCustomization(data));
    trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.ADDITIONAL_PRODUCT_DETAILS);
  };

  const handleDiscountOnMrp = (value: boolean) => {
    const data = {
      ...customization,
      discount_on_mrp: value
    };
    dispatch(setGeneralCustomization(data));
    trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.COMPARE_PRICE);
  };

  const handleShopifyAutoRedirect = (value: boolean) => {
    const data = {
      ...customization,
      auto_redirect_shopify_checkout: value
    };
    dispatch(setGeneralCustomization(data));
    trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.AUTO_REDIRECT);
  };

  const handleShowEstimatedTime = (value: boolean) => {
    const data = {
      ...customization,
      show_estimated_time: value
    };
    dispatch(setGeneralCustomization(data));
  };

  const handleRedirectVisibility = (value: boolean) => {
    const data = {
      ...customization,
      redirect_shopify_checkout: {
        ...customization.redirect_shopify_checkout,
        visibility: value
      }
    };
    dispatch(setGeneralCustomization(data));
    trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.REDIRECT_SHOPIFY_CHECKOUT);
  };

  const handleRedirectText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const truncatedValue = event.target.value.slice(0, 50);
    const data = {
      ...customization,
      redirect_shopify_checkout: {
        ...customization.redirect_shopify_checkout,
        title: truncatedValue
      }
    };
    dispatch(setGeneralCustomization(data));
  };

  const handleCancel = useCallback(() => {
    if (generalData?.customisations?.general_config) {
      dispatch(setGeneralCustomization(generalData?.customisations?.general_config));
      setCards(generalData?.customisations?.general_config.layout_order);
    }
    setSelectImage(null);
  }, [generalData?.customisations?.general_config]);

  const imageUploadMutation = useMutation({
    mutationFn: imageUpload,
    onError() {
      refetch();
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
  });

  const handleSave = async () => {
    setLoadingActions(true);
    if (selectImage !== null) {
      await imageUploadMutation.mutate(selectImage);
    }

    const { logo: _logo, ...rest } = customization;

    try {
      await updateGeneralCustomisationMutation({
        variables: {
          configs: {
            ...rest,
            layout_style: 'CARD'
          }
        }
      });
      setLoadingActions(false);
      setShowActions(false);
      if (selectImage === null) {
        await refetch();
      }
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
    trackEvents(EventCategory.GENERAL_CUSTOMIZATION, GeneralCustomizationEvents.LAYOUT_REARRANGED);
  };

  const handleDragEnd = (event: any) => {
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setCards((items: Item[]) => {
        const oldIndex = items.findIndex((item: Item) => `${item.position}` === active.id);
        const newIndex = items.findIndex((item: Item) => `${item.position}` === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    if (generalData?.customisations?.general_config) {
      dispatch(setGeneralCustomization(generalData.customisations.general_config));
      setCards(generalData?.customisations?.general_config.layout_order);
    }
  }, [generalData?.customisations.general_config]);

  useEffect(() => {
    dispatch(setPage('General'));
    dispatch(setLoginCustomization(generalCustomizationLoginData));
  }, []);

  useEffect(() => {
    return () => {
      handleCancel();
    };
  }, [handleCancel]);

  useEffect(() => {
    handleLayoutOrder();
  }, [cards]);

  useEffect(() => {
    const hasChanges = !isEqual(generalData?.customisations.general_config, customization);
    if (hasChanges) {
      if (!generalLoading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [generalData?.customisations.general_config, customization, saveButtonClicked, cancelButtonClicked]);

  if (generalError?.networkError) return <ErrorHandling />;

  return (
    <>
      {generalLoading ? (
        <GeneralTabLoading />
      ) : (
        <div>
          <Label size={'md'}>Logo</Label>
          <div className="mt-3 flex flex-col items-start sm:flex-row">
            {logo && (
              <img
                src={logo}
                alt="Logo Image"
                className="aspect-auto h-[40px] w-[150px] mr-6 rounded-md object-contain border border-muted"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = 'https://static.flexype.in/assets/flexype-full-black.svg';
                }}
              />
            )}

            <Dropzone
              onDrop={(acceptedFiles) => {
                handleFileChange(acceptedFiles);
              }}
              onDragEnter={() => {
                setDragEnter(true);
              }}
              onDropAccepted={() => {
                setDragEnter(false);
                setDragAccepted(true);
                setTimeout(() => {
                  setDragAccepted(false);
                }, 500);
              }}
              onDropRejected={() => {
                setDragAccepted(false);
              }}
              maxFiles={1}
              multiple={false}
              accept={{
                'image/*': ['.jpeg', '.png']
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Card
                  {...getRootProps()}
                  className={classNames(
                    'dropzone w-full h-[104px] mt-4 flex flex-col justify-center items-center px-5 text-center cursor-pointer py-4 sm:w-[284px] sm:mt-0',
                    {
                      'border-primary border-2 border-dashed': dragEnter,
                      'border-black border-2 border-dashed': dragAccepted
                    }
                  )}
                >
                  <div className="bg-muted p-2 w-fit rounded-full">
                    <UilCloudUpload />
                  </div>
                  <div>
                    <input name="logo" {...getInputProps()} />
                    <p className="text-xs mt-2">
                      <span className="font-medium underline">Click here to upload</span>
                      <span className="no-underline ml-1 text-[#82889A]">
                        or drag and drop WEBP, PNG, JPG or GIF (max 150 x 40 px)
                      </span>
                    </p>
                  </div>
                </Card>
              )}
            </Dropzone>
          </div>
          <Separator />
          <Label size={'md'}>Color Scheme</Label>
          <div className="mt-3 grid grid-cols-2 gap-4 sm:gap-8 sm:grid-cols-3">
            <div className="flex">
              <input
                type="color"
                value={primaryColor}
                className="w-10 h-10 border border-border rounded-sm mr-3 cursor-pointer"
                onChange={handleColorChangePrimary}
              />
              <div className="flex flex-col">
                <Label size="paragraph">Primary Color</Label>
                <Label size="sm" className="text-[#888D9B] uppercase">
                  {primaryColor}
                </Label>
              </div>
            </div>
            <div className="flex">
              <input
                type="color"
                value={textColor}
                className="w-10 h-10 border border-border rounded-sm mr-3 cursor-pointer"
                onChange={handleColorChangeText}
              />
              <div className="flex flex-col">
                <Label size="paragraph">Text Color</Label>
                <Label size="sm" className="text-[#888D9B] uppercase">
                  {textColor}
                </Label>
              </div>
            </div>
            <div className="flex col-span-2 sm:col-span-1">
              <input
                type="color"
                value={upsellingColor}
                className="w-10 h-10 border border-border rounded-sm mr-3 cursor-pointer"
                onChange={handleColorChangeUpsellingButton}
              />
              <div className="flex flex-col">
                <Label size="paragraph">Upselling Background Color</Label>
                <Label size="sm" className="text-[#888D9B] uppercase">
                  {upsellingColor}
                </Label>
              </div>
            </div>
          </div>
          {/* TODO: Do not remove have to add in Version 2 */}
          {/*
          <Separator />
           <Label size={'md'}>Layout style</Label>
          <RadioGroup value={LayoutStyle} className="flex mt-3" onValueChange={handleLayoutStyle}>
            <div className="flex items-center space-x-2 mr-3">
              <Label
                htmlFor="CARD"
                className={classNames(
                  'w-[213px] h-[80px] border border-border p-[10px] rounded-2xl cursor-pointer',
                  {
                    'border border-primary': LayoutStyle === 'CARD'
                  }
                )}
              >
                <div className="flex w-full h-full justify-between">
                  <div className="flex flex-col justify-between h-full">
                    <RadioGroupItem value="CARD" id="CARD" />
                    <Label size="paragraph">Card Layout</Label>
                  </div>
                  {LayoutStyle === 'CARD' ? <CardLayoutActive /> : <CardLayoutInactive />}
                </div>
              </Label>
            </div>
            <div className="flex items-center space-x-2">
              <Label
                htmlFor="TILE"
                className={classNames(
                  'w-[213px] h-[80px] border border-border p-[10px] rounded-2xl cursor-pointer',
                  {
                    'border border-primary': LayoutStyle === 'TILE'
                  }
                )}
              >
                <div className="flex w-full h-full justify-between">
                  <div className="flex flex-col justify-between h-full">
                    <RadioGroupItem value="TILE" id="TILE" />
                    <Label size="paragraph">Tile Layout</Label>
                  </div>
                  {LayoutStyle === 'TILE' ? <TileLayoutActive /> : <TileLayoutInactive />}
                </div>
              </Label>
            </div>
          </RadioGroup> */}
          <Separator />
          <Label size={'md'} className="mt-3">
            Layout
          </Label>
          {cards.length <= 0 &&
            [1, 2, 3].map((_, index) => (
              <div className="flex" key={index}>
                <Skeleton variant={'rounded'} size={'xl'} className="h-10" />
              </div>
            ))}
          <div className="mt-3">
            <div className="rounded-lg overflow-hidden border border-border">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
              >
                <SortableContext items={cards.map((item) => `${item.position}`)} strategy={verticalListSortingStrategy}>
                  {cards.map((card, index) => (
                    <LayoutCard
                      key={card.position}
                      index={index}
                      id={card.position}
                      layout={replaceAndCapitalize(card.name)}
                      showAction={false}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
          </div>
          <Separator />
          <Label size={'md'}>Order summary</Label>
          <div className="flex items-center gap-2 mt-3">
            <Switch
              id="item_quantity_control"
              checked={itemQuantityControl}
              onCheckedChange={handleItemQuantityControl}
            />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="item_quantity_control">
              Allow quantity change
            </Label>
          </div>
          <div className="flex items-center gap-2 mt-3">
            <Switch
              id="show_additional_details"
              checked={showAdditionalDetails}
              onCheckedChange={handleAdditionalDetails}
            />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="show_additional_details">
              Show additional product details
            </Label>
          </div>
          <div className="flex items-center gap-2 mt-3">
            <Switch id="discount_on_mrp" checked={discountOnMrp} onCheckedChange={handleDiscountOnMrp} />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="discount_on_mrp">
              Show the compare price in strike-through format
            </Label>
          </div>
          <Separator />
          <div className="flex justify-between items-center">
            <Label size={'md'}>Redirect to shopify checkout </Label>
            <Switch
              id="redirect_visibility"
              checked={redirectShopifyCheckout.visibility}
              onCheckedChange={handleRedirectVisibility}
            />
          </div>
          <div className="mb-4 mt-3">
            <div className="flex justify-between">
              <Label size={'paragraph'} className="text-[#595F74]">
                Redirect Text
              </Label>
              <Label size={'paragraph'} className="text-[#595F74]">
                {`${redirectShopifyCheckout?.title.length}/50`}
              </Label>
            </div>
            <Input
              type="text"
              name="login"
              placeholder="Ordering from outside INDIA?"
              value={redirectShopifyCheckout?.title ?? ' '}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRedirectText(event)}
            />
          </div>
          <Separator />
          <Label size={'md'}>Other</Label>
          {/* <div className="flex items-center gap-2 mt-3">*/}
          {/*  <Switch id="show_estimated_time" checked={showEstimatedTime} onCheckedChange={handleShowEstimatedTime} />*/}
          {/*  <Label size={'paragraph'} className="text-[#595F74]" htmlFor="show_estimated_time">*/}
          {/*    Show shipping estimated time*/}
          {/*  </Label>*/}
          {/* </div>*/}
          <div className="flex items-center gap-2 mt-4">
            <Switch
              id="auto_redirect_shopify_checkout"
              checked={autoRedirectShopifyCheckout}
              onCheckedChange={handleShopifyAutoRedirect}
            />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="auto_redirect_shopify_checkout">
              Auto redirect international visitors to shopify checkout
            </Label>
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralTab;
