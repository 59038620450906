/* eslint-disable camelcase */
import {
  Partnered,
  useDeleteWebhookMutation,
  useUpdateWebhookMutation,
  WebhookConfig,
  WebhookType
} from '../../../../../graphql';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Checkbox, Label } from '../../../../flexyui';
import { EventCategory, IntegrationEvent, trackEvents } from '../../../../../analytics';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';

type Props = {
  type: WebhookType;
  partnered: Partnered;
  webhook: WebhookConfig;
  handleEditClick: (value: WebhookConfig) => void;
  refetch: () => void;
};

export const WebhooksCard: React.FC<Props> = ({ type, partnered, webhook, handleEditClick, refetch }) => {
  const [updateWebhook] = useUpdateWebhookMutation();
  const [deleteWebhook, { loading: deleteLoading }] = useDeleteWebhookMutation();

  const [webhookStatus, setWebhookStatus] = useState<boolean>(webhook.enabled);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  useEffect(() => {
    if (webhook) setWebhookStatus(webhook.enabled);
  }, [webhook]);

  const handleWebhookStatusUpdate = async (value: boolean) => {
    setWebhookStatus(value);

    const { id, ...rest } = webhook;

    try {
      await updateWebhook({
        variables: {
          webhookId: id,
          config: {
            ...rest,
            enabled: value
          }
        }
      });
      enqueueSnackbar(`Webhook has been ${value ? 'enabled' : 'disabled'} successfully!`, {
        variant: 'success'
      });

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });

      setWebhookStatus(webhook.enabled);
    }
  };

  const handleDeleteWebhook = async () => {
    try {
      await deleteWebhook({
        variables: {
          webhookId: webhook.id
        }
      });

      enqueueSnackbar('Webhook has been deleted successfully!', {
        variant: 'success'
      });

      refetch();
      trackEvents(EventCategory.INTEGRATIONS, IntegrationEvent.REMOVE_WEBHOOK, {
        partnered
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setShowConfirmationDialog(false);
    }
  };

  return (
    <>
      <div className="text-[#888D9B] text-xs border border-muted px-4 py-4 rounded-[6px] mb-3 last:mb-0">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Checkbox
              checked={webhookStatus}
              id={'webhook_enabled'}
              name={'webhookEnabled'}
              onChange={(e) => handleWebhookStatusUpdate(e.target.checked)}
            />
            <div className="grid gap-[5px] leading-none ml-3">
              <Label htmlFor={'webhook_enabled'} size={'paragraph'} className="font-normal text-[#595F74]">
                Enable this webhook
              </Label>
            </div>
          </div>
          <div className="flex items-center">
            <Button size="icon" variant="icon" onClick={() => handleEditClick(webhook)} className="mr-2">
              <Unicons.UilEdit size={'22'} className="p-[2px] text-[#595F74]" />
            </Button>
            <Button
              size="icon"
              variant="icon"
              onClick={() => setShowConfirmationDialog(true)}
              className="text-[#595F74] hover:text-red-500"
            >
              <Unicons.UilTrashAlt size={'22'} className="p-[2px]" />
            </Button>
          </div>
        </div>
        {partnered === Partnered.None && (
          <>
            <div className="mt-3 font-normal text-[#595F74]">Webhook URL</div>
            <div className="flex justify-start text-sm text-[#121B38] font-normal">
              {webhook?.url ? webhook?.url : '-'}
            </div>
          </>
        )}
        <div className="mt-3 font-normal text-[#595F74]">API Secret</div>
        <div className="flex justify-start text-sm text-[#121B38] font-normal">
          {webhook?.api_secret ? webhook?.api_secret : '-'}
        </div>
      </div>

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={handleDeleteWebhook}
        headerText="Delete Webhook?"
        text="This action will delete the webhook"
        confirmButtonText="Delete"
        confirmActionVariant="destructive"
        loading={deleteLoading}
      />
    </>
  );
};
