import React from 'react';
import classNames from 'classnames';
import { SideBar } from '../../constants/sidebar';
import { AppDispatch, RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, DrawerContent } from '../flexyui/Drawer';
import { setSidebarState } from '../../store/main-slice';
import { SidebarLink } from '../shared/sidebar-link/sidebar-link';
import { ReactComponent as Logo } from '../../assets/images/flexype-logo.svg';

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const banners = useSelector((state: RootState) => state.main.banners);
  const sideBarState = useSelector((state: RootState) => state.main.sideBarState);

  return (
    <>
      <div
        className={classNames('fixed w-56 z-10 transition-top duration-300 ease-in-out', {
          'top-20': banners.length > 0,
          'top-12': banners.length === 0
        })}
      >
        <div className="border-r-[1px] h-screen pt-4 hidden lg:block">
          {SideBar.map((item: any, index) => {
            return <SidebarLink key={index} text={item.name} routeLink={item.to} icon={item.icon} />;
          })}
        </div>
      </div>

      <Drawer direction="left" open={sideBarState} onOpenChange={(value: boolean) => dispatch(setSidebarState(value))}>
        <DrawerContent anchor="left" className="w-auto">
          <div className="w-[225px]">
            <Logo className="h-8 w-24 my-4 ml-4" />
            {SideBar.map((item: any, index) => {
              return <SidebarLink key={index} text={item.name} routeLink={item.to} icon={item.icon} />;
            })}
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};
