/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Chip, Input, Label, RadioGroup, RadioGroupItem, Separator, Switch } from '../../flexyui';
import { LoginCustomization } from '../../../store/customization-slice';
import { AppDispatch } from '../../../store';
import TabLayoutLoading from '../../customization/customization-tabs/loading-screen/tab-layout-loading';
import { LayoutCard } from '../layout-card/layout-card';
import { CouponDisplay } from '../../../graphql';
import { DndContext, closestCenter, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CommonCustomizationEvents, EventCategory, LoginCustomizationEvents, trackEvents } from '../../../analytics';

interface CustomizationLayoutProps {
  tab: string;
  cards?: any;
  setCards?: any;
  loading?: boolean;
  lockCoupons: boolean;
  placeholder?: string;
  tabData?: LoginCustomization;
  setCustomization?: any;
}
export interface Item {
  position: number;
  text: string;
  enabled: boolean;
}

const CustomizationLayout: React.FC<CustomizationLayoutProps> = ({
  tab,
  tabData,
  cards,
  setCards,
  loading,
  lockCoupons = false,
  placeholder = 'Continue',
  setCustomization
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [activeId, setActiveId] = useState(null);

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.BANNER_REARRANGED}`
    );
  };

  const handleDragEnd = (event: any) => {
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setCards((items: Item[]) => {
        const oldIndex = items.findIndex((item: Item) => `${item.position}` === active.id);
        const newIndex = items.findIndex((item: Item) => `${item.position}` === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleBannerToggler = (value: boolean) => {
    const loginData = {
      ...tabData,
      banner: {
        ...tabData?.banner,
        enabled: value
      }
    };
    dispatch(setCustomization(loginData));
    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.BANNERS_STATUS_CHANGED}`
    );
  };
  const handleButtonText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const truncatedValue = event.target.value.slice(0, 30);
    const loginData = {
      ...tabData,
      button: {
        button_text: truncatedValue
      }
    };
    dispatch(setCustomization(loginData));
  };
  const handleShowCoupons = (value: boolean) => {
    const loginData = {
      ...tabData,
      coupons: {
        ...tabData?.coupons,
        show_coupons: value
      }
    };
    dispatch(setCustomization(loginData));
    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.COUPONS_STATUS_CHANGED}`
    );
  };
  const handleShowUpselling = (value: boolean) => {
    const loginData = {
      ...tabData,
      upselling: {
        ...tabData?.upselling,
        show_upselling: value
      }
    };
    dispatch(setCustomization(loginData));
    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.UPSELLING_STATUS_CHANGED}`
    );
  };
  const handleAccessCoupons = (value: boolean) => {
    const loginData = {
      ...tabData,
      coupons: {
        ...tabData?.coupons,
        lock_coupon_access: value
      }
    };
    dispatch(setCustomization(loginData));
    trackEvents(EventCategory.LOGIN_CUSTOMIZATION, LoginCustomizationEvents.LOCK_COUPONS);
  };
  const handleDisplayCoupons = (value: string) => {
    const loginData = {
      ...tabData,
      coupons: {
        ...tabData?.coupons,
        display: value
      }
    };
    dispatch(setCustomization(loginData));
    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.COUPONS_UI_CHANGED}`,
      {
        display: value
      }
    );
  };

  const handleLayoutOrder = () => {
    if (!cards) {
      return;
    }
    const order = cards?.map((card: Item, index: number) => ({ ...card, position: index }));
    const orderedCards = order.reduce((acc: any, array: any) => {
      if (array) {
        return acc.concat(array);
      }
      return acc;
    }, []);
    dispatch(
      setCustomization({
        ...tabData,
        banner: {
          ...tabData?.banner,
          banners: orderedCards
        }
      })
    );
  };

  const handleBannerEdit = (values: any, type: any) => {
    const updatedBanners = cards?.map((banner: any) =>
      banner.position === type ? { ...banner, text: values.text } : banner
    );

    setCards(updatedBanners);
    dispatch(
      setCustomization({
        ...tabData,
        banner: {
          ...tabData?.banner,
          banners: updatedBanners
        }
      })
    );

    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.BANNER_EDIT}`
    );
  };
  const handleBannerVisibility = (value: boolean, type: any) => {
    if (!cards) {
      console.error('cards is null or undefined');
      return;
    }

    const updatedBanners = cards?.map((banner: any) =>
      banner.position === type ? { ...banner, enabled: value } : banner
    );

    setCards(updatedBanners);
    dispatch(
      setCustomization({
        ...tabData,
        banner: {
          ...tabData?.banner,
          banners: updatedBanners
        }
      })
    );
    trackEvents(
      `${tab} ${EventCategory.CUSTOMIZATION}` as EventCategory,
      `${tab} ${CommonCustomizationEvents.BANNER_VISIBILITY_CHANGED}`
    );
  };

  const handleColorChangePrimary = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorscheme = e?.target.value;
    const data = {
      ...tabData,
      banner: {
        ...tabData?.banner,
        background_color: colorscheme
      }
    };
    dispatch(setCustomization(data));
  };
  const handleColorChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const colorscheme = e?.target.value;
    const data = {
      ...tabData,
      banner: {
        ...tabData?.banner,
        text_color: colorscheme
      }
    };
    dispatch(setCustomization(data));
  };

  // TODO: Don't remove

  // const handleUpsellingButtonColor = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const colorscheme = e?.target.value;
  //   const data = {
  //     ...tabData,
  //     upselling: {
  //       ...tabData?.upselling,
  //       button_color: colorscheme
  //     }
  //   };
  //   dispatch(setCustomization(data));
  // };

  useEffect(() => {
    handleLayoutOrder();
  }, [cards]);

  const renderLayoutsCard = useCallback(
    (card: Item, index: number) => {
      return (
        <SortableContext items={cards.map((item: Item) => `${item.position}`)} strategy={verticalListSortingStrategy}>
          <LayoutCard
            key={card.position}
            index={index}
            id={card.position}
            layout={card.text}
            cards={cards}
            handleEdit={handleBannerEdit}
            handleVisibility={handleBannerVisibility}
            visibility={card.enabled}
          />
        </SortableContext>
      );
    },
    [cards, tabData]
  );
  return (
    <>
      {loading ? (
        <TabLayoutLoading />
      ) : (
        <div key={tab}>
          <div className="flex justify-between items-center">
            <Label size={'md'}>
              Banner <Chip text="Banner is disabled" variant="warning" show={!tabData?.banner.enabled} />
            </Label>
            <Switch
              checked={tabData?.banner.enabled}
              className="pointer-events-auto mr-1"
              id={'banner'}
              onCheckedChange={handleBannerToggler}
            />
          </div>

          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <div className="mt-3">
              <div className="rounded-lg overflow-hidden border border-border">
                {cards?.map((card: Item, i: number) => renderLayoutsCard(card, i))}
              </div>
            </div>
          </DndContext>

          <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-8">
            <div className="flex">
              <input
                type="color"
                value={tabData?.banner.background_color}
                className="w-10 h-10 border border-border rounded-sm mr-3 cursor-pointer"
                onChange={handleColorChangePrimary}
              />
              <div className="flex flex-col">
                <Label size="paragraph">Banner background color</Label>
                <Label size="sm" className="text-[#888D9B] uppercase">
                  {tabData?.banner.background_color}
                </Label>
              </div>
            </div>
            <div className="flex">
              <input
                type="color"
                value={tabData?.banner.text_color}
                className="w-10 h-10 border border-border rounded-sm mr-3 cursor-pointer"
                onChange={handleColorChangeText}
              />
              <div className="flex flex-col">
                <Label size="paragraph">Text Color</Label>
                <Label size="sm" className="text-[#888D9B] uppercase">
                  {tabData?.banner.text_color}
                </Label>
              </div>
            </div>
          </div>

          {tab !== 'Payment' && (
            <>
              <Separator />
              <Label size={'md'}>Button</Label>
              <div className="mb-4 mt-3">
                <div className="flex justify-between">
                  <Label size={'paragraph'} className="text-[#595F74]">
                    Button Text
                  </Label>
                  <Label size={'paragraph'} className="text-[#595F74]">
                    {`${tabData?.button.button_text.length}/30`}
                  </Label>
                </div>
                <Input
                  type="text"
                  name="login"
                  placeholder={placeholder}
                  value={tabData?.button.button_text ?? ' '}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleButtonText(event)}
                />
              </div>
            </>
          )}
          <Separator />
          <Label size={'md'}>Coupons</Label>
          <div className="flex items-center gap-2 mt-4">
            <Switch id="show_coupons" checked={tabData?.coupons.show_coupons} onCheckedChange={handleShowCoupons} />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="show_coupons">
              Enable coupons
            </Label>
          </div>
          {lockCoupons && (
            <div className="flex items-center gap-2 mt-3">
              <Switch
                id="coupons-access"
                checked={tabData?.coupons.lock_coupon_access}
                onCheckedChange={handleAccessCoupons}
              />
              <Label size={'paragraph'} className="text-[#595F74]" htmlFor="coupons-access">
                Lock coupon access
              </Label>
            </div>
          )}
          <Separator lineStyle="dashed" />
          <Label>Coupon UI</Label>
          <RadioGroup className="mt-4 mb-2" value={tabData?.coupons.display} onValueChange={handleDisplayCoupons}>
            <div className="flex items-center">
              <RadioGroupItem value={CouponDisplay.CouponList} id={CouponDisplay.CouponList} />
              <Label
                size={'paragraph'}
                htmlFor={CouponDisplay.CouponList}
                className="text-[#595F74] cursor-pointer pl-2"
              >
                As coupon cards
              </Label>
            </div>
            <div className="flex items-center">
              <RadioGroupItem value={CouponDisplay.EntryField} id={CouponDisplay.EntryField} />
              <Label
                htmlFor={CouponDisplay.EntryField}
                size={'paragraph'}
                className="text-[#595F74] cursor-pointer pl-2"
              >
                As code entry field
              </Label>
            </div>
          </RadioGroup>
          <Separator />
          <Label size={'md'}>Upselling</Label>
          <div className="flex items-center gap-2 mt-4">
            <Switch
              id="show_upselling"
              checked={tabData?.upselling?.show_upselling}
              onCheckedChange={handleShowUpselling}
            />
            <Label size={'paragraph'} className="text-[#595F74]" htmlFor="show_upselling">
              Enable upselling
            </Label>
          </div>
          {/* TODO: Don't remove */}
          {/* <div className="flex mt-3">
            <input
              type="color"
              value={tabData?.upselling.button_color}
              className="w-10 h-10 border border-border rounded-sm mr-3 cursor-pointer"
              onChange={handleUpsellingButtonColor}
            />
            <div className="flex flex-col">
              <Label size="paragraph">Button Color</Label>
              <Label size="sm" className="text-[#888D9B] uppercase">
                {tabData?.upselling.button_color}
              </Label>
            </div>
          </div> */}
        </div>
      )}
    </>
  );
};

export default CustomizationLayout;
