import * as Yup from 'yup';

export const businessDetailsSchema = (useGst: boolean) => {
  return Yup.object().shape({
    legal_name: Yup.string().required('Legal name is required'),
    trade_name: Yup.string().required('Trade name is required'),
    GSTIN: useGst ? Yup.string().required('GSTIN is required') : Yup.string(),
    company_name_on_gst: useGst ? Yup.string().required('Company name on GST is required') : Yup.string(),
    address: Yup.object().shape({
      address1: Yup.string().required('Address Line 1 is required'),
      address2: Yup.string(),
      city: Yup.string().required('City is required'),
      state: Yup.string().required('State is required'),
      country: Yup.string().required('Country is required'),
      postal_code: Yup.number().required('Postal code is required')
    })
  });
};
