import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label
} from '../../flexyui';
import React, { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import * as Unicons from '@iconscout/react-unicons';
import { isValidEmail } from '../../../utils/validation';
import { RequestType, useSendMetricsMailMutation } from '../../../graphql';
import { AnalyticEvents, EventCategory, trackEvents } from '../../../analytics';

type Props = {
  dateRange: any;
  requestType: RequestType;
};

export const DownloadReport: React.FC<Props> = ({ dateRange, requestType }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const [sendMetricsMail] = useSendMetricsMailMutation();

  const handleSendMetricsMail = async () => {
    try {
      await sendMetricsMail({
        variables: {
          dateRange: {
            start: new Date(dateRange.startDate).toISOString(),
            end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
          },
          requestType: requestType,
          receiverEmail: email
        }
      });

      enqueueSnackbar('Report sent successfully!', {
        variant: 'success'
      });
      trackEvents(EventCategory.ANALYTICS, AnalyticEvents.SEND_REPORT_CLICKED, {
        requestType,
        receiverEmail: email
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  return (
    <>
      <div
        className="text-[#2F72FF] flex items-center cursor-pointer font-medium px-3.5 py-1 rounded-2xl shadow-md hover:shadow-lg hover:bg-slate-50 transition-all ease-linear"
        onClick={() => {
          setShowModal(true);
          trackEvents(EventCategory.ANALYTICS, AnalyticEvents.GET_REPORT_CLICKED, {
            requestType
          });
        }}
      >
        <Unicons.UilFileDownloadAlt size={18} />
        Get report
      </div>

      <Dialog open={showModal} onOpenChange={(value: boolean) => setShowModal(value)}>
        <DialogContent close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Download Report</p>
              </DialogTitle>
              <DialogDescription className="text-gray-600 !mt-4">
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  placeholder="example@work.com"
                  className="mt-1"
                  value={email}
                  onChange={(e: any) => setEmail(e?.target?.value || '')}
                  onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      await handleSendMetricsMail();
                      setEmail('');
                      setShowModal(false);
                    }
                  }}
                />
                <div className="text-[rgb(89,95,116)] leading-4 text-sm mt-4">
                  The report will be send by email within a few minutes
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={() => {
                setEmail('');
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              size={'md'}
              disabled={!isValidEmail(email)}
              onClick={async () => {
                await handleSendMetricsMail();
                setEmail('');
                setShowModal(false);
              }}
            >
              Send
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
