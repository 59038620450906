import {
  setStore,
  setStoreName,
  setUserEmail,
  setUserName,
  setUserType,
  setUserPasswordSet,
  setStoreOnBoardingState
} from '../store/main-slice';
import jwtDecode from 'jwt-decode';
import { useAuth } from '../context/auth';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Login } from '../pages/login/login';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Onboarding } from '../pages/onboarding/onboarding';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SetPassword } from '../pages/set-password/set-password';
import { ResetPassword } from '../pages/reset-password/reset-password';
import { ForgotPassword } from '../pages/forgot-password/forgot-password';
import { AuthenticatedApp } from '../pages/authenticated-app/authenticated-app';
import { useSessionTracker, setMixpanelSuperProperties, setMixpanelMerchantGroup, setMixpanelUser } from '../analytics';

function MainApp() {
  const location = useLocation();
  const { accessToken, setAccessToken, setRefreshToken } = useAuth();
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams, setSearchParams] = useSearchParams();

  useSessionTracker();

  const isUserPasswordSet = useSelector((state: RootState) => state.main.userPasswordSet);
  const storeOnBoardingState = useSelector((state: RootState) => state.main.storeOnBoardingState);
  const [forgotPasswordActive, setForgotPasswordActive] = useState(false);

  // Extract query parameters
  const queryAccessType = searchParams.get('access_type');
  const queryAccessToken = searchParams.get('access');
  const queryRefreshToken = searchParams.get('refresh');
  const resetPasswordToken = searchParams.get('token');

  // Handle restricted access type
  useEffect(() => {
    if (queryAccessType === 'restricted') {
      enqueueSnackbar('Access denied! You do not have permissions to open this app', {
        variant: 'error'
      });
      setSearchParams((params) => {
        params.delete('access_type');
        return params;
      });
    }
  }, [queryAccessType]);

  // Handle access and refresh tokens
  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken);
      setSearchParams((params) => {
        params.delete('access');
        return params;
      });
    }

    if (queryRefreshToken) {
      setRefreshToken(queryRefreshToken);
      setSearchParams((params) => {
        params.delete('refresh');
        return params;
      });
    }
  }, [queryAccessToken, queryRefreshToken]);

  // Decode access token if available
  useEffect(() => {
    if (accessToken) {
      const user: any = jwtDecode(accessToken);
      dispatch(setStore(user.payload.merchant_id));
      dispatch(setStoreName(user.payload.merchant_name));
      dispatch(setUserName(user.payload.name));
      dispatch(setUserEmail(user.payload.email));
      dispatch(setUserType(user.payload.user_type));
      dispatch(setStoreOnBoardingState(user.payload.on_boarding_state));
      dispatch(setUserPasswordSet(user.payload.is_password_set));

      setMixpanelSuperProperties({
        merchant_name: user.payload.merchant_name,
        user_name: user.payload.name,
        user_email: user.payload.email,
        user_type: user.payload.user_type
      });

      setMixpanelMerchantGroup(user.payload.merchant_id, {
        name: user.payload.merchant_name
      });

      setMixpanelUser(user.sub, {
        user_id: user.sub,
        name: user.payload.name,
        email: user.payload.email,
        $email: user.payload.email,
        user_type: user.payload.user_type
      });
    }
  }, [accessToken]);

  if (location.pathname === '/reset-password' && !!resetPasswordToken)
    return <ResetPassword token={resetPasswordToken} />;
  if (forgotPasswordActive) return <ForgotPassword setForgotPasswordActive={setForgotPasswordActive} />;
  if (!accessToken) return <Login setForgotPasswordActive={setForgotPasswordActive} />;

  if (storeOnBoardingState && storeOnBoardingState !== 'COMPLETED') return <Onboarding />;
  if (typeof isUserPasswordSet === 'boolean' && !isUserPasswordSet) return <SetPassword />;

  return <AuthenticatedApp />;
}
export default MainApp;
