import dayjs from 'dayjs';
import { ItemsFrom, UserScope } from '../../constants/coupons';
import { ApplicationTarget, ApplicationType, CouponType, DiscountType, PurchaseActivityType } from '../../graphql';

export const getCouponInitialValues = (couponDetails: any, defaultCouponType: CouponType) => {
  return {
    code: couponDetails?.code || '',
    title: couponDetails?.title || '',
    has_title: !!couponDetails?.title,
    application_type: couponDetails?.application_type || ApplicationType.Code,
    coupon_type: couponDetails?.coupon_type || defaultCouponType,
    discount_type:
      (couponDetails?.coupon_type !== CouponType.Bxgy && couponDetails?.discount_type) || DiscountType.Percentage,
    discount_amount: (couponDetails?.coupon_type !== CouponType.Bxgy && couponDetails?.discount_amount) || null,
    discount_max_cap: couponDetails?.discount_max_cap || null,
    show_discount_max_cap: !!couponDetails?.discount_max_cap,
    applies_on_each_item: couponDetails?.applies_on_each_item || false,
    disable_cod: couponDetails?.disable_cod || false,
    add_on: {
      max_quantity: couponDetails?.add_on?.max_quantity,
      products: couponDetails?.add_on?.products || []
    },
    application: {
      type: couponDetails?.application?.type || ApplicationTarget.Universal,
      products: couponDetails?.application?.products || [],
      collections: couponDetails?.application?.collections || [],
      max_per_order: couponDetails?.application?.max_per_order || null,
      show_max_per_order: !!(couponDetails?.application && couponDetails?.application?.max_per_order),
      min_quantity: couponDetails?.application?.min_quantity || null,
      items_from:
        couponDetails?.application?.products && couponDetails?.application?.products?.length > 0
          ? ItemsFrom.SPECIFIC_PRODUCTS
          : couponDetails?.application?.collections && couponDetails.application?.collections.length > 0
            ? ItemsFrom.SPECIFIC_COLLECTION
            : ItemsFrom.SPECIFIC_PRODUCTS,
      discount_type:
        couponDetails?.coupon_type === CouponType.Bxgy &&
        couponDetails?.discount_type === DiscountType.Percentage &&
        couponDetails?.discount_amount === 100
          ? 'FREE'
          : couponDetails?.discount_type || DiscountType.Percentage,
      discount_amount: (couponDetails?.coupon_type === CouponType.Bxgy && couponDetails?.discount_amount) || null
    },
    purchase_requirement: {
      show_max_amount: !!couponDetails?.purchase_requirement?.max_amount,
      show_max_quantity: !!couponDetails?.purchase_requirement?.max_quantity,
      type: couponDetails?.purchase_requirement?.type || 'NONE',
      min_amount: couponDetails?.purchase_requirement?.min_amount || null,
      max_amount: couponDetails?.purchase_requirement?.max_amount || null,
      min_quantity: couponDetails?.purchase_requirement?.min_quantity || null,
      max_quantity: couponDetails?.purchase_requirement?.max_quantity || null,
      productType:
        couponDetails?.purchase_requirement?.products?.length > 0
          ? 'PURCHASE_PRODUCTS'
          : couponDetails?.purchase_requirement?.collections?.length > 0
            ? 'PURCHASE_COLLECTIONS'
            : 'PURCHASE_CART',
      products: couponDetails?.purchase_requirement?.products || [],
      collections: couponDetails?.purchase_requirement?.collections || [],
      items_from:
        couponDetails?.purchase_requirement?.products && couponDetails?.purchase_requirement?.products?.length > 0
          ? ItemsFrom.SPECIFIC_PRODUCTS
          : couponDetails?.purchase_requirement?.collections &&
              couponDetails?.purchase_requirement?.collections.length > 0
            ? ItemsFrom.SPECIFIC_COLLECTION
            : ItemsFrom.SPECIFIC_PRODUCTS
    },
    purchase_activity: {
      type: couponDetails?.purchase_activity?.type || PurchaseActivityType.CouponCode,
      min_amount: couponDetails?.purchase_activity?.min_amount || null,
      min_quantity: couponDetails?.purchase_activity?.min_quantity || null,
      coupon_usage: couponDetails?.purchase_activity?.coupon_usage || null,
      selected_coupon_id: couponDetails?.purchase_activity?.coupon_usage?.coupon?.id || ''
    },
    purchase_activity_period: {
      start: couponDetails?.purchase_activity_period?.start || dayjs().format('YYYY-MM-DDTHH:mm'),
      end: couponDetails?.purchase_activity_period?.end || dayjs().format('YYYY-MM-DDTHH:mm'),
      has_endTime: !!couponDetails?.purchase_activity_period?.end
    },
    user_specific_metadata: {
      user_scope: couponDetails?.user_specific_metadata?.resource_id ? UserScope.SPECIFIC_USERS : UserScope.ALL_USERS,
      resource_id: couponDetails?.user_specific_metadata?.resource_id || null,
      file_orginial_name: couponDetails?.user_specific_metadata?.file_orginial_name || null,
      download_url: couponDetails?.user_specific_metadata?.download_url || null
    },
    user_order_requirement: {
      max_order_count: couponDetails?.user_order_requirement?.max_order_count || null,
      max_order_count_needed: !!couponDetails?.user_order_requirement?.max_order_count,
      min_order_count: couponDetails?.user_order_requirement?.min_order_count || null,
      type:
        couponDetails?.user_order_requirement?.max_order_count === 0 &&
        couponDetails?.user_order_requirement?.min_order_count === null
          ? 'first'
          : couponDetails?.user_order_requirement?.min_order_count >= 0 &&
              couponDetails?.user_order_requirement?.min_order_count !== null
            ? 'order'
            : 'all'
    },
    show_per_customer_usage_limit: !!couponDetails?.per_customer_usage_limit,
    show_total_usage_limit: !!couponDetails?.total_usage_limit,
    discoverable: couponDetails?.discoverable || false,
    combine: couponDetails?.combine || false,
    activation_period: {
      start: couponDetails?.activation_period?.start || dayjs().format('YYYY-MM-DDTHH:mm'),
      end: couponDetails?.activation_period?.end || dayjs().format('YYYY-MM-DDTHH:mm'),
      has_endTime: !!couponDetails?.activation_period?.end
    },
    per_customer_usage_limit: couponDetails?.per_customer_usage_limit || null,
    total_usage_limit: couponDetails?.total_usage_limit || null
  };
};
