/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { UilEye } from '@iconscout/react-unicons';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { customisationToken } from '../../network/customization/customization';
import { AlertDialog, AlertDialogContent, Button, Card } from '../flexyui';
import { RootState } from '../../store';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { MethodLayoutStyle } from '../../graphql';
import { CommonCustomizationEvents, EventCategory, trackEvents } from '../../analytics';

declare global {
  interface Window {
    flexypeConfig: any;
  }
}

export const CustomizationRightPanel: React.FC = () => {
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeLoadedWeb, setIframeLoadedWeb] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingWeb, setLoadingWeb] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const currentPage = useSelector((state: RootState) => state.customization.page);
  const customization = useSelector((state: RootState) => state.customization);
  const paymentCustomization = useSelector((state: RootState) => state.customization.payment);
  const storeName = useSelector((state: RootState) => state.main.storeName);

  const handleOnIframeLoad = () => {
    setIframeLoaded((prev) => !prev);
  };
  const handleOnIframeLoadWeb = () => {
    setTimeout(() => {
      setIframeLoadedWeb((prev) => !prev);
    }, 1000);
  };

  const { data } = useQuery({
    queryKey: ['customisationToken'],
    queryFn: () => customisationToken(),
    retry: false,
    refetchOnWindowFocus: false
  });

  const debouncedPostMessage = (flexyData: any, type: 'MOBILE' | 'DESKTOP') => {
    if (type === 'MOBILE') {
      const flexyFrame = window.document?.getElementById('flexy-checkout-frame') as HTMLIFrameElement;
      flexyFrame?.contentWindow?.postMessage(
        `flexy-token|${JSON.stringify(data)}|${storeName || localStorage.getItem('store_name')}`,
        '*'
      );
      flexyFrame?.contentWindow?.postMessage(
        `flexy-config|-|-|${JSON.stringify(flexyData)}|-|-|${storeName || localStorage.getItem('store_name')}`,
        '*'
      );
    }
    if (type === 'DESKTOP') {
      const flexyFrame = window.document?.getElementById('flexy-checkout-frame-web') as HTMLIFrameElement;
      flexyFrame?.contentWindow?.postMessage(
        `flexy-token|${JSON.stringify(data)}|${storeName || localStorage.getItem('store_name')}`,
        '*'
      );
      flexyFrame?.contentWindow?.postMessage(
        `flexy-config-web|-|-|${JSON.stringify(flexyData)}|-|-|${storeName || localStorage.getItem('store_name')}`,
        '*'
      );
    }
  };

  const paymentDataModel = useMemo(() => {
    const layoutStyle = paymentCustomization?.method_layout_style as MethodLayoutStyle;
    const commonDiscount = {
      discount_amount: 5,
      discount_type: 'PERCENTAGE',
      coupon_id: '762d5efa-7a28-4bb6-8594-f76b992be8f1',
      code: 'PAY'
    };

    return {
      ...paymentCustomization,
      payment_methods: paymentCustomization?.payment_methods
        .filter((each: any) => each.key !== 'PARTIAL_COD')
        ?.map((payment) => {
          const title = payment.layout_title?.[layoutStyle];
          const description =
            layoutStyle !== MethodLayoutStyle.Layout_3 ? payment.layout_description?.[layoutStyle] : '';

          if (payment.key === 'COD') {
            return {
              ...payment,
              title,
              description,
              provider: 'CASH_ON_DELIVERY',
              discount: 0,
              total_payable: 1824.95
            };
          }

          return {
            ...payment,
            title,
            description,
            discount_amount: 5,
            provider: 'RAZORPAY',
            discount: 177.99,
            total_payable: 1686.2,
            coupon_data: commonDiscount
          };
        })
    };
  }, [paymentCustomization, currentPage === 'Payment']);

  useEffect(() => {
    const flexyData = {
      customisations: {
        general_config: customization.general,
        login_customisation_config: customization.login,
        address_customisation_config: customization.address,
        payment_customisation_config: paymentDataModel
      }
    };
    debouncedPostMessage(flexyData, 'MOBILE');
    debouncedPostMessage(flexyData, 'DESKTOP');
    if (iframeLoaded) {
      setLoading(false);
    }
    if (iframeLoadedWeb) {
      setLoadingWeb(false);
    }
  }, [iframeLoaded, customization, iframeLoadedWeb, debouncedPostMessage, currentPage, paymentCustomization]);

  useEffect(() => {
    const eventListener = async (event: any) => {
      if (typeof event?.data === 'string' && event.data.startsWith('flexy-checkout-close')) {
        setDialogOpen(false);
      }
    };

    window.addEventListener('message', eventListener, false);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, []);

  return (
    <>
      <div
        className="text-primary underline-offset-4 hover:underline !p-0 flex cursor-pointer absolute right-2 -top-[2.75rem] sm:hidden"
        onClick={() => {
          setDialogOpen(true);
          trackEvents(EventCategory.CUSTOMIZATION, CommonCustomizationEvents.DESKTOP_PREVIEW);
        }}
      >
        <UilEye width={20} height={20} className="mr-1" />
        Preview
      </div>
      <div className="w-full sticky top-[12%] scroll-pt-10 mt-[18px] 2xl:top-14 hidden sm:block">
        <div
          className="max-h-[600px] min-h-[90%] max-w-[400px]"
          style={{
            aspectRatio: '9/16'
          }}
        >
          <div className="flex ml-auto w-full justify-end">
            <div
              className="text-primary underline-offset-4 hover:underline !p-0 flex cursor-pointer"
              onClick={() => {
                setDialogOpen(true);
                trackEvents(EventCategory.CUSTOMIZATION, CommonCustomizationEvents.DESKTOP_PREVIEW);
              }}
            >
              <UilEye width={20} height={20} className="mr-1" />
              Preview Desktop
            </div>
          </div>
          {loading ? (
            <div className="!min-w-[100%] h-full flex items-center justify-center shadow-2xl border-6 border-solid mt-3 border-gray-500 rounded-2xl min-h-[500px] max-h-[600px] w-[280px]">
              <div className="w-full flex justify-center py-6 px-4">
                <LoadingIcon height={16} className="mr-3 h-5 w-5 animate-spin text-slate-500" />
                <span>Loading Preview</span>
              </div>
            </div>
          ) : null}
          <iframe
            title="Flexy Checkout"
            src={`${process.env.REACT_APP_CHECKOUT_URL}/?session=8b7aea2c-d0fc-49c4-985e-648a20ef8d24&page=${currentPage}&mode=preview`}
            id="flexy-checkout-frame"
            allow="otp-credentials"
            key={'flexy-checkout-frame'}
            className={classNames(
              '!min-w-[100%] w-full mt-3 shadow-2xl border-6 border-solid border-gray-500 rounded-2xl !max-w-[400px] min-h-[500px] h-full max-h-[600px]',
              {
                hidden: loading
              }
            )}
            onLoad={handleOnIframeLoad}
          ></iframe>
        </div>
      </div>

      <AlertDialog
        onOpenChange={(open) => {
          if (!open) {
            setIframeLoadedWeb(false);
            setLoadingWeb(true);
          }
        }}
        open={dialogOpen}
      >
        <AlertDialogContent
          size="fullWidth"
          className="border-none shadow-none outline-none ring-0 bg-white sm:bg-transparent"
        >
          <div className="w-full h-full flex items-center justify-center relative">
            <Button
              size="icon"
              variant="icon"
              className="absolute shadow-md top-3 right-3 block sm:hidden"
              onClick={() => setDialogOpen(false)}
            >
              <Unicons.UilTimes className="text-[#2F72FF]" />
            </Button>
            {loadingWeb && (
              <Card className="sm:h-[600px] sm:w-[100%] sm:max-w-[800px] bg-white flex justify-center items-center outline-none border-none rounded-2xl shadow-none sm:shadow-sm">
                <div className="w-full flex justify-center py-6 px-4">
                  <LoadingIcon height={16} className="mr-3 h-5 w-5 animate-spin text-slate-500" />
                  <span>Loading Preview</span>
                </div>
              </Card>
            )}
            <iframe
              title="Flexy Checkout Web"
              src={`${process.env.REACT_APP_CHECKOUT_URL}/?session=8b7aea2c-d0fc-49c4-985e-648a20ef8d24&page=${currentPage}&mode=preview`}
              id="flexy-checkout-frame-web"
              allow="otp-credentials"
              key={'flexy-checkout-frame-web'}
              className={classNames('!min-w-full h-full', {
                hidden: loadingWeb
              })}
              onLoad={handleOnIframeLoadWeb}
            ></iframe>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
