/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, ExpandedState, getExpandedRowModel } from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../flexyui';
import { Skeleton } from '../flexyui/Skeleton';
import { closestCenter, DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { UpsellingProductTableRow } from './upselling-product-table-row';
import { ColumnDef } from '@tanstack/table-core';
import { EventCategory, trackEvents, UpsellingEvents } from '../../analytics';

type props = {
  mainColumns: ColumnDef<any, any>[];
  subRowColumns: ColumnDef<any, any>[];
  data: any;
  setProducts: (data: any) => void;
  expanded: ExpandedState;
  setExpanded: (data: any) => void;
  loading: boolean;
};

export const UpsellingProductTable: React.FC<props> = ({
  mainColumns,
  subRowColumns,
  data,
  setProducts,
  expanded,
  setExpanded,
  loading
}) => {
  const [activeId, setActiveId] = useState(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const getSubRows = (row: any) => row.variants;
  const table = useReactTable({
    data,
    columns: mainColumns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      expanded
    },
    onExpandedChange: setExpanded,
    getSubRows: getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
    enablePinning: true,
    autoResetAll: false
  });

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (event: any) => {
    setIsDragging(true);
    setActiveId(event.active.id);
    trackEvents(EventCategory.UPSELLING, UpsellingEvents.PRODUCT_REARRANGED);
  };

  const handleDragEnd = (event: any) => {
    // table.toggleAllRowsExpanded(false);
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setProducts((items: any) => {
        const oldIndex = items.findIndex((item: any) => item.product_id === active.id);
        const newIndex = items.findIndex((item: any) => item.product_id === over.id);

        const newExpanded: any = {};
        Object.keys(expanded).forEach((key) => {
          const keyInt = parseInt(key, 10);
          let newKey = keyInt;

          if (keyInt === oldIndex) {
            newKey = newIndex;
          } else if (keyInt > oldIndex && keyInt <= newIndex) {
            newKey = keyInt - 1;
          } else if (keyInt < oldIndex && keyInt >= newIndex) {
            newKey = keyInt + 1;
          }

          newExpanded[newKey] = true;
        });

        setExpanded(newExpanded);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setIsDragging(false);
  };

  return (
    <div className="rounded-md h-fit border overflow-y-hidden">
      <Table className="rounded-md">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="bg-[#F3F4F5] text-[#595F74]">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {loading &&
            [...Array(5)].map((_, index) => (
              <TableRow key={index} className="hover:bg-transparent">
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <TableCell key={header.id} className="px-2 py-4">
                    <Skeleton size="xl" />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {data && table.getRowModel().rows?.length > 0 ? (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              onDragStart={handleDragStart}
            >
              <SortableContext
                items={table
                  .getRowModel()
                  .rows.filter((row) => row.depth === 0)
                  .map((row) => row.original.product_id)}
                strategy={verticalListSortingStrategy}
              >
                {table
                  .getRowModel()
                  .rows.filter((row) => row.depth === 0)
                  .map((row) => (
                    <UpsellingProductTableRow row={row} subRowColumns={subRowColumns} dragState={isDragging} />
                  ))}
              </SortableContext>
            </DndContext>
          ) : data && !loading && table.getRowModel().rows?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={mainColumns?.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </div>
  );
};
