/* eslint-disable import/default */
import dayjs from 'dayjs';
import Charts from 'react-apexcharts';
import React, { useCallback } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { DashboardGraphTitle } from '../title/title';
import { options } from '../../../constants/dashboard';
import { PerformanceMetricsQuery } from '../../../graphql';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { convertAmount, calculateIncrement } from '../../../utils/convert-amount';
import { SkeletonDashboard } from '../../shared/skeleton-dashboard/skeleton-dashboard';

type Props = {
  metricsData?: PerformanceMetricsQuery['performanceMetrics'];
  loading: boolean;
};

export const RevenuePerVisitor: React.FC<Props> = ({ metricsData, loading }) => {
  const calculateData = useCallback(() => {
    const dates: any = [];
    const currentData = metricsData?.graph?.current?.map((item: any) => {
      dates.push(dayjs.utc(item?.date_time).format('MMM D h:mm A'));
      return Math.round(item?.revenue_per_visitor);
    });
    const prevData = metricsData?.graph?.previous?.map((item: any) => {
      return Math.round(Number(item?.revenue_per_visitor));
    });

    return {
      series: [
        {
          name:
            dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D') !==
            dayjs.utc(metricsData?.metrics?.current?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.metrics?.current?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.metrics?.current?.start).format('MMM D')}`,
          data: currentData || []
        },
        {
          name:
            dayjs.utc(metricsData?.metrics?.previous?.start).format('MMM D') !==
            dayjs.utc(metricsData?.metrics?.previous?.end).format('MMM D')
              ? `${dayjs.utc(metricsData?.metrics?.previous?.start).format('MMM D')} - ${dayjs
                  .utc(metricsData?.metrics?.previous?.end)
                  .format('MMM D')}`
              : `${dayjs.utc(metricsData?.metrics?.previous?.end).format('MMM D')}`,
          data: prevData || [],
          color: '#C5C5C5'
        }
      ],
      dates
    };
  }, [metricsData]);

  options.xaxis.tickAmount = calculateData().dates.length < 30 ? 3 : 4;
  options.xaxis.categories =
    calculateData().dates.length === 24
      ? calculateData().dates.map((date: any) => dayjs(date).format('MMM D h:mm A'))
      : calculateData().dates.map((date: any) => dayjs(date).format('MMM D'));

  return (
    <div className="bg-white rounded-br-lg">
      <div className="font-medium text-black pt-4 px-4 flex items-center">
        <div>Revenue per visitor</div>
        <ButtonWithInfo
          heading="Revenue per Visitor"
          description="Average revenue generated per checkout visitor."
          calculation={true}
          calculationVariable="RPV"
          calculationValue="net sales / number of checkout visitors"
          align="center"
        >
          <Unicons.UilInfoCircle size={15} className="ml-1.5" />
        </ButtonWithInfo>
      </div>
      {loading ? (
        <SkeletonDashboard heightGraph="h-[50px]" />
      ) : (
        <>
          <DashboardGraphTitle
            loading={loading}
            value={convertAmount(Number(metricsData?.metrics?.current?.revenue_per_visitor || '0'))}
            title="Average Order Value"
            percentageChange={calculateIncrement(
              Number(metricsData?.metrics?.current?.revenue_per_visitor),
              Number(metricsData?.metrics?.previous?.revenue_per_visitor)
            )}
          />
          {metricsData?.metrics?.current?.start ? (
            <Charts options={options} series={calculateData().series} type="line" height={100} />
          ) : (
            <div className="text-center my-8">
              <div className="text-gray-300 text-base">Not Available</div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
