import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Card, Chip, Skeleton } from '../../../../flexyui';
import { GoogleAnalyticsCard } from '../google-analytics/google-analytics-card';
import { GoogleAnalyticsDialog } from '..//google-analytics/google-analytics-dialog';
import { EventCategory, IntegrationEvent, trackEvents } from '../../../../../analytics';
import ConfirmationDialog from '../../../../shared/confirmation-dialog/confirmation-dialog';
import { GoogleAnalyticsConversionAction, useGoogleAnalyticsQuery } from '../../../../../graphql';

type Props = {
  handleAnalyticsStatusChange: (value: boolean, analytics: string, refetch: () => void) => void;
};

export const GoogleAnalytics: React.FC<Props> = ({ handleAnalyticsStatusChange }) => {
  const navigate = useNavigate();
  const { data, loading, refetch } = useGoogleAnalyticsQuery();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [conversionToEdit, setConversionToEdit] = useState<GoogleAnalyticsConversionAction | null>(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const handleEditClick = (conversion: GoogleAnalyticsConversionAction) => {
    setConversionToEdit(conversion);
    setShowModal(true);
  };

  const handleGoogleAnalyticsConversionStatus = () => {
    if (data?.googleAnalytics?.conversion_actions?.length === 0)
      handleAnalyticsStatusChange(true, 'google_analytics', refetch);
    if (data?.googleAnalytics?.conversion_actions?.length === 1)
      handleAnalyticsStatusChange(false, 'google_analytics', refetch);
  };

  return (
    <>
      <div className="items-center font-semibold text-lg my-2 hidden sm:flex">
        <Button
          size="icon"
          variant="ghost"
          className="hover:bg-gray-200 rounded-lg w-7 h-7 p-0.5"
          onClick={() => navigate('/settings/integrations')}
        >
          <Unicons.UilArrowLeft className="text-[#2A324C]" />
        </Button>
        <div className="ml-1.5">Google Analytics</div>
      </div>

      <Card className="p-3 pl-4">
        <div className="flex items-center">
          <div>Enable Google Analytics(UA)</div>
          <div className="ml-2 hidden sm:block">
            {loading ? (
              <Skeleton variant="circular" className="!m-0 w-[70px]" />
            ) : (
              <Chip
                text={data?.googleAnalytics?.enabled ? 'Enabled' : 'Disabled'}
                variant={data?.googleAnalytics?.enabled ? 'success' : 'draft'}
              />
            )}
          </div>
          <div className="flex items-center justify-end flex-1">
            {loading ? (
              <Skeleton className="w-[95px] h-[36px] !m-0" />
            ) : (
              <Button
                size={'md'}
                onClick={() => setShowConfirmationDialog(true)}
                variant={data?.googleAnalytics?.enabled ? 'outline' : 'default'}
                disabled={data?.googleAnalytics?.conversion_actions?.length === 0}
              >
                {data?.googleAnalytics?.enabled ? 'Turn off' : 'Turn on'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <Card className="mt-4 p-4">
        <div className="flex items-center justify-between mb-4">
          <div>
            <div className="font-medium">Conversion Actions</div>
            <div className="text-[#888D9B] text-xs">
              Add your tracking conversions to monitor and optimize user interactions.
            </div>
          </div>
          {(data?.googleAnalytics?.conversion_actions?.length || 0) > 0 && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => {
                setConversionToEdit(null);
                setShowModal(true);
              }}
            >
              <Unicons.UilPlus size={18} className="mr-2" />
              Add google analytics credential
            </Button>
          )}
        </div>
        <div>
          {loading && (
            <div className="border border-muted px-4 py-4 rounded-[6px]">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-5 w-5" />
                  <Skeleton className="w-[150px] !m-0" />
                </div>
                <div className="flex items-center gap-2">
                  <Skeleton className="!m-0 h-8 w-8" />
                  <Skeleton className="!m-0 h-8 w-8" />
                </div>
              </div>
              <div className="mt-4">
                <Skeleton className="w-full sm:w-[450px] !m-0 !mb-2" />
                <Skeleton className="w-[70%] sm:w-[300px] !m-0" />
              </div>
            </div>
          )}

          {data?.googleAnalytics?.conversion_actions?.length === 0 ? (
            <div className="text-center border border-muted px-4 py-8 rounded-[6px]">
              <Button variant="primary" size="sm" onClick={() => setShowModal(true)}>
                <Unicons.UilPlus size={18} className="mr-2" />
                Add google analytics credential
              </Button>
            </div>
          ) : (
            data?.googleAnalytics?.conversion_actions?.map((conversion) => (
              <GoogleAnalyticsCard
                conversion={conversion}
                handleEditClick={handleEditClick}
                refetch={refetch}
                handleGoogleAnalyticsConversionStatus={handleGoogleAnalyticsConversionStatus}
              />
            ))
          )}
        </div>
      </Card>

      <GoogleAnalyticsDialog
        open={showModal}
        setOpen={setShowModal}
        conversionData={conversionToEdit}
        setConversionData={setConversionToEdit}
        handleGoogleAnalyticsConversionStatus={handleGoogleAnalyticsConversionStatus}
        refetch={refetch}
      />

      <ConfirmationDialog
        showModal={showConfirmationDialog}
        setShowModal={setShowConfirmationDialog}
        onSave={() => {
          handleAnalyticsStatusChange(!data?.googleAnalytics?.enabled, 'google_analytics', refetch);
          setShowConfirmationDialog(false);
          trackEvents(EventCategory.INTEGRATIONS, IntegrationEvent.GA_STATUS_CHANGED);
        }}
        text={`This action will ${data?.googleAnalytics?.enabled ? 'disable' : 'enable'} the Google Analytics`}
      />
    </>
  );
};
