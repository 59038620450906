import React, { useEffect } from 'react';
import { Poc } from './poc';
import { Users } from './users';
import { Separator } from '../flexyui';
import { StepContent } from './step-content';
import { StepCallback } from '@/src/pages/onboarding/onboarding';
import { useGetMerchantPoCsQuery, useGetUsersQuery } from '../../graphql';

type Props = {
  activeStep: number;
  setStepCallback: (callback: StepCallback) => void;
  setDisabled: (disabled: boolean) => void;
};

export const BusinessTeam: React.FC<Props> = ({ activeStep, setDisabled }) => {
  const { data: pocData, loading: pocLoading, refetch: pocRefetch } = useGetMerchantPoCsQuery();
  const { data: usersData, loading: usersLoading, refetch: usersRefetch } = useGetUsersQuery();

  useEffect(() => {
    setDisabled(
      pocData?.getMerchantPOCs?.length === 0 || usersData?.getUsers?.length === 0 || pocLoading || usersLoading
    );
  }, [pocData, pocLoading, usersData, usersLoading]);

  return (
    <>
      <StepContent activeStep={activeStep} />
      <Poc data={pocData} loading={pocLoading} refetch={pocRefetch} />
      <Separator />
      <Users data={usersData} loading={usersLoading} refetch={usersRefetch} />
    </>
  );
};
