import React from 'react';
import { DateTime } from 'luxon';
import * as Unicons from '@iconscout/react-unicons';
import { Button } from '../../flexyui';
import { AnalyticEvents, EventCategory, trackEvents } from '../../../analytics';

type Props = {
  setShowDateRange: (data?: any) => void;
  showDateRange?: boolean;
  ranges?: any;
};

export const DatePickerDisplayButton: React.FC<Props> = ({ setShowDateRange, showDateRange, ranges }) => {
  return (
    <Button
      variant="outline"
      onClick={() => {
        setShowDateRange(!showDateRange);
        trackEvents(EventCategory.ANALYTICS, AnalyticEvents.DATE_PICKER_CLICKED);
      }}
      className="hover:bg-slate-100 border-[1.5px] rounded-lg font-medium w-fit"
    >
      <div className="flex items-center gap-1">
        <Unicons.UilCalendarAlt
          size={16}
          style={{
            marginRight: '8px',
            marginBottom: '2px'
          }}
        />
        <span>
          {DateTime.fromJSDate(new Date(ranges.startDate)).toFormat('dd LLL yy')} -{' '}
          {DateTime.fromJSDate(new Date(ranges.endDate)).toFormat('dd LLL yy')}
        </span>
      </div>
    </Button>
  );
};
