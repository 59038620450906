import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import * as Unicons from '@iconscout/react-unicons';
import { Button, Label } from '../../components/flexyui';
import { AdministrationEvents, EventCategory, PageEvents, trackEvents, trackPageView } from '../../analytics';
import { useSetDashboardUserPasswordMutation } from '../../graphql';
import useLoginWithMerchantId from '../../hooks/use-login-with-merchant';
import { CredentialsInput } from '../../components/settings/credentials-input';
import { PasswordSchema } from '../../utils/validation-schemas/authentication';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { GettingStartedWrapper } from '../../components/layout/getting-started-wrapper';

export const SetPassword = () => {
  const [setPassword] = useSetDashboardUserPasswordMutation();
  const refetchToken = useLoginWithMerchantId(false);

  const merchantId = useSelector((state: RootState) => state.main.store);

  const handleSave = async (values: any) => {
    try {
      await setPassword({
        variables: {
          passwords: values
        }
      });

      trackEvents(EventCategory.ADMINISTRATION, AdministrationEvents.SET_PASSWORD);
      refetchToken.mutate(merchantId);
    } catch (e) {
      console.error(e);
    }
  };

  const initialValues = {
    new_password: '',
    confirm_new_password: ''
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: PasswordSchema,
    onSubmit: handleSave
  });

  useEffect(() => {
    trackPageView(PageEvents.SET_PASSWORD);
  }, []);

  return (
    <GettingStartedWrapper
      leftContent={
        <>
          <div className="text-gray-500 mb-10">Complete the final step to get started</div>
          <div className="h-[400px] xl:h-[500px] overflow-y-auto scrollbar-hide">
            <div className="flex gap-4 mb-8">
              <div className="min-w-8 w-8 h-8 rounded-full flex items-center justify-center bg-green-600">
                <Unicons.UilCheck size={26} className="text-white" />
              </div>
              <div>
                <div className="font-bold text-xl mb-2">Set Password</div>
                <div className="text-gray-400 text-[13px]">
                  Create a secure password to protect your account and enable safe access.
                </div>
              </div>
            </div>
          </div>
        </>
      }
      rightContent={
        <>
          <div className="text-primary font-medium mb-4 px-3">Final Step</div>
          <div className="max-h-[450px] xl:max-h-[500px] overflow-y-scroll px-4 pb-10">
            <div className="font-bold text-2xl mb-1">Secure Your Account</div>
            <div className="text-[#888D9B] mb-8">
              Create a strong password using letters, numbers, and special characters to keep your account secure.
            </div>

            <Label>New Password</Label>
            <div className="mb-4">
              <CredentialsInput
                {...formik.getFieldProps('new_password')}
                error={formik.touched.new_password && !!formik.errors?.new_password}
                errorMessage={formik.errors?.new_password}
              />
            </div>
            <Label>Confirm Password</Label>
            <div className="mb-4">
              <CredentialsInput
                {...formik.getFieldProps('confirm_new_password')}
                error={formik.touched.confirm_new_password && !!formik.errors?.confirm_new_password}
                errorMessage={formik.errors?.confirm_new_password}
              />
            </div>
          </div>
          <div className="w-full absolute bottom-6 flex items-center justify-end px-4">
            <Button
              variant="default"
              className="w-20 h-9"
              onClick={async () => await formik.submitForm()}
              disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting}
            >
              {formik.isSubmitting ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : 'Save'}
            </Button>
          </div>
        </>
      }
    />
  );
};
