import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { Button, Card } from '../../flexyui';
import { enqueueSnackbar } from 'notistack';
import { CredentialsInput } from '../credentials-input/index';
import { changePasswordFields } from '../../../constants/settings';
import { changePassword } from '../../../network/authentication/authentication';
import { ChangePasswordEvent, EventCategory, trackEvents } from '../../../analytics';

export const SettingPasswordDetails: React.FC = () => {
  const email = useSelector((state: RootState) => state.main.userEmail);

  const [inputCredentials, setInputCredentials] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const handleInput = (e: any) => {
    setInputCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handlePasswordChange();
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handlePasswordChange = async () => {
    const body = {
      email: email || '',
      old_password: inputCredentials.oldPassword,
      new_password: inputCredentials.newPassword,
      confirm_new_password: inputCredentials.confirmNewPassword
    };
    try {
      const response = await changePassword(body);
      if (response.status === 200) {
        enqueueSnackbar('Password changed successfully! Logging you Out..', {
          variant: 'success'
        });
        trackEvents(EventCategory.CHANGE_PASSWORD, ChangePasswordEvent.PASSWORD_CHANGED);
        setTimeout(handleLogout, 2000);
      } else {
        enqueueSnackbar('Oops! Something went wrong. Please check entered values.', {
          variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please check entered values.', {
        variant: 'error'
      });
    }
  };

  return (
    <Card className="h-fit p-4 mb-4 shadow-[0px_4px_20px_rgba(0,0,0,0.05)]">
      <div className="font-medium mb-4">Change Password</div>
      <form onSubmit={handlePasswordChange}>
        <div>
          <div className="my-4">
            {changePasswordFields.OLDPASSWORD.value}
            <CredentialsInput
              value={inputCredentials.oldPassword}
              name={changePasswordFields.OLDPASSWORD.primaryKey}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="my-4">
            {changePasswordFields.NEWPASSWORD.value}
            <CredentialsInput
              value={inputCredentials.newPassword}
              name={changePasswordFields.NEWPASSWORD.primaryKey}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="my-4">
            {changePasswordFields.CONFIRMNEWPASSWORD.value}
            <CredentialsInput
              value={inputCredentials.confirmNewPassword}
              name={changePasswordFields.CONFIRMNEWPASSWORD.primaryKey}
              onChange={handleInput}
              onKeyDown={handleKeyDown}
            />
          </div>
          <div className="mt-6 flex justify-end">
            <Button
              size={'md'}
              type="submit"
              disabled={
                inputCredentials.oldPassword === '' ||
                inputCredentials.newPassword === '' ||
                inputCredentials.confirmNewPassword !== inputCredentials.newPassword
              }
              onClick={handlePasswordChange}
            >
              {changePasswordFields.CHANGEPASSWORD.value}
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
};
