import React, { useState } from 'react';
import { Button, Card, Input, Label } from '../../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';

type Props = {
  defaultTaxDetails: any;
};

export const DefaultTaxes: React.FC<Props> = ({ defaultTaxDetails }) => {
  const limit = 8;
  const [page, setPage] = useState<number>(0);

  const provinces =
    defaultTaxDetails?.provinces?.length > 0
      ? [
          {
            province_id: Math.random(),
            rate: defaultTaxDetails.tax,
            state_code: 'DEFAULT',
            state: 'All Regions'
          },
          ...defaultTaxDetails.provinces
        ]
      : [];
  const totalPages = Math.ceil(provinces.length / limit);

  const handleNext = () => {
    if (page < totalPages - 1) setPage(page + 1);
  };

  const handleBack = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const startIndex = page * limit;
  const endIndex = startIndex + limit;
  const itemsToDisplay = provinces.slice(startIndex, endIndex);

  return (
    <>
      {defaultTaxDetails && (
        <>
          <Label size="md" className="text-base text-[#2A324C]">
            Regional Settings
          </Label>
          <Card className="p-0 bg-[#fbfbfb] mt-4 mb-8 h-fit border-0 shadow-none sm:shadow-[0px_4px_20px_rgba(0,0,0,0.05)] sm:bg-card sm:p-4 sm:border">
            <div className="flex items-center justify-between mb-4">
              <div>
                <div className="font-medium">Base Taxes</div>
                <div className="text-[#888D9B] text-xs">This is synchronized with Shopify and cannot be edited</div>
              </div>

              {/* <Button variant="primary" size="sm">*/}
              {/*  <Unicons.UilSync size={18} className="mr-2" /> Sync*/}
              {/* </Button>*/}
            </div>

            <div className="border border-muted mt-4 rounded-[6px] overflow-hidden">
              <div className="px-4 py-3 bg-[#f7f7f7] border-b">
                <div className="text-[#2F72FF] font-medium py-2">Regions</div>
              </div>

              {itemsToDisplay?.map((province: any, index: number) => (
                <div
                  className={`px-4 py-3 bg-white hover:bg-slate-50 transition-colors ease-linear ${index !== itemsToDisplay?.length - 1 ? 'border-b' : ''}`}
                  key={province?.province_id}
                >
                  <div className="flex flex-col items-start justify-between md:flex-row md:items-center">
                    <div className="w-full flex items-center justify-between md:w-auto md:justify-start">
                      <div className="w-[220px] md:w-[170px] lg:w-[275px] overflow-hidden overflow-ellipsis whitespace-nowrap font-medium sm:font-normal">
                        {province?.state === 'All Regions' ? 'India' : province?.state}
                      </div>
                      <div className="hidden md:block mx-5">
                        <Input
                          value={(province.rate * 100).toFixed(3).replace(/\.?0+$/, '')}
                          type="number"
                          disabled={true}
                          iconPosition="end"
                          className="h-8 w-24 !bg-white"
                          icon={<Unicons.UilPercentage size={18} />}
                        />
                      </div>
                      <div className="">{province?.tax_name}</div>
                    </div>
                    <div className="w-24 mt-3 block md:hidden">
                      <Input
                        value={(province.rate * 100).toFixed(3).replace(/\.?0+$/, '')}
                        type="number"
                        disabled={true}
                        iconPosition="end"
                        className="h-8 !bg-white"
                        icon={<Unicons.UilPercentage size={18} />}
                      />
                    </div>
                    <div className="mt-1 ml-0.5 md:m-0">{province?.tax_value}</div>
                  </div>
                </div>
              ))}
            </div>

            <div className="w-full flex justify-end items-center gap-3 pt-4">
              <Button variant="outline" className="h-8 w-8 p-0 lg:flex" onClick={handleBack} disabled={page === 0}>
                <span className="sr-only">previous page</span>
                <DoubleArrowLeftIcon className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0 lg:flex"
                onClick={handleNext}
                disabled={page === totalPages - 1}
              >
                <span className="sr-only">next page</span>
                <DoubleArrowRightIcon className="h-4 w-4" />
              </Button>
            </div>
          </Card>
        </>
      )}
    </>
  );
};
