export const convertAmount = (amount: number) => {
  const roundedNumber = Math.round(amount * 100) / 100; // Round the number to 2 decimal places
  const parts = roundedNumber.toLocaleString('en-IN', { minimumFractionDigits: 2 }).split('.');
  const integerPart = parts[0];
  let decimalPart = parts[1] || '';

  if (decimalPart === '00') {
    decimalPart = '';
  }

  // Combine the formatted parts
  const formattedNumber = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;
  return Number.isNaN(formattedNumber) ? '0' : formattedNumber.toString();
};

export const calculateIncrement = (current: number, previous: number) => {
  return ((current - previous) / (previous === 0 ? 1 : previous)) * 100;
};
