import React from 'react';
import { OnboardingSteps } from '../../constants/onboarding';

type Props = {
  activeStep: number;
};

export const StepContent: React.FC<Props> = ({ activeStep }) => {
  const currentStep = OnboardingSteps.find((step) => step.step === activeStep);
  return (
    <div>
      <div className="font-bold text-2xl mb-1">
        {currentStep?.title}{' '}
        {currentStep?.isOptional && <span className="text-gray-400 text-sm font-normal">(optional)</span>}
      </div>
      <div className="text-gray-400 mb-8">{currentStep?.description}</div>
    </div>
  );
};
