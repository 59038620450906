import classNames from 'classnames';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { enqueueSnackbar } from 'notistack';
import { Button } from '../../components/flexyui';
import * as Unicons from '@iconscout/react-unicons';
import React, { useEffect, useRef, useState } from 'react';
import { AdministrationEvents, EventCategory, PageEvents, trackEvents, trackPageView } from '../../analytics';
import { OnboardingSteps } from '../../constants/onboarding';
import { PaymentSetup } from '../../components/onboarding/payment';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BusinessType } from '../../components/onboarding/business-type';
import useLoginWithMerchantId from '../../hooks/use-login-with-merchant';
import { BusinessTeam } from '../../components/onboarding/business-team';
import { BusinessDetails } from '../../components/onboarding/business-details';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';
import { GettingStartedWrapper } from '../../components/layout/getting-started-wrapper';
import { OnBoardingState, useGetOnBoardingStateQuery, useUpdateMerchantOnBoardingStateMutation } from '../../graphql';

export type StepCallback = () => Promise<void>;
const steps = [BusinessType, BusinessDetails, BusinessTeam, PaymentSetup];

export const Onboarding = () => {
  const refetchToken = useLoginWithMerchantId(false);
  const { data, loading: stateLoading } = useGetOnBoardingStateQuery();
  const [updateOnBoardingState] = useUpdateMerchantOnBoardingStateMutation();

  const merchantId = useSelector((state: RootState) => state.main.store);

  const stepsMap = new Map<number, OnBoardingState[]>([
    [-1, [OnBoardingState.Incomplete]],
    [0, [OnBoardingState.BusinessType]],
    [1, [OnBoardingState.BusinessDetails]],
    [2, [OnBoardingState.PocUserPermission]],
    [3, [OnBoardingState.Completed]]
  ]);

  const getStepsFromState = (state: OnBoardingState | undefined): number => {
    for (const [step, states] of stepsMap) {
      if (states.includes(state!)) return step;
    }
    return -1;
  };

  const getStateFromStep = (step: number): OnBoardingState[] | undefined => {
    return stepsMap.get(step);
  };

  const activeStepRef = useRef<HTMLDivElement>(null);
  const stepCallbacks = useRef<Record<number, StepCallback>>({});

  const [prevStep, setPrevStep] = useState<number>(getStepsFromState(data?.getMerchantOnBoardingState));
  const [activeStep, setActiveStep] = useState<number>(getStepsFromState(data?.getMerchantOnBoardingState) + 1);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<Record<number, boolean>>(
    Array(OnboardingSteps.length)
      .fill(false)
      .reduce(
        (acc, _, index) => {
          acc[index] = false;
          return acc;
        },
        {} as Record<number, boolean>
      )
  );

  const currentStep = OnboardingSteps.find((step) => step.step === activeStep);

  const handleUpdateOnBoardingState = async (step: number) => {
    const state = getStateFromStep(step)?.[0] as OnBoardingState;
    try {
      await updateOnBoardingState({
        variables: { state }
      });

      if (state === OnBoardingState.Completed) {
        trackEvents(EventCategory.ADMINISTRATION, AdministrationEvents.ONBOARDING_COMPLETED);
        refetchToken.mutate(merchantId);
      }
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  };

  const handleNext = async () => {
    const executeStepLogic = stepCallbacks.current[activeStep];

    setLoading(true);
    try {
      if (executeStepLogic) await executeStepLogic();
      await handleUpdateOnBoardingState(activeStep);

      if (activeStep < OnboardingSteps.length - 1) {
        setPrevStep(activeStep);
        setActiveStep((prev) => prev + 1);
      }
    } catch (error) {
      console.error('Error executing step logic:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    await handleUpdateOnBoardingState(activeStep);

    if (activeStep < OnboardingSteps.length - 1) {
      setPrevStep(activeStep);
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setPrevStep(activeStep);
      setActiveStep(activeStep - 1);
    }
  };

  const setStepDisabled = (step: number, isDisabled: boolean) => {
    setDisabled((prev) => ({
      ...prev,
      [step]: isDisabled
    }));
  };

  useEffect(() => {
    if (activeStepRef.current) {
      activeStepRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [activeStep]);

  useEffect(() => {
    if (data) {
      if (data.getMerchantOnBoardingState === OnBoardingState.Completed) {
        refetchToken.mutate(merchantId);
        return;
      }

      setPrevStep(getStepsFromState(data.getMerchantOnBoardingState));
      setActiveStep(getStepsFromState(data.getMerchantOnBoardingState) + 1);
    }
  }, [data]);

  useEffect(() => {
    trackPageView(PageEvents.ONBOARDING);
  }, []);

  return (
    <>
      {stateLoading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <div className="loader">
            <img
              className="logo"
              src="https://static.flexype.in/assets/flexype-transparent.svg"
              alt="flexype logo"
              loading="eager"
            />
            <div className="spin">
              <div className="circle"></div>
              <div className="dot"></div>
            </div>
          </div>
        </div>
      ) : (
        <GettingStartedWrapper
          leftContent={
            <>
              <div className="mb-10">
                <div className="text-gray-500 mb-10">Complete following steps to setup your store</div>
                <div className="max-h-[400px] xl:max-h-[600px] overflow-y-auto scrollbar-hide">
                  {OnboardingSteps.map((step) => (
                    <div
                      key={step.title}
                      className="flex gap-4 mb-8"
                      ref={step.step === activeStep ? activeStepRef : null}
                    >
                      <div
                        className={classNames('min-w-8 w-8 h-8 rounded-full flex items-center justify-center', {
                          'bg-green-600': step.step < activeStep,
                          'bg-primary': step.step === activeStep,
                          'bg-gray-300': step.step > activeStep
                        })}
                      >
                        {step.step < activeStep && <Unicons.UilCheck size={26} className="text-white" />}
                        {step.step === activeStep && <div className="min-w-4 w-4 h-4 rounded-full bg-white" />}
                        {step.step > activeStep && step.icon}
                      </div>
                      <div>
                        <div className="font-bold text-xl mb-2">
                          {step.title} {/* {step.isOptional && (*/}
                          {/*  <span className="text-gray-400 text-[13px] font-normal">(optional)</span>*/}
                          {/* )}*/}
                        </div>
                        <div className="text-gray-400 text-[13px]">{step.description}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          }
          rightContent={
            <>
              <div className="text-primary font-medium mb-4 px-3">Step {activeStep + 1} of 4</div>
              <div className="max-h-[450px] xl:max-h-[500px] overflow-y-scroll px-4 pb-10">
                <TransitionGroup>
                  <CSSTransition
                    key={activeStep}
                    timeout={300}
                    classNames={activeStep > prevStep ? 'slide-up' : 'slide-down'}
                  >
                    <div>
                      {steps.map(
                        (StepComponent, index) =>
                          activeStep === index && (
                            <StepComponent
                              key={index}
                              activeStep={activeStep}
                              setStepCallback={(callback) => (stepCallbacks.current[index] = callback)}
                              setDisabled={(disabled) => setStepDisabled(index, disabled)}
                            />
                          )
                      )}
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
              <div className="absolute bottom-16 left-0 right-2 h-12 bg-gradient-to-t from-white via-white/100 to-transparent blur-lg pointer-events-none"></div>
              <div className="w-full absolute bottom-6 flex items-center justify-between px-4">
                {activeStep !== 0 ? (
                  <Button variant="ghost" className="w-20 h-9" onClick={handleBack}>
                    <Unicons.UilAngleLeft size={24} className="-ml-2" />
                    Back
                  </Button>
                ) : (
                  <span />
                )}
                <div className="flex items-center gap-2">
                  {currentStep?.isOptional && (
                    <Button variant="outline" className="w-fit h-9" onClick={handleSkip}>
                      Skip
                    </Button>
                  )}
                  <Button variant="default" className="w-20 h-9" onClick={handleNext} disabled={disabled[activeStep]}>
                    {loading ? (
                      <LoadingIcon height={20} className={'animate-spin text-white'} />
                    ) : (
                      <>
                        Next <Unicons.UilAngleRight size={24} className="-mr-2.5" />
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </>
          }
        />
      )}
    </>
  );
};
