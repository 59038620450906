import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { AppDispatch } from '../../../store';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import { setConfirmationDialog, setRouteLink, setSidebarState } from '../../../store/main-slice';

type Props = {
  routeLink: any;
  text: string;
  icon?: any;
};

//

export const SidebarLink: React.FC<Props> = ({ routeLink, text, icon }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { showActions } = useSaveCancelButtons();
  return (
    <NavLink
      data-testid={`sidebar-${routeLink}`}
      to={`${routeLink}`}
      onClick={(e) => {
        dispatch(setRouteLink(`${routeLink}`));
        if (showActions) {
          e.preventDefault();
          dispatch(setConfirmationDialog(true));
          history.pushState(null, document.title, routeLink);
        }
        dispatch(setSidebarState(false));
      }}
    >
      {({ isActive }) => (
        <div
          className={classNames('p-2 mx-4 flex items-center gap-3 rounded-md my-1 transition-all ease-linear', {
            'bg-[#2F72FF] text-white font-semibold': isActive,
            'text-[rgba(18,27,56,0.5)] font-medium hover:bg-slate-100': !isActive
          })}
        >
          <div className="h-4">{icon}</div>
          {text}
        </div>
      )}
    </NavLink>
  );
};
