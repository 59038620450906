import React from 'react';
import { Skeleton, Card } from '../../flexyui';
import * as Unicons from '@iconscout/react-unicons';
import { ButtonWithInfo } from '../../flexyui/ButtonWithInfo';
import { convertAmount } from '../../../utils/convert-amount';
import { DownloadReport } from '../../shared/download-report';
import { SalesChannelIcons, SalesChannelKey, salesChannelsItems } from '../../../constants/dashboard';
import { RequestType, SalesChannelMetricsSource, useSalesChannelMetricsQuery } from '../../../graphql';

type Props = {
  dateRange?: any;
};

export const SalesChannels: React.FC<Props> = ({ dateRange }) => {
  const { data, loading } = useSalesChannelMetricsQuery({
    variables: {
      dateRange: {
        start: new Date(dateRange.startDate).toISOString(),
        end: new Date(`${dateRange.endDate}T23:59:59.999Z`).toISOString()
      }
    }
  });

  const getIcon = (source?: string) => {
    const key = source as SalesChannelKey;
    if (key in SalesChannelIcons) {
      return SalesChannelIcons[key];
    }
    // else {
    //   return <Unicons.UilSearch size={18} />; // Default fallback icon
    // }
  };

  return (
    <Card className="relative p-4 col-span-1 sm:col-span-2 overflow-x-scroll sm:overflow-hidden scrollbar-hide">
      <div className="flex items-center justify-between sticky left-0 sm:static">
        <div className="font-medium text-black flex items-center">
          <div>Sales Channels</div>
          <ButtonWithInfo
            heading="Sales Channels"
            description="Distribution of sales from different social media channels."
            calculation={false}
          >
            <Unicons.UilInfoCircle size={15} className="ml-1.5" />
          </ButtonWithInfo>
        </div>
        {!loading && <DownloadReport dateRange={dateRange} requestType={RequestType.OrderUtm} />}
      </div>
      <div className="min-w-[500px]">
        <div className="grid gap-2 grid-cols-5 mt-6 mb-2 font-medium text-gray-400 text-center">
          <div className="min-w-[100px]"></div>
          <div className="min-w-[100px]">Sessions</div>
          <div className="min-w-[100px]">Orders</div>
          <div className="min-w-[100px]">Total Sales</div>
          <div className="min-w-[100px]">Conversions</div>
        </div>

        {salesChannelsItems?.map((each) => {
          const current = data?.salesChannelMetrics?.[each as keyof SalesChannelMetricsSource];

          return (
            <div key={each} className="grid gap-2 grid-cols-5 font-medium py-2.5 text-center border-b last:border-0">
              <div className="flex items-center min-w-[120px]">
                {each !== 'other' ? <img alt={each} src={getIcon(each)} /> : <Unicons.UilLayerGroup size={18} />}
                <div className="ml-2 font-medium">{each?.charAt(0)?.toUpperCase() + each?.slice(1)}</div>
              </div>
              {loading ? (
                [0, 1, 2, 3].map((index) => (
                  <div key={`${each}_${index}`} className="flex justify-center">
                    <Skeleton size="label" className="w-10 sm:w-14 h-4 !mr-0" />
                  </div>
                ))
              ) : (
                <>
                  <div className="flex justify-center min-w-[100px]">{current?.total_sessions ?? '-'}</div>
                  <div className="flex justify-center min-w-[100px]">{current?.total_orders ?? '-'}</div>
                  <div className="flex justify-center min-w-[100px]">
                    {current?.total_sales === 0
                      ? '₹0'
                      : current?.total_sales
                        ? `₹${convertAmount(Number(current?.total_sales))}`
                        : '-'}
                  </div>
                  <div className="flex justify-center min-w-[100px]">
                    {current?.conversion_rate ? `${current.conversion_rate}%` : '-'}
                  </div>
                </>
              )}
            </div>
          );
        })}
        {!data?.salesChannelMetrics && !loading && (
          <div className="text-center my-20">
            <div className="text-gray-300 text-base">Not Available</div>
          </div>
        )}
      </div>
    </Card>
  );
};
