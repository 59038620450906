import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Input,
  Label,
  RadioGroup,
  Select as SelectCN,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../flexyui';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { indianStates } from '../../../../constants/settings';
import { RadioWithLabel } from '../../../shared/radio-with-label/radio-with-label';
import { EventCategory, StoreDetailsEvent, trackEvents } from '../../../../analytics';
import { BusinessCategory, RegistrationType } from '../../../../constants/onboarding';
import { ReactComponent as LoadingIcon } from '../../../../assets/images/loading.svg';
import { businessDetailsSchema } from '../../../../utils/validation-schemas/onboarding';
import { DashboardUserType, useGetBusinessDetailQuery, useUpdateBusinessDetailMutation } from '../../../../graphql';

export const BusinessDetails = () => {
  const userType = useSelector((state: RootState) => state.main.userType);

  const [useGst, setUseGst] = useState<'YES' | 'NO'>('YES');
  const [open, setOpen] = useState<boolean>(false);

  const { data, refetch } = useGetBusinessDetailQuery();
  const [updateBusinessDetails] = useUpdateBusinessDetailMutation();

  const businessDetail = data && data.getBusinessDetail;

  const handleSave = async (values: any) => {
    try {
      await updateBusinessDetails({
        variables: {
          detail: values
        }
      });

      enqueueSnackbar('Business details have been updated successfully.', {
        variant: 'success'
      });

      await refetch();
      trackEvents(EventCategory.STORE_DETAILS, StoreDetailsEvent.EDIT_BUSINESS_DETAILS);
    } catch {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
    }
  };

  const initialValues = useMemo(() => {
    return {
      category: data?.getBusinessDetail?.category || '',
      registration_type: data?.getBusinessDetail?.registration_type || '',
      legal_name: data?.getBusinessDetail?.legal_name || '',
      trade_name: data?.getBusinessDetail?.trade_name || '',
      GSTIN: data?.getBusinessDetail?.GSTIN || '',
      company_name_on_gst: data?.getBusinessDetail?.company_name_on_gst || '',
      address: {
        address1: data?.getBusinessDetail?.address?.address1 || '',
        address2: data?.getBusinessDetail?.address?.address2 || '',
        city: data?.getBusinessDetail?.address?.city || '',
        state: data?.getBusinessDetail?.address?.state || '',
        country: 'India',
        postal_code: data?.getBusinessDetail?.address?.postal_code || ''
      }
    };
  }, [data?.getBusinessDetail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: businessDetailsSchema(useGst === 'YES'),
    onSubmit: handleSave
  });

  useEffect(() => {
    formik.validateForm();
  }, [useGst]);

  if (!data?.getBusinessDetail) return <></>;

  return (
    <>
      <Card className="p-0 bg-[#fbfbfb] mb-4 h-fit border-0 shadow-none sm:shadow-[0px_4px_20px_rgba(0,0,0,0.05)] sm:bg-card sm:p-4 sm:border">
        <div className="flex items-center justify-between mb-4">
          <div>
            <div className="font-medium">Business Details</div>
            <div className="text-[#888D9B] text-xs">Comprehensive information about the business.</div>
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setOpen(true);
              trackEvents(EventCategory.STORE_DETAILS, StoreDetailsEvent.EDIT_BUSINESS_DETAILS_CLICKED);
            }}
            disabled={userType === DashboardUserType.Staff}
          >
            <Unicons.UilEdit size={18} className="mr-2" />
            Edit
          </Button>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilAnalysis size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">Trade Name</div>
              <div className="text-[#121B38]">{businessDetail?.trade_name ? businessDetail.trade_name : '-'}</div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilFileContract size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">Legal Name</div>
              <div className="text-[#121B38]">{businessDetail?.legal_name ? businessDetail.legal_name : '-'}</div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilArrow size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">Category</div>
              <div className="text-[#121B38]">{businessDetail?.category ? businessDetail.category : '-'}</div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilArrow size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">Registration Type</div>
              <div className="text-[#121B38]">
                {businessDetail?.registration_type ? businessDetail.registration_type : '-'}
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilUniversity size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">Company Name on GST</div>
              <div className="text-[#121B38]">
                {businessDetail?.company_name_on_gst ? businessDetail.company_name_on_gst : '-'}
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilInvoice size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">GSTIN</div>
              <div className="text-[#121B38]">{businessDetail?.GSTIN ? businessDetail.GSTIN : '-'}</div>
            </div>
          </div>
          <div className="flex items-start gap-2">
            <div className="bg-[rgb(229,229,229)] p-2 rounded-[1rem]">
              <Unicons.UilEstate size={14} />
            </div>
            <div>
              <div className="text-[#595F74] text-xs">Address</div>
              <div className="text-[#121B38] flex flex-col">
                <div>
                  {businessDetail?.address?.address1}
                  <span>{businessDetail?.address?.address2 ? `, ${businessDetail?.address?.address2}` : ''}</span>
                </div>
                <div className="flex">
                  {businessDetail?.address?.city},&nbsp;
                  {businessDetail?.address?.state} -&nbsp;
                  {businessDetail?.address?.postal_code}
                </div>
                <div>{businessDetail?.address?.country}</div>
              </div>
            </div>
          </div>
        </div>
      </Card>

      <Dialog open={open} onOpenChange={(value) => setOpen(value)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Business contact</p>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0">
                <div className="p-4 h-[60vh] overflow-y-auto scroll-smooth">
                  <Label className="required-field">Category</Label>
                  <SelectCN
                    value={formik.values.category}
                    onValueChange={(value) => {
                      formik.setFieldValue('category', value);
                    }}
                  >
                    <SelectTrigger className="h-10 mt-1 mb-4 w-full text-black">
                      <SelectValue placeholder="None" />
                    </SelectTrigger>
                    <SelectContent side="bottom" longList={true}>
                      {BusinessCategory.map((category) => (
                        <SelectItem key={category} value={category}>
                          {category}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectCN>

                  <Label className="required-field">Registration type</Label>
                  <SelectCN
                    value={formik.values.registration_type}
                    onValueChange={(value) => {
                      formik.setFieldValue('registration_type', value);
                    }}
                  >
                    <SelectTrigger className="h-10 mt-1 mb-4 w-full text-black">
                      <SelectValue placeholder="None" />
                    </SelectTrigger>
                    <SelectContent side="bottom" longList={true}>
                      {RegistrationType.map((category) => (
                        <SelectItem key={category} value={category}>
                          {category}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectCN>
                  <Label className="required-field">Legal Name</Label>
                  <Input
                    {...formik.getFieldProps('legal_name')}
                    error={formik.touched.legal_name && !!formik.errors.legal_name}
                    className="text-[#121b38] mt-1 mb-4"
                  />
                  <Label className="required-field">Trade Name</Label>
                  <Input
                    {...formik.getFieldProps('trade_name')}
                    error={formik.touched.trade_name && !!formik.errors.trade_name}
                    className="text-[#121b38] mt-1 mb-4"
                  />
                  <Label className="required-field">Address line 1</Label>
                  <Input
                    {...formik.getFieldProps('address.address1')}
                    error={formik.touched.address?.address1 && !!formik.errors.address?.address1}
                    className="text-[#121b38] mt-1 mb-4"
                  />
                  <Label>Address line 2</Label>
                  <Input
                    {...formik.getFieldProps('address.address2')}
                    error={formik.touched.address?.address2 && !!formik.errors.address?.address2}
                    className="text-[#121b38] mt-1 mb-4"
                  />
                  <Label className="required-field">Pincode</Label>
                  <Input
                    {...formik.getFieldProps('address.postal_code')}
                    type="number"
                    error={formik.touched.address?.postal_code && !!formik.errors.address?.postal_code}
                    className="text-[#121b38] mt-1 mb-4"
                  />

                  <div className="grid grid-cols-2 gap-2 mb-6">
                    <div>
                      <Label className="required-field">City</Label>
                      <Input
                        {...formik.getFieldProps('address.city')}
                        error={formik.touched.address?.city && !!formik.errors.address?.city}
                        className="text-[#121b38] mt-1"
                      />
                    </div>
                    <div>
                      <Label className="required-field">State</Label>
                      <SelectCN
                        value={formik.values.address?.state}
                        onValueChange={(value) => {
                          formik.setFieldValue('address.state', value);
                        }}
                      >
                        <SelectTrigger className="h-10 mt-1 w-full text-black">
                          <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
                            <SelectValue placeholder="None" />
                          </div>
                        </SelectTrigger>
                        <SelectContent side="bottom" longList={true}>
                          {indianStates
                            .filter((states) => states.state_code !== 'DEFAULT')
                            .map((state) => (
                              <SelectItem key={state.state_code} value={state.state}>
                                {state.state}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </SelectCN>
                    </div>
                  </div>
                  <RadioGroup value={useGst} className="flex items-center gap-6">
                    <RadioWithLabel label="Yes, I have GST" value="YES" onClick={() => setUseGst('YES')} />
                    <RadioWithLabel label="No, I don’t have GST" value="NO" onClick={() => setUseGst('NO')} />
                  </RadioGroup>

                  {useGst === 'YES' && (
                    <div className="mt-4">
                      <Label className="required-field">GSTIN</Label>
                      <Input
                        {...formik.getFieldProps('GSTIN')}
                        error={formik.touched.GSTIN && !!formik.errors.GSTIN}
                        className="text-[#121b38] mt-1 mb-4"
                      />
                      <Label className="required-field">Company name as on GST</Label>
                      <Input
                        {...formik.getFieldProps('company_name_on_gst')}
                        error={formik.touched.company_name_on_gst && !!formik.errors.company_name_on_gst}
                        className="text-[#121b38] mt-1 mb-2"
                      />
                    </div>
                  )}
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button variant="outline" size="md" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="default"
              size="md"
              disabled={Object.keys(formik.errors).length > 0 || formik.isSubmitting}
              onClick={async () => await formik.submitForm()}
            >
              {formik.isSubmitting ? <LoadingIcon height={20} className={'animate-spin text-white'} /> : 'Save'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
