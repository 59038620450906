import { useFormikContext } from 'formik';
import { Button, Input } from '../../../flexyui';
import React, { useEffect, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { InfoCardSetup } from '../../../../constants/coupons';
import { DiscountFields } from '../../../../types/coupon-data-types';
import SelectedProducts from '../../selected-products/selected-products';
import { DiscountInfoCard } from '../discount-info-card/discount-info-card';
import { ProductSelectionDialog } from '../../product-selection/product-selection';

export const DiscountFreeItems = () => {
  const { values, errors, touched, getFieldProps, setFieldValue } = useFormikContext<DiscountFields>();

  const [selectionSearch, setSelectionSearch] = useState('');
  const [openProductSelection, setOpenProductSelection] = useState<boolean>(false);

  useEffect(() => {
    if (selectionSearch.length > 0) setOpenProductSelection(true);
    else setOpenProductSelection(false);
  }, [selectionSearch]);

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 sm:pl-8">
        <DiscountInfoCard data={InfoCardSetup[3]} />
        <div>
          <div className="font-semibold text-base mb-4 block sm:hidden">Products to be added</div>
          <div>
            <div className="font-medium mb-1">Free products</div>
            <div className="flex gap-2">
              <div className="flex-1">
                <Input
                  type="text"
                  placeholder="Search"
                  iconPosition="start"
                  icon={<Unicons.UilSearch size={18} />}
                  value={selectionSearch}
                  error={touched.add_on?.products && !!errors.add_on?.products}
                  errorMessage={errors.add_on?.products as string}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setSelectionSearch(e.target.value);
                  }}
                />
              </div>
              <div>
                <Button
                  variant="outline"
                  className="h-10 hover:border-[#2F72FF] hover:bg-[#f9fcff] border-[1px] rounded-lg font-medium w-fit"
                  onClick={() => setOpenProductSelection(true)}
                >
                  Browse
                </Button>
                <ProductSelectionDialog
                  key={'ProductSelectionDialogBuys'}
                  open={openProductSelection}
                  setOpen={(val: boolean) => {
                    setOpenProductSelection(val);
                    setSelectionSearch('');
                  }}
                  search={selectionSearch}
                  preSelections={values.add_on.products}
                  onSave={(value: any) => {
                    setFieldValue('add_on.products', value);
                  }}
                />
              </div>
            </div>
            <div className="mt-2">
              <SelectedProducts on="add_on.products" setFieldValue={setFieldValue} value={values.add_on.products} />
            </div>
          </div>
          <div className="w-[150px] mt-4">
            <div className="font-medium mb-1">Free quantity</div>
            <Input
              {...getFieldProps('add_on.max_quantity')}
              type="number"
              error={touched.add_on?.max_quantity && !!errors.add_on?.max_quantity}
              errorMessage={errors.add_on?.max_quantity}
            />
          </div>
        </div>
      </div>
    </>
  );
};
