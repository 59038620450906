import { ReactComponent as PMUpiIcon } from '../../assets/images/pm-upi-bold.svg';
import { ReactComponent as PMCardIcon } from '../../assets/images/pm-card-bold.svg';
import { ReactComponent as PMEmiIcon } from '../../assets/images/pm-emi-bold.svg';
import { ReactComponent as PMWalletIcon } from '../../assets/images/pm-wallet-bold.svg';
import { ReactComponent as PMCodIcon } from '../../assets/images/pm-cod-bold.svg';
import { ReactComponent as PMPayLaterIcon } from '../../assets/images/pm-pay-later-bold.svg';
import { ReactComponent as PMNetBankingIcon } from '../../assets/images/pm-net-banking-bold.svg';
import { ReactComponent as PMRedirectIcon } from '../../assets/images/pm-redirect-bold.svg';
import { ReactComponent as PMSnapmintIcon } from '../../assets/images/pm-snapmint-bold.svg';
import { CouponDisplay, LayoutComponent } from '../../graphql';
import FacebookIcon from '../../assets/images/facebook-icon.svg';
import GoogleIcon from '../../assets/images/google-icon.svg';
import InstagramIcon from '../../assets/images/instagram-icon.svg';
import YoutubeIcon from '../../assets/images/youtube-icon.svg';

export const options: any = {
  legend: {
    position: 'top',
    offsetY: 0,
    markers: {
      width: 10,
      height: 10
    },
    horizontalAlign: 'left',
    colors: ['black', '#A0A4AF'],
    style: {
      colors: ['black', '#A0A4AF']
    }
  },
  chart: {
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    },
    toolbar: {
      show: false,
      enabled: true,
      shared: true
    }
  },
  onDatasetHover: {
    highlightDataSeries: false
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2,
    color: '#EEEEEE'
  },
  grid: {
    show: false
  },
  xaxis: {
    labels: {
      show: false
    },
    axisBorder: {
      show: false // Hide the x-axis line
    },
    axisTicks: {
      show: false // Hide the x-axis ticks
    },
    crosshairs: {
      show: false // Hide the crosshair lines
    },
    tooltip: {
      enabled: false
    }
  },
  yaxis: {
    show: false,
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: false
    },
    tooltip: {
      enabled: true
    }
  }
};

export const LayoutData = [
  {
    position: 1,
    layout: LayoutComponent.Banner
  },
  {
    position: 2,
    layout: LayoutComponent.OrderSummary
  },
  {
    position: 3,
    layout: LayoutComponent.Coupons
  }
];
export const PaymentMethodsData = [
  {
    title: 'UPI',
    subtitle: 'UPI/QR Code',
    description: 'Pay with installed apps, or any UPI ID',
    position: 0,
    visibility: true
  },
  {
    title: 'CARD',
    subtitle: 'Debit/Credit cards',
    description: 'Pay with RuPay, Visa, Master Card',
    position: 1,
    visibility: false
  }
];

interface PaymentMethodIconsType {
  [key: string]: any;
  UPI: any;
  CARD: any;
}

export const PaymentMethodIcons: PaymentMethodIconsType = {
  UPI: PMUpiIcon,
  CARD: PMCardIcon,
  ONLINE: 'https://static.flexype.in/assets/checkout/UPI.gif',
  COD: PMCodIcon,
  WALLET: PMWalletIcon,
  PAY_LATER: PMPayLaterIcon,
  NET_BANKING: PMNetBankingIcon,
  EMI: PMEmiIcon,
  REDIRECT_SHOPIFY: PMRedirectIcon,
  SNAPMINT: PMSnapmintIcon
};

export const generalCustomizationLoginData = {
  banner: {
    enabled: true,
    background_color: '#000000',
    text_color: '#FFFFFF',
    banners: [
      {
        text: 'This is sample banner',
        position: 0,
        enabled: true
      },
      {
        text: 'This is sample banner',
        position: 0,
        enabled: true
      },
      {
        text: 'This is sample banner',
        position: 0,
        enabled: true
      }
    ]
  },
  button: {
    button_text: 'Continue'
  },
  coupons: {
    show_coupons: true,
    lock_coupon_access: false,
    display: CouponDisplay.CouponList
  },
  upselling: {
    button_color: '#000000',
    show_upselling: true
  }
};

export function replaceAndCapitalize(inputString: string) {
  const replacedString = inputString.replace(/_/g, ' ');

  const capitalizedString = replacedString.toLowerCase().replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());

  return capitalizedString;
}

export type SalesChannelKey = 'facebook' | 'google' | 'instagram' | 'youtube';

export const SalesChannelIcons = {
  facebook: FacebookIcon,
  google: GoogleIcon,
  instagram: InstagramIcon,
  youtube: YoutubeIcon
};

export const salesChannelsItems = [...(Object.keys(SalesChannelIcons) as SalesChannelKey[]), 'other'];
