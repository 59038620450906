import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../flexyui/Dialog';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Coupon, CouponType, useGetCouponsLazyQuery } from '../../../graphql';
import debounce from 'debounce';
import { Input } from '../../flexyui/Input';
import { Button, Chip, Label, Skeleton } from '../../flexyui';
import classNames from 'classnames';
import { formatCouponType } from '../../../constants/coupons';

type props = {
  open: boolean;
  setOpen: (data: any) => void;
  preSelections: Coupon['code'];
  onSave: (value: Coupon) => void;
};
export const CouponSelection: FC<props> = ({ open, setOpen, preSelections, onSave }) => {
  const limit = 10;

  const [page, setPage] = useState(0);

  const [coupons, setCoupons] = useState<any>([]);
  const [totalCouponCount, setTotalCouponCount] = useState<number>(0);

  const [selectedCoupons, setSelectedCoupons] = useState<Coupon['code']>(preSelections);
  const [selectedFinalCoupon, setSelectedFinalCoupon] = useState<Coupon>();
  const [getData, { data: couponData }] = useGetCouponsLazyQuery({
    variables: {
      code: '',
      couponTypes: [
        CouponType.Bxgy,
        CouponType.Amount,
        CouponType.CartAmount,
        CouponType.ProductAmount,
        CouponType.AddOn
      ],
      skip: 0,
      limit: 10
    }
  });

  const [searchedValue, setSearchedValue] = useState({
    input: '',
    skip: 0,
    limit: 10
  });

  const handleSearch = (searchParams: any, skip: number, limit: number) => {
    getData({
      variables: {
        code: searchParams.input || '',
        skip,
        limit,
        couponTypes: [
          CouponType.Bxgy,
          CouponType.Amount,
          CouponType.CartAmount,
          CouponType.ProductAmount,
          CouponType.AddOn
        ]
      }
    }).then((response) => {
      const { data } = response;
      const newCoupons = data?.getCoupons?.data || [];

      setTotalCouponCount(data?.getCoupons?.page_info?.total_count || 0);

      if (coupons.length > 0) {
        setCoupons((prevState: any) => [...prevState, ...newCoupons]);
      } else {
        setCoupons([...newCoupons]);
      }
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), [coupons]);

  const handleCouponSelection = (couponData: Coupon) => {
    setSelectedCoupons(couponData.code);
    setSelectedFinalCoupon(couponData);
  };

  useEffect(() => {
    setSelectedCoupons(preSelections);
  }, [preSelections]);

  useEffect(() => {
    debouncedSearch(searchedValue, limit * page, limit);
  }, [searchedValue, page]);

  return (
    <>
      <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <DialogContent className="!gap-0" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p> {'Select coupon'}</p>
              </DialogTitle>
              <div className="sticky z-10 bg-white top-0 p-3 !m-0 border-b-[1px]">
                <Input
                  type="text"
                  name="search_Coupons"
                  placeholder="Search coupon"
                  className="!m-0"
                  value={searchedValue.input}
                  onChange={(e: any) => {
                    setPage(0);
                    setCoupons([]);
                    setSearchedValue((prevState) => {
                      return {
                        ...prevState,
                        input: e.target.value
                      };
                    });
                  }}
                />
              </div>
              <DialogDescription className="!p-0 !m-0">
                <div id="scrollableDiv" className="w-full h-[60vh] overflow-y-auto scroll-smooth">
                  <InfiniteScroll
                    dataLength={coupons.length}
                    next={() => {
                      if ((coupons.length || 0) < totalCouponCount) setPage((prevState) => prevState + 1);
                    }}
                    hasMore={coupons.length < totalCouponCount}
                    loader={''}
                    scrollableTarget="scrollableDiv"
                  >
                    {coupons.length > 0 ? (
                      coupons.map((item: Coupon) => (
                        <>
                          <div
                            className={classNames(
                              'w-[96%] min-h-[42px] rounded-md flex items-center border-[1.5px] px-3 py-1.5 cursor-pointer m-auto my-3 hover:bg-slate-100 transition-colors ease-linear',
                              {
                                'border-[1.5px] border-primary': item.code === selectedCoupons
                                // 'pointer-events-none bg-gray-100': !item.active
                              }
                            )}
                            onClick={() => {
                              handleCouponSelection(item);
                            }}
                          >
                            <div className="ml-2 w-full h-full flex justify-between items-center">
                              <div className="flex flex-col">
                                <Label className="cursor-pointer">{item.code}</Label>
                                <Label size={'sm'} className="text-gray-500 cursor-pointer">
                                  {formatCouponType(item.coupon_type)} Discount
                                </Label>
                              </div>
                              {item?.active ? (
                                <Chip text="Active" show variant="success" />
                              ) : (
                                <Chip text="Inactive" show variant="draft" />
                              )}
                            </div>
                          </div>
                        </>
                      ))
                    ) : couponData && couponData.getCoupons.data?.length === 0 ? (
                      <div className="px-6 py-4 text-gray-500">No Results found</div>
                    ) : (
                      [0, 1, 2, 4].map((index) => (
                        <div key={index} className="flex justify-between py-2 px-5">
                          <div>
                            <Skeleton size={'label'} className="w-40 h-5" />
                            <Skeleton size={'md'} className="mt-1.5" />
                          </div>
                          <Skeleton size={'label'} className="w-20" />
                        </div>
                      ))
                    )}
                  </InfiniteScroll>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={async () => {
                setSelectedCoupons(preSelections);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              onClick={async () => {
                onSave(selectedFinalCoupon as Coupon);
                setOpen(false);
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
