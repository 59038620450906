export const parseRules = (values: any, rows: any) => {
  const tempRows: any = [];
  const rowKeys = Object.keys(values);
  rowKeys.forEach((rowKey) => {
    const rowNames = rowKey.split('_');
    const index = rowNames[rowNames.length - 1];
    rowNames.pop();
    tempRows[index] = {
      ...tempRows[index],
      [rowNames.join('_')]: values[rowKey]
    };
  });
  const finalRows: any = [];
  rows.forEach((i: any) => finalRows.push({ ...tempRows[i], _index: i }));
  finalRows.map((row: any) => {
    const rowData = row;

    if (!rowData.minimum_order_price) rowData.minimum_order_price = 0;
    else rowData.minimum_order_price = Number(rowData.minimum_order_price);

    if (rowData.maximum_order_price) rowData.maximum_order_price = Number(rowData.maximum_order_price);

    if (!rowData.delivery_price) rowData.delivery_price = 0;
    rowData.cod_accepted = !!rowData.cod_accepted;

    if (!rowData.cod_charges) rowData.cod_charges = 0;

    return rowData;
  });
  finalRows.sort((a: any, b: any) => a._index - b._index);
  return finalRows;
};

export const validatePriceRangeRule = (rules: any) => {
  rules.sort((a: any, b: any) => a.minimum_order_price - b.minimum_order_price);
  let prevMax = -1;
  for (let i = 0; i < rules.length; i += 1) {
    const order = rules[i];
    if (i === 0 && order.maximum_order_price && order.minimum_order_price >= order.maximum_order_price) {
      return false;
    }

    if (i > 0 && prevMax >= order.minimum_order_price) {
      return false;
    }

    prevMax = order.maximum_order_price || Infinity;
    if (order.minimum_order_price >= prevMax) {
      return false;
    }
  }
  return true;
};
