import React, { useEffect, useState } from 'react';
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '../../components/flexyui';
import Collection from './collection';
import * as Unicons from '@iconscout/react-unicons';
import { ButtonWithInfo } from '../../components/flexyui/ButtonWithInfo';
import { Title } from '../../components/shared/dashboard-title/title';
import { UpsellingProduct } from './upselling-product';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import useSaveCancelButtons from '../../hooks/use-save-cancel';
import { AppDispatch } from '@/src/store';
import { useDispatch } from 'react-redux';
import { setConfirmationDialog, setRouteLink } from '../../../src/store/main-slice';
import { EventCategory, trackEvents, UpsellingEvents } from '../../analytics';

export const Upsell: React.FC = () => {
  const [productModal, setProductModal] = useState<boolean>(false);
  const [collectionModal, setCollectionModal] = useState<boolean>(false);
  const [productSearchId, setProductSearchId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState('products');
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  const { showActions } = useSaveCancelButtons();

  useEffect(() => {
    const path = location.pathname.replace('/upsell/', '');
    const isValidPath = ['products', 'collection'].includes(path);

    if (isValidPath) {
      setActiveTab(path);
    } else {
      setActiveTab('products');
      navigate('/upsell/products', { replace: true });
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    if (productModal) return;
    setProductSearchId(null);
  }, [productModal]);

  useEffect(() => {
    if (productSearchId) {
      setProductModal(true);
    } else {
      setProductModal(false);
    }
  }, [productSearchId]);

  const handleTabChange = (value: string) => {
    dispatch(setRouteLink(`/upsell/${value}`));

    if (showActions) {
      dispatch(setConfirmationDialog(true));
    } else {
      setActiveTab(value);
      navigate(`/upsell/${value}`, { replace: true });
    }
  };

  return (
    <>
      <div className="px-4">
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center">
            <Title title="Upselling" />
            <ButtonWithInfo
              heading="Note"
              description="Draft and out of stock items will be automatically filtered on checkout."
              calculation={false}
            >
              <Unicons.UilInfoCircle size={15} className="ml-1.5" />
            </ButtonWithInfo>
          </div>
          <Button
            size="sm"
            variant="primary"
            onClick={() => {
              if (activeTab === 'products') {
                setProductModal(true);
                trackEvents(EventCategory.UPSELLING, UpsellingEvents.ADD_PRODUCT_CLICKED);
              } else {
                setCollectionModal(true);
                trackEvents(EventCategory.UPSELLING, UpsellingEvents.ADD_COLLECTION_CLICKED);
              }
            }}
          >
            <Unicons.UilPlus size={18} className="mr-2" />
            Add {activeTab === 'products' ? 'Products' : 'Collections'}
          </Button>
        </div>

        <Tabs value={activeTab} className="justify-center w-full mt-0 sm:mt-2" onValueChange={handleTabChange}>
          <TabsList className="max-w-fit justify-between backdrop-blur-3xl">
            <TabsTrigger value="products" className="px-4">
              Products
            </TabsTrigger>
            <TabsTrigger value="collection" className="px-4">
              Collection
            </TabsTrigger>
          </TabsList>
          <div className="mt-3 mb-4">
            <Routes>
              <Route
                path="products"
                element={
                  <TabsContent value="products">
                    <UpsellingProduct
                      productModal={productModal}
                      setProductModal={setProductModal}
                      productSearchId={productSearchId}
                      setProductSearchId={setProductSearchId}
                    />
                  </TabsContent>
                }
              />
              <Route
                path="collection"
                element={
                  <TabsContent value="collection">
                    <Collection collectionModal={collectionModal} setCollectionModal={setCollectionModal} />
                  </TabsContent>
                }
              />
              <Route path="*" element={<Navigate to="products" />} />
            </Routes>
          </div>
        </Tabs>
      </div>
    </>
  );
};
