/* eslint-disable camelcase */
import { authAPI } from '..';
import { AxiosResponse } from 'axios';
import { LoginWithEmail, LoginResponse, ChangePassword } from './types';

export const loginWithEmail = async (credentials: LoginWithEmail): Promise<AxiosResponse<LoginResponse>> => {
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/login`, credentials);
};

export const loginWithMerchantId = async (
  merchant_id: string,
  refresh_token: string
): Promise<AxiosResponse<LoginResponse>> => {
  const body = {
    merchant_id,
    refresh_token
  };
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/dashboard/refreshToken`, body);
};

export const changePassword = async (credentials: ChangePassword): Promise<AxiosResponse<LoginResponse>> => {
  return authAPI.post(`${process.env.REACT_APP_BASE_URL}/v1/public/dashboard/password/change`, credentials);
};
