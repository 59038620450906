import React from 'react';
import { PaymentProviders } from './payment-providers';
import { PaymentMethods } from './payment-methods';
import { usePaymentMethodsQuery, usePaymentProvidersQuery } from '../../../../graphql';

export const SettingsPayment: React.FC = () => {
  const {
    data: paymentProvidersData,
    loading: paymentProvidersLoading,
    refetch: paymentProvidersRefetch
  } = usePaymentProvidersQuery();
  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    refetch: paymentMethodsRefetch
  } = usePaymentMethodsQuery();

  const getActivePaymentProviders = () => {
    const paymentProviders = paymentProvidersData?.paymentProviders;

    if (!paymentProviders) return [];
    return (Object.keys(paymentProviders) as Array<keyof typeof paymentProviders>).filter(
      (provider) => !!paymentProviders[provider].api_key && !!paymentProviders[provider].api_secret
    );
  };

  const getUsedPaymentProviders = () => {
    const paymentMethods = paymentMethodsData?.payment?.paymentMethods;

    if (!paymentMethods) return [];
    return [
      ...new Set(
        Object.values(paymentMethods)
          .filter((method) => method && method.provider)
          .map((method) => String(method?.provider))
      )
    ];
  };

  return (
    <>
      <PaymentProviders
        data={paymentProvidersData}
        loading={paymentProvidersLoading}
        refetch={paymentProvidersRefetch}
        usedProviders={getUsedPaymentProviders()}
      />
      <PaymentMethods
        data={paymentMethodsData}
        loading={paymentMethodsLoading}
        refetch={paymentMethodsRefetch}
        activeProviders={getActivePaymentProviders()}
      />
    </>
  );
};
