/* eslint-disable camelcase */
import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { useAuth } from '../context/auth';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { setPageProgress } from '../store/main-slice';
import { loginWithMerchantId } from '../network/authentication/authentication';
import { StepCallback } from '@/src/pages/onboarding/onboarding';

const useLoginWithMerchantId = (storeSwitch = true) => {
  const navigate = useNavigate();
  const { refreshToken, setAccessToken, setRefreshToken } = useAuth();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const storeName = localStorage.getItem('flexy-new-store');
    if (storeName) {
      enqueueSnackbar(`Successfully switched to ${storeName}`, { variant: 'success' });
      localStorage.removeItem('flexy-new-store');
    }
  }, []);

  const handleTokenStorage = (accessToken: string, refreshToken: string) => {
    if (accessToken) setAccessToken(accessToken);
    if (refreshToken) setRefreshToken(refreshToken);
  };

  return useMutation({
    mutationFn: (merchant_id: string) => loginWithMerchantId(merchant_id, refreshToken || ''),
    onSuccess: (response) => {
      const tokens = response?.data?.data?.token_data;
      const accessToken = tokens?.access_token;
      const refreshToken = tokens?.refresh_token;

      if (storeSwitch) {
        const user: any = jwtDecode(accessToken);
        const storeName = user.payload.merchant_name;

        localStorage.setItem('flexy-new-store', storeName);

        let progress = 30;
        dispatch(setPageProgress(progress));

        const interval = setInterval(() => {
          const increment = (100 - progress) * 0.1;
          progress += increment;

          if (progress >= 99.9) {
            clearInterval(interval);
            dispatch(setPageProgress(100));
            handleTokenStorage(accessToken, refreshToken);
            navigate('');
            window.location.reload();
          } else {
            dispatch(setPageProgress(progress));
          }
        }, 50);
      } else handleTokenStorage(accessToken, refreshToken);
    },
    onError: () => {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    }
  });
};

export default useLoginWithMerchantId;
