import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../flexyui/Dialog/index';
import * as Unicons from '@iconscout/react-unicons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CollectionType, useCollectionsUpsellingLazyQuery } from '../../../graphql';
import debounce from 'debounce';
import { Input } from '../../flexyui/Input';
import { Checkbox } from '../../flexyui/Checkbox';
import { Button, Skeleton, RadioGroup, RadioGroupItem } from '../../flexyui';

interface Collection {
  collection_id: number;
  admin_graphql_api_id: string;
  body_html: string;
  title: string;
  image_src?: string;
  sort_order: string;
  published_scope: string;
  handle: string;
}

type props = {
  open: boolean;
  setOpen: (data: any) => void;
  preSelections: any;
  onSave: (value: any) => void;
  search?: string;
  filterCollectionType?: CollectionType;
  singleSelect?: boolean;
};
export const CollectionSelectionDialog: FC<props> = ({
  open,
  setOpen,
  preSelections,
  onSave,
  search = '',
  filterCollectionType = [CollectionType.Custom, CollectionType.Smart],
  singleSelect = false
}) => {
  const limit = 10;

  const [page, setPage] = useState(0);
  const [selectedCollections, setSelectedCollections] = useState<Array<any>>(preSelections);

  const [collections, setCollections] = useState<Array<any>>([]);
  const [totalCollectionCount, setTotalCollectionCount] = useState<number>(0);

  const [getData, { data, loading }] = useCollectionsUpsellingLazyQuery({
    variables: {
      collectionTitle: '',
      collectionType: filterCollectionType,
      skip: 0,
      limit: 10
    }
  });

  const [searchedValue, setSearchedValue] = useState({
    input: '',
    skip: 0,
    limit: 10
  });

  const handleSearch = (searchParams: any, skip: number, limit: number) => {
    getData({
      variables: {
        collectionTitle: searchParams.input || '',
        collectionType: filterCollectionType,
        skip,
        limit
      }
    }).then((response) => {
      const { data } = response;
      const newCollections = data?.collections?.data || [];

      setTotalCollectionCount(data?.collections?.page_info?.total_count || 0);

      if (collections.length > 0) {
        setCollections((prevState) => [...prevState, ...newCollections]);
      } else {
        setCollections([...newCollections]);
      }
    });
  };

  const debouncedSearch = useCallback(debounce(handleSearch, 300), [collections]);

  const handleCollectionSelection = (collectionId: number, item: Collection) => {
    if (singleSelect) {
      setSelectedCollections([item]);
    } else {
      const selectedCollectionIndex = selectedCollections.findIndex(
        (collection: Collection) => collection.collection_id === collectionId
      );
      const newSelectedCollections = [...selectedCollections];

      if (selectedCollectionIndex !== -1) {
        newSelectedCollections.splice(selectedCollectionIndex, 1);
      } else {
        newSelectedCollections.push(item);
      }

      setSelectedCollections(newSelectedCollections);
    }
  };

  useEffect(() => {
    setPage(0);
    setCollections([]);
    setSearchedValue({
      input: search,
      skip: 0,
      limit: 10
    });
  }, [search]);

  useEffect(() => {
    setSelectedCollections(preSelections);
  }, [preSelections]);

  useEffect(() => {
    debouncedSearch(searchedValue, limit * page, limit);
  }, [searchedValue, page]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (open && !!search) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(search.length, search.length);
        }
      }, 0);
    }
  }, [open]);

  return (
    <>
      <Dialog open={open} onOpenChange={(value: boolean) => setOpen(value)}>
        <DialogContent className="!gap-0" size="sm" close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Select Collections</p>
              </DialogTitle>
              <div className="sticky z-10 bg-white top-0 p-3 !m-0 border-b-[1px]">
                <Input
                  ref={inputRef}
                  type="text"
                  name="search_collections"
                  placeholder="Search Collections"
                  className="!m-0"
                  value={searchedValue.input}
                  onChange={(e: any) => {
                    setPage(0);
                    setCollections([]);
                    setSearchedValue((prevState) => {
                      return {
                        ...prevState,
                        input: e.target.value
                      };
                    });
                  }}
                />
              </div>
              <DialogDescription className="!p-0 !m-0">
                <div id="scrollableDiv" className="w-full h-[60vh] overflow-y-auto scroll-smooth">
                  <InfiniteScroll
                    dataLength={collections.length}
                    next={() => {
                      if ((collections.length || 0) < totalCollectionCount) setPage((prevState) => prevState + 1);
                    }}
                    hasMore={collections.length < totalCollectionCount}
                    loader={''}
                    scrollableTarget="scrollableDiv"
                  >
                    {collections.length > 0 ? (
                      collections?.map((item) => (
                        <React.Fragment key={item.collection_id}>
                          <div
                            className="w-[100%] h-[66px] flex items-center border-b-[1px] cursor-pointer px-4 sm:px-6 hover:bg-slate-100 transition-colors ease-linear"
                            onClick={() => handleCollectionSelection(item?.collection_id, item)}
                          >
                            {singleSelect ? (
                              <>
                                <RadioGroup
                                  value={selectedCollections[0]?.collection_id}
                                  onValueChange={(value) => handleCollectionSelection(item?.collection_id, item)}
                                >
                                  <RadioGroupItem value={item.collection_id} />
                                </RadioGroup>
                              </>
                            ) : (
                              <Checkbox
                                name={'collection-checkbox'}
                                checked={selectedCollections.some(
                                  (collection: Collection) => collection.collection_id === item.collection_id
                                )}
                              />
                            )}

                            <div className="border rounded-lg border-black-200 border-solid p-2 ml-4">
                              {!item.image_src || item?.image_src === '' ? (
                                <Unicons.UilImages style={{ height: '1.5rem', width: '1.5rem' }} />
                              ) : (
                                <img src={item?.image_src} style={{ height: '1.5rem', width: '1.5rem' }} />
                              )}
                            </div>
                            <div className="ml-2 text-gray-700">
                              <div className="flex items-center">
                                <div>{item?.title}</div>
                                {item?.status === 'draft' && (
                                  <div className="ml-1.5 bg-[#d5ebff] rounded-lg px-2 py-0.5 w-fit text-xs">Draft</div>
                                )}
                              </div>
                              {item?.product_count && item?.product_count > 0 ? (
                                <div className="text-[#616161] text-xs mt-0.5">{item?.product_count} products</div>
                              ) : null}
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    ) : data && data.collections.data?.length === 0 ? (
                      <div className="px-6 py-4 text-gray-500">No Results found for "{searchedValue.input}"</div>
                    ) : (
                      [0, 1, 2, 4].map((index) => (
                        <div key={index} className="flex justify-between py-2 px-5">
                          <div className="flex items-center gap-2">
                            <Skeleton className="!m-0 h-5 w-5" />
                            <Skeleton className="h-[42px] w-[42px]" />
                            <div>
                              <Skeleton size={'label'} className="w-32 sm:w-40 h-5" />
                              <Skeleton size={'md'} className="mt-1.5" />
                            </div>
                          </div>
                          <Skeleton size={'label'} className="w-12 sm:w-20" />
                        </div>
                      ))
                    )}
                  </InfiniteScroll>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'outline'}
              size={'md'}
              onClick={async () => {
                setSelectedCollections(preSelections);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant={'default'}
              size={'md'}
              onClick={async () => {
                onSave(selectedCollections);
                setOpen(false);
              }}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
