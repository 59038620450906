import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Button
} from '../../flexyui';
import './date-picker.scss';
import moment from 'moment';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useScreenSize from '../../../hooks/use-screen-size';
import { DateRangePicker, defaultStaticRanges, createStaticRanges } from 'react-date-range';

type Props = {
  open: boolean;
  onClose: () => void;
  setDateRange: (data: any) => void;
};

export const DatePicker: React.FC<Props> = ({ setDateRange, onClose, open }) => {
  const isMobile = useScreenSize().width < 640;
  const savedRanges = localStorage.getItem('date-range');

  const [ranges, setRanges] = useState<any>([
    savedRanges
      ? {
          startDate: new Date(JSON.parse(savedRanges)?.startDate),
          endDate: new Date(JSON.parse(savedRanges)?.endDate),
          key: 'selection'
        }
      : {
          startDate: DateTime.now()
            .minus({
              days: 7
            })
            .toJSDate(),
          endDate: DateTime.now().toJSDate(),
          key: 'selection'
        }
  ]);

  const staticRanges: any = createStaticRanges([
    ...defaultStaticRanges,
    {
      label: 'This Year',
      range: () => ({
        startDate: moment().startOf('year').toDate(),
        endDate: moment().endOf('day').toDate()
      }),
      isSelected: () => false
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: moment().subtract(1, 'years').startOf('year').toDate(),
        endDate: moment().subtract(1, 'years').endOf('year').toDate()
      }),
      isSelected: () => false
    }
  ]);

  const checkValidDate = (selectedDate: any) => {
    return selectedDate >= DateTime.now();
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="!gap-0" size={isMobile ? 'default' : 'md'} close={true}>
          <div>
            <DialogHeader>
              <DialogTitle>
                <p>Date Picker</p>
              </DialogTitle>
              <DialogDescription className="!p-0 !m-0 text-[#2A324C] min-h-[425px] max-h-[75dvh] sm:max-h-none overflow-y-scroll sm:overflow-y-hidden ">
                <DateRangePicker
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  color="#8B7CE1"
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction="horizontal"
                  disabledDay={checkValidDate}
                  rangeColors={['#2F72FF', '#2F72FF']}
                  ranges={ranges || []}
                  onChange={(ranges: any) => {
                    setRanges([ranges.selection]);
                  }}
                  // minDate={minDate}
                  staticRanges={staticRanges}
                  inputRanges={[]}
                />
              </DialogDescription>
            </DialogHeader>
          </div>
          <DialogFooter>
            <Button
              variant={'default'}
              size={'md'}
              onClick={() => {
                const currentDate = new Date();
                const inputDate = new Date(ranges[0].endDate);
                let rangeEndDate = '';

                if (inputDate > currentDate) {
                  rangeEndDate = currentDate.toISOString();
                } else {
                  rangeEndDate = inputDate.toISOString();
                }
                setDateRange({
                  startDate: DateTime.fromISO(ranges[0].startDate.toISOString()).toISODate(),
                  endDate: DateTime.fromISO(rangeEndDate).toISODate()
                });
                onClose();
              }}
            >
              Apply
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
