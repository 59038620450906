import { useEffect } from 'react';
import { trackSessionEnd, trackSessionStart } from './mixpanel';

export const useSessionTracker = (sessionProperties = {}) => {
  useEffect(() => {
    const sessionKey = 'flexy_session';

    const isSessionActive = sessionStorage.getItem(sessionKey);

    if (!isSessionActive) {
      trackSessionStart(sessionProperties);
      sessionStorage.setItem(sessionKey, 'true');
    }

    const handleSessionEnd = () => {
      if (sessionStorage.getItem(sessionKey)) {
        trackSessionEnd(sessionProperties);
        sessionStorage.removeItem(sessionKey);
      }
    };

    window.addEventListener('beforeunload', handleSessionEnd);

    return () => window.removeEventListener('beforeunload', handleSessionEnd);
  }, []);
};

export default useSessionTracker;
