import * as Yup from 'yup';
import { ApplicationTarget } from '../../../graphql';
import { UserScope } from '../../../constants/coupons';
import { DiscountSchema, PurchaseRequirementSchema } from './index';
import { ErrorMessages, requiredError } from '../../../constants/error-messages';

export const DiscountFreebieSchema = DiscountSchema.shape({
  add_on: Yup.object().shape({
    max_quantity: Yup.number()
      .integer(ErrorMessages.WHOLE_NO_ERROR)
      .moreThan(0, ErrorMessages.NEGATIVE_ERROR)
      .required(requiredError('Quantity'))
      .test('max-quantity-less-than-variants-length', 'Must not exceed the total number of variants', function (value) {
        // eslint-disable-next-line no-invalid-this
        const { products } = this.parent;
        const totalVariants: number = products.reduce((sum: number, product: any) => {
          return sum + product.variants.length;
        }, 0);

        return value <= totalVariants;
      }),
    products: Yup.array().default([]).min(1, ErrorMessages.MIN_PRODUCT_ERROR)
  }),
  application: Yup.object().shape({
    type: Yup.string().default(ApplicationTarget.Universal).required(),
    products: Yup.array()
      .default([])
      .when('type', {
        is: ApplicationTarget.Products,
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      }),
    collections: Yup.array()
      .default([])
      .when('type', {
        is: ApplicationTarget.Collections,
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR)
      })
  }),
  purchase_requirement: PurchaseRequirementSchema.shape({
    products: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_PRODUCTS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_PRODUCT_ERROR)
      }),
    collections: Yup.array()
      .default([])
      .when(['type', 'productType'], {
        is: (type: string, productType: string) => type !== 'NONE' && productType === 'PURCHASE_COLLECTIONS',
        then: (schema) => schema.min(1, ErrorMessages.MIN_COLLECTION_ERROR)
      })
  }),
  user_specific_metadata: Yup.object().shape({
    user_scope: Yup.string().default(UserScope.ALL_USERS).required(),
    resource_id: Yup.string().when('user_scope', {
      is: UserScope.SPECIFIC_USERS,
      then: (schema) => schema.required(requiredError('CSV file')),
      otherwise: (schema) => schema.nullable()
    })
  })
});
