import React from 'react';
import classNames from 'classnames';
import { RootState } from '../../store';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Banner } from '../../components/banner';
import { Navbar } from '../../components/navbar/navbar';
import { Sidebar } from '../../components/sidebar/sidebar';

export const MainLayoutWrapper = () => {
  const banners = useSelector((state: RootState) => state.main.banners);
  return (
    <div className="h-full">
      <Banner />
      <Navbar />
      <Sidebar />
      <div
        className={classNames(
          'lg:ml-56 scroll-smooth bg-[#fbfbfb] transition-[margin-top,min-height] duration-300 ease-in-out',
          {
            'min-h-[calc(100dvh-80px)] mt-20': banners.length > 0,
            'min-h-[calc(100dvh-48px)] mt-12': banners.length === 0
          }
        )}
      >
        <Outlet />
      </div>
    </div>
  );
};
