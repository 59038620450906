import React, { useState } from 'react';
import { Button, Card, Chip, Dialog, DialogContent, DialogDescription, DialogHeader, Label } from '../../../flexyui';
import * as Unicons from '@iconscout/react-unicons';

export const SettingsBillingUsage = () => {
  const [featureDetails, setFeatureDetails] = useState<boolean>(false);
  return (
    <>
      <div className="grid grid-cols-6 gap-4">
        <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05) col-span-4">
          <div className="flex items-center justify-between px-4 pt-4 pb-3 border-b">
            <Label size="md" className="text-base text-[#2A324C]">
              Plan Details
            </Label>
          </div>
          <div className="col-span-4 border-r p-4">
            <div className="w-full flex items-center justify-between mb-4">
              <div>
                <div className="text-gray-400 leading-[16px]">Prepaid rate</div>
                <div className="text-[#121B38] font-bold text-2xl">1.7%</div>
              </div>
              <div>
                <div className="text-gray-400 leading-[16px]">COD rate</div>
                <div className="text-[#121B38] font-bold text-2xl">0.8%</div>
              </div>
              <div>
                <div className="text-gray-400 leading-[16px]">Fixed rate</div>
                <div className="text-[#121B38] font-bold text-2xl">-</div>
              </div>
              <div>
                <div className="flex items-center gap-1">
                  <div className="text-gray-400 leading-[16px]">Features rate</div>
                  <Unicons.UilInfoCircle size={15} className="cursor-pointer" onClick={() => setFeatureDetails(true)} />
                </div>
                <div className="text-[#121B38] font-bold text-2xl">₹400</div>
              </div>
            </div>
            <div className="border border-muted p-2 rounded-[6px]">
              <div className="font-medium mb-3">Plan Usage</div>
              <div className="text-gray-400 leading-[16px] mb-2">Orders</div>
              <div className="w-full h-2 bg-sky-400 rounded-2xl">
                <div className="w-[80%] h-2 bg-primary rounded-l-2xl"></div>
              </div>
              <div className="flex items-center gap-8 mt-3 mb-4 text-[13px]">
                <div className="flex items-center gap-1.5">
                  <div className="w-2.5 h-2.5 rounded-full bg-primary" />
                  <div>432 Prepaid order</div>
                </div>
                <div className="flex items-center gap-1.5">
                  <div className="w-2.5 h-2.5 rounded-full bg-sky-400" />
                  <div>98 COD order</div>
                </div>
              </div>
              <div className="flex justify-end text-[#888D9B] text-xs">
                <div>Usage resets on 31st Jan 2025</div>
              </div>
            </div>
          </div>
        </Card>
        <Card className="shadow-[0px_4px_20px_rgba(0,0,0,0.05) col-span-2 relative">
          <div className="flex items-center justify-between px-4 pt-4 pb-3 border-b">
            <Label size="md" className="text-base text-[#2A324C]">
              Bill Summary
            </Label>
          </div>
          <div className="p-4 space-y-3">
            <div>
              <div className="text-gray-400 leading-[16px] flex items-center justify-between">
                <div>Total Outstanding</div>
                <Chip text="3 bills" variant="warning" />
              </div>
              <div className="text-[#121B38] font-semibold text-xl">₹12,340</div>
            </div>
            <div>
              <div className="text-gray-400 leading-[16px]">Overdue</div>
              <div className="text-[#121B38] font-semibold text-xl">₹3,080</div>
            </div>
          </div>
          <div className="absolute bottom-4 right-4">
            <Button size="sm">Pay now</Button>
          </div>
        </Card>
      </div>

      <Dialog open={featureDetails} onOpenChange={(value: boolean) => setFeatureDetails(value)}>
        <DialogContent className="!gap-0" close={true}>
          <div>
            <DialogHeader>
              <DialogDescription className="!p-0 !m-0 text-[#2A324C]">
                <div className="p-4">
                  <div>
                    <div className="flex flex-col items-center text-center mt-4">
                      <div className="text-xl font-semibold">Feature Rates</div>
                      <div className="text-gray-500 text-[13px]">
                        View detailed information about the features pricing available in your current plan.
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 mt-6">
                      <Card className="shadow-lg p-3 hover:shadow-xl hover:scale-105 transition-all duration-300">
                        <div className="flex gap-3">
                          <div className="p-1.5 border border-gray-300 w-fit h-fit rounded-xl">
                            <Unicons.UilMapPin size={20} />
                          </div>
                          <div>
                            <div className="text-base font-medium">Upselling</div>
                            <div className="font-medium text-blue-500">₹100</div>
                          </div>
                        </div>
                      </Card>
                      <Card className="shadow-lg p-3 hover:shadow-xl hover:scale-105 transition-all duration-300">
                        <div className="flex gap-3">
                          <div className="p-1.5 border border-gray-300 w-fit h-fit rounded-xl">
                            <Unicons.UilLuggageCart size={20} />
                          </div>
                          <div>
                            <div className="text-base font-medium">RTO Suite</div>
                            <div className="font-medium text-blue-500">₹100</div>
                          </div>
                        </div>
                      </Card>
                      <Card className="shadow-lg p-3 hover:shadow-xl hover:scale-105 transition-all duration-300">
                        <div className="flex gap-3">
                          <div className="p-1.5 border border-gray-300 w-fit h-fit rounded-xl">
                            <Unicons.UilLuggageCart size={20} />
                          </div>
                          <div>
                            <div className="text-base font-medium">ACR Webhooks</div>
                            <div className="font-medium text-blue-500">₹200</div>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </DialogDescription>
            </DialogHeader>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
