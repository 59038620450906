import {
  CreatedByType,
  ShippingZoneType,
  useAddShippingRateMutation,
  useUpdateShippingRateMutation
} from '../../graphql';
import classNames from 'classnames';
import { enqueueSnackbar } from 'notistack';
import { Formik, FormikErrors } from 'formik';
import React, { useMemo, useState } from 'react';
import * as Unicons from '@iconscout/react-unicons';
import { ShippingPincode } from './shipping-pincode';
import { Drawer, DrawerContent } from '../flexyui/Drawer';
import { ShippingDrawerRules } from './shipping-drawer-rules';
import { Button, Input, Label, RadioGroup, Separator } from '../flexyui';
import { ShippingSchema } from '../../utils/validation-schemas/shipping';
import { RadioWithLabel } from '../shared/radio-with-label/radio-with-label';
import { EventCategory, ShippingEvents, trackEvents } from '../..//analytics';
import { ReactComponent as LoadingIcon } from '../../assets/images/loading.svg';

interface PriceConditionRule {
  minimum_value: number;
  maximum_value: number | null;
  delivery_price: number;
  cod_charges: number | null;
  cod_accepted: boolean;
}

export interface ShippingFields {
  title: string;
  delivery_estimation: string;
  enabled: boolean;
  type: any;
  price_condition_rules: PriceConditionRule[];
}

type props = {
  open: boolean;
  setOpen: (data: any) => void;
  shippingRateData: any;
  refetch: () => void;
};

export const ShippingDrawer: React.FC<props> = ({ open, setOpen, shippingRateData, refetch }) => {
  const [addRate] = useAddShippingRateMutation();
  const [updateRate] = useUpdateShippingRateMutation();
  const [resourceId, setResourceId] = useState<string | null>(null);

  const initialValues = useMemo(
    () => ({
      title: shippingRateData?.data?.title || '',
      delivery_estimation: shippingRateData?.data?.delivery_estimation || '',
      enabled: shippingRateData?.data?.enabled || true,
      type: shippingRateData?.data?.type
        ? shippingRateData?.data?.type === 'NONE'
          ? 'PRICE'
          : shippingRateData?.data?.type
        : 'PRICE',
      price_condition_rules: shippingRateData?.data?.price_condition_rules || [
        {
          minimum_value: 0,
          maximum_value: null,
          delivery_price: 0,
          cod_charges: null,
          cod_accepted: false
        }
      ]
    }),
    [shippingRateData]
  );

  const handleSave = async (values: any) => {
    const updatedPriceConditionRules = values.price_condition_rules.map((rule: any) => ({
      ...rule,
      maximum_value: rule.maximum_value === '' ? null : rule.maximum_value,
      cod_charges: rule.cod_accepted === false ? null : rule.cod_charges
    }));
    const updatedValues = {
      ...values,
      ...(resourceId && { resource_id: resourceId }),
      price_condition_rules: updatedPriceConditionRules
    };

    try {
      if (shippingRateData.data) {
        await updateRate({
          variables: {
            shippingId: shippingRateData.zoneId,
            shippingRateId: shippingRateData.data.id,
            configs: updatedValues
          }
        });
        trackEvents(EventCategory.SHIPPING, ShippingEvents.EDIT_RATE);
      } else {
        await addRate({
          variables: {
            shippingId: shippingRateData.zoneId,
            configs: updatedValues
          }
        });
        trackEvents(EventCategory.SHIPPING, ShippingEvents.ADD_RATE);
      }
      enqueueSnackbar(`Rate has been ${shippingRateData ? 'updated' : 'created'} successfully!`, {
        variant: 'success'
      });
      if (resourceId) trackEvents(EventCategory.SHIPPING, ShippingEvents.NON_SERVICEABLE_PINCODE);

      refetch();
    } catch (e) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
    } finally {
      setOpen(false);
    }
  };

  const handleTypeChange = (type: string, setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('type', type);
    setFieldValue('price_condition_rules', [
      {
        minimum_value: 0,
        maximum_value: null,
        delivery_price: 0,
        cod_charges: null,
        cod_accepted: false
      }
    ]);
  };

  const isShopifyCreated = shippingRateData?.data?.created_by === CreatedByType.Shopify;

  const scrollToError = (errors: FormikErrors<any>) => {
    const fieldPath = Object.keys(errors)[0];
    let errorElement: HTMLInputElement | null = null;

    if (fieldPath.startsWith('price_condition_rules')) {
      const errorItems = errors[fieldPath] as Array<Record<string, any>>;
      for (let index = 0; index < errorItems.length; index++) {
        const item = errorItems[index];
        if (item && typeof item === 'object') {
          const errorKey = Object.keys(item)[0];
          const selector = `[name="price_condition_rules[${index}].${errorKey}"]`;
          const potentialErrorElement: HTMLInputElement | null = document.querySelector(selector);
          if (potentialErrorElement) {
            errorElement = potentialErrorElement;
            break;
          }
        }
      }
    } else errorElement = document.querySelector(`[name="${fieldPath}"]`);

    if (errorElement) errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const highlightErrors = (
    errors: FormikErrors<any>,
    setFieldTouched: (field: string, isTouched: boolean, shouldValidate: boolean) => void,
    prefix = ''
  ) => {
    Object.entries(errors).forEach(([key, value]) => {
      const fieldPath = prefix ? `${prefix}.${key}` : key;

      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          const arrayFieldPath = `${fieldPath}[${index}]`;
          if (typeof item === 'object' && item !== null) {
            highlightErrors(item as FormikErrors<any>, setFieldTouched, arrayFieldPath);
          } else {
            setFieldTouched(arrayFieldPath, true, false);
          }
        });
      } else if (typeof value === 'object' && value !== null) {
        highlightErrors(value as FormikErrors<any>, setFieldTouched, fieldPath);
      } else {
        setFieldTouched(fieldPath, true, false);
      }
    });
  };

  return (
    <Drawer direction="right" open={open} onOpenChange={(value: boolean) => setOpen(value)}>
      <DrawerContent>
        <Formik<ShippingFields>
          enableReinitialize
          initialValues={initialValues}
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
          validationSchema={ShippingSchema}
          onSubmit={handleSave}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            getFieldProps,
            submitForm,
            isSubmitting,
            resetForm
          }) => {
            return (
              <div className="w-full relative sm:w-[570px] h-full overflow-y-scroll scrollbar-hide">
                <div className="sticky top-0 bg-white z-50">
                  <div className="flex items-center justify-between font-semibold px-3 py-3.5 sm:p-4">
                    <Label size="lg">{shippingRateData.data ? 'Edit' : 'Add'} Shipping Rate</Label>
                    <Button type="submit" size="icon" variant="icon" onClick={() => setOpen(false)}>
                      <Unicons.UilTimes className="text-[#2F72FF] cursor-pointer" />
                    </Button>
                  </div>
                  <hr />
                </div>
                <div className="p-3 sm:p-4 mb-20">
                  <div className="mb-2">
                    <Label size="paragraph">Name</Label>
                    <Input
                      {...getFieldProps('title')}
                      disabled={isShopifyCreated}
                      error={touched.title && !!errors.title}
                      errorMessage={errors.title}
                      placeholder="Standard Shipping"
                      className="mt-1"
                    />
                  </div>
                  <div className="mb-2">
                    <Label size="paragraph">Estimated Time</Label>
                    <Input
                      {...getFieldProps('delivery_estimation')}
                      error={touched.delivery_estimation && !!errors.delivery_estimation}
                      errorMessage={errors.delivery_estimation}
                      placeholder="3-5 Business days"
                      className="mt-1"
                    />
                  </div>
                  <Separator />
                  {isShopifyCreated ? (
                    <div className="flex flex-col">
                      <Label size="md" className="text-sm">
                        Type
                      </Label>
                      <Label className="text-[#888D9B]" size="sm">
                        Based on order {values?.type?.toLowerCase()}
                      </Label>
                    </div>
                  ) : (
                    <>
                      <Label size="md" className="text-sm">
                        Type
                      </Label>
                      <RadioGroup
                        {...getFieldProps('type')}
                        defaultValue="PRICE"
                        className="flex flex-col gap-0 mt-1.5"
                      >
                        <RadioWithLabel
                          label="Based on order price"
                          value="PRICE"
                          onClick={() => handleTypeChange('PRICE', setFieldValue)}
                        />
                        <RadioWithLabel
                          label="Based on order weight"
                          value="WEIGHT"
                          onClick={() => handleTypeChange('WEIGHT', setFieldValue)}
                        />
                      </RadioGroup>
                    </>
                  )}

                  <Separator />
                  <Label size="md" className="text-sm">
                    Pricing Conditions
                  </Label>

                  <ShippingDrawerRules isShopifyCreated={isShopifyCreated} />
                  {shippingRateData.zoneType === ShippingZoneType.StateCountry && (
                    <>
                      <Separator />
                      <ShippingPincode
                        level="SHIPPING"
                        details={shippingRateData?.data}
                        setResourceId={setResourceId}
                      />
                    </>
                  )}
                </div>
                <div className="flex justify-end gap-2 fixed bottom-0 border-t-[1px] border-t-[#E2E2E2] p-3 bg-white w-full transition duration-150 ease-out hover:ease-in z-[10]">
                  <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    variant="solid"
                    className="ml-3 text-center"
                    onClick={async () => {
                      if (Object.keys(errors).length > 0) {
                        scrollToError(errors);
                        highlightErrors(errors, setFieldTouched);
                        return;
                      }
                      await submitForm();
                      resetForm();
                    }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <LoadingIcon height={16} className={classNames('h-5 w-5 animate-spin text-white')} />
                    ) : (
                      'Save'
                    )}
                  </Button>
                </div>
              </div>
            );
          }}
        </Formik>
      </DrawerContent>
    </Drawer>
  );
};
