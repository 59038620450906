import { AnalyticsInput, AnalyticsStatusQuery } from '../../graphql';

export const getUpdateAnalyticsPayload = (
  data: AnalyticsStatusQuery,
  value: boolean,
  analytics: string
): AnalyticsInput => {
  switch (analytics) {
    case 'meta_pixels':
      return {
        meta: value,
        googleAds: data?.analytics?.google_ads?.enabled,
        googleAnalytics: data?.analytics?.google_analytics?.enabled
      };
    case 'google_ads':
      return {
        meta: data?.analytics?.meta?.enabled,
        googleAds: value,
        googleAnalytics: data?.analytics?.google_analytics?.enabled
      };
    case 'google_analytics':
      return {
        meta: data?.analytics?.meta?.enabled,
        googleAds: data?.analytics?.google_ads?.enabled,
        googleAnalytics: value
      };
    default:
      return { googleAds: false, googleAnalytics: false, meta: false };
  }
};
