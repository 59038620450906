import React, { useCallback, useEffect, useState } from 'react';
import { DndContext, closestCenter, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import {
  MethodLayoutStyle,
  PaymentCustomisationConfigsQuery,
  usePayment_Customisation_ConfigQuery as usePaymentQuery,
  useUpdatePaymentCustomisationMutation
} from '../../../graphql';
import CustomizationLayout from '../../dashboard/customizations/layout';
import PaymentMethodCard from '../../dashboard/payment-methods/payment-method-card';
import { Label, RadioGroup, RadioGroupItem, Separator, Switch } from '../../flexyui';
import { PaymentUiConfigs, setPage, setPaymentCustomization } from '../../../store/customization-slice';
import { AppDispatch, RootState } from '../../../store';
import useSaveCancelButtons from '../../../hooks/use-save-cancel';
import ErrorHandling from '../../error-handling/error-handling';
import { enqueueSnackbar } from 'notistack';
import classNames from 'classnames';
import MethodLayout1Content from './method-layout-1';
import { paymentMethod1 } from '../../../constants/customizations';
import MethodLayoutCard from './method-card';
import MethodLayout2 from './method-layout-2';
import MethodLayout3 from './method-layout-3';
import { capitalizeFirstLetter } from '../../../utils/convert-currency';
import { EventCategory, PaymentCustomizationEvents, trackEvents } from '../../../analytics';

export interface PaymentItem {
  position: number;
  title: string;
  subtitle: string;
  description: string;
  visibility: boolean;
}

const PaymentsTab: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const paymentCustomization = useSelector((state: RootState) => state.customization.payment);
  const { data: paymentData, loading: paymentLoading, error: paymentError, refetch } = usePaymentQuery();
  const [updatePaymentCustomisationMutation] = useUpdatePaymentCustomisationMutation();
  const { setShowActions, saveButtonClicked, cancelButtonClicked, setLoadingActions } = useSaveCancelButtons();
  const [cards, setCards] = useState<any>([]);
  const [activeId, setActiveId] = useState(null);
  const [bannerCards, setBannerCards] = useState<any>([]);

  function organizePaymentMethods() {
    const organizedObject: any = {
      payment_methods: {}
    };

    cards.forEach((card: PaymentUiConfigs, index: number) => {
      const method = card.key;
      organizedObject.payment_methods[method || 'KEY'] = {
        title: card.title,
        description: card.description,
        layout_title: card.layout_title,
        layout_description: card.layout_description,
        position: index,
        visibility: card.visibility
      };
    });
    return organizedObject.payment_methods;
  }

  const handleSave = async () => {
    const data = organizePaymentMethods();
    setLoadingActions(true);
    try {
      await updatePaymentCustomisationMutation({
        variables: {
          configs: {
            ...paymentCustomization,
            method_layout_style: paymentCustomization.method_layout_style ?? MethodLayoutStyle.Layout_1,
            payment_methods: data
          }
        }
      });
    } catch (error) {
      enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
        variant: 'error'
      });
      setLoadingActions(false);
      setShowActions(false);
    }
    const response = await refetch();
    setLoadingActions(false);
    setCards(response.data.customisations.payment_customisation_config.payment_methods);
  };

  const handleEdit = (value: any, type: string) => {
    const layoutStyle = paymentCustomization?.method_layout_style as MethodLayoutStyle;

    const updatedPaymentMethods = paymentCustomization.payment_methods?.map((item) => {
      if (item.key === type) {
        const newLayoutDescription = { ...item.layout_description };

        if (layoutStyle !== MethodLayoutStyle.Layout_3) {
          newLayoutDescription[layoutStyle] = value.description;
        }

        return {
          ...item,
          layout_title: {
            ...item.layout_title,
            [layoutStyle]: value.title
          },
          layout_description: newLayoutDescription
        };
      } else if (type === 'COD' && item.key === 'PARTIAL_COD') {
        const newLayoutDescription = { ...item.layout_description };

        if (layoutStyle !== MethodLayoutStyle.Layout_3) {
          newLayoutDescription[layoutStyle] = value.partialCodDescription;
        }

        return {
          ...item,
          layout_title: {
            ...item.layout_title,
            [layoutStyle]: value.partialCodTitle
          },
          layout_description: newLayoutDescription
        };
      }
      return item;
    });

    const data = {
      ...paymentCustomization,
      payment_methods: updatedPaymentMethods
    } as PaymentCustomisationConfigsQuery;

    setCards(updatedPaymentMethods);
    dispatch(setPaymentCustomization(data));
    trackEvents(EventCategory.PAYMENT_CUSTOMIZATION, PaymentCustomizationEvents.METHOD_EDIT);
  };

  const handleVisibility = (value: boolean, type: string) => {
    const method = paymentCustomization.payment_methods?.find((item) => item.key === type);
    const updatedMethod = { ...method, visibility: value };
    const updatedPaymentMethods = paymentCustomization.payment_methods?.map((item) =>
      item.key === type ? updatedMethod : item
    );
    setCards(updatedPaymentMethods);
    const data = {
      ...paymentCustomization,
      payment_methods: updatedPaymentMethods
    };
    dispatch(setPaymentCustomization(data));
    trackEvents(EventCategory.PAYMENT_CUSTOMIZATION, PaymentCustomizationEvents.METHOD_VISIBILITY_CHANGED);
  };

  const handleCancelPaymentMethods = () => {
    setCards((prev: any) => prev);
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 5
    }
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 250,
      tolerance: 5
    }
  });
  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = (event: any) => {
    setActiveId(event.active.id);
    trackEvents(EventCategory.PAYMENT_CUSTOMIZATION, PaymentCustomizationEvents.METHODS_REARRANGED);
  };

  const handleDragEnd = (event: any) => {
    setActiveId(null);

    const { active, over } = event;

    if (active.id !== over.id) {
      setCards((items: PaymentItem[]) => {
        const oldIndex = items.findIndex((item: PaymentItem) => `${item.position}` === active.id);
        const newIndex = items.findIndex((item: PaymentItem) => `${item.position}` === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const renderPaymentMethodsCard = useCallback(
    (payment: any, index: number) => {
      const layoutStyle = paymentCustomization?.method_layout_style as MethodLayoutStyle;
      const hasDescription = layoutStyle !== MethodLayoutStyle.Layout_3;

      if (payment.key === 'COD') {
        const partialCodData = cards.find((item: PaymentUiConfigs) => item.key === 'PARTIAL_COD');
        payment = {
          ...payment,
          PARTIAL_COD: {
            title: partialCodData?.layout_title?.[layoutStyle],
            description: hasDescription ? partialCodData?.layout_description?.[layoutStyle] : ''
          }
        };
      }

      const title = payment.layout_title?.[layoutStyle];
      const description = hasDescription ? payment.layout_description?.[layoutStyle] : '';

      const updatedPayment = {
        ...payment,
        title,
        description
      };

      return (
        <SortableContext
          items={cards
            .filter((each: PaymentUiConfigs) => each.key !== 'PARTIAL_COD')
            .map((item: PaymentItem) => `${item.position}`)}
          strategy={verticalListSortingStrategy}
        >
          <PaymentMethodCard
            key={index}
            data={updatedPayment}
            id={payment.position}
            handleEdit={handleEdit}
            handleVisibility={handleVisibility}
            handleCancel={handleCancelPaymentMethods}
          />
        </SortableContext>
      );
    },
    [cards, paymentCustomization]
  );

  const handleCancel = () => {
    if (paymentData?.customisations.payment_customisation_config) {
      setCards(paymentData?.customisations.payment_customisation_config.payment_methods);
      setBannerCards(paymentData?.customisations.payment_customisation_config.banner.banners);
      dispatch(setPaymentCustomization(paymentData?.customisations.payment_customisation_config));
    }
  };

  const handleLayoutOrder = () => {
    const order = cards.map((card: PaymentUiConfigs, index: number) => [{ ...card, position: index }]);
    let orderedCards: PaymentUiConfigs[] = [];

    Object.values(order).forEach((array: any) => {
      orderedCards = orderedCards.concat(array);
    });
    const data = {
      ...paymentCustomization,
      payment_methods: [...orderedCards]
    };
    dispatch(setPaymentCustomization(data));
  };

  useEffect(() => {
    handleLayoutOrder();
  }, [cards]);

  useEffect(() => {
    dispatch(setPage('Payment'));
  }, []);

  useEffect(() => {
    if (paymentData?.customisations?.payment_customisation_config?.banner?.banners) {
      setBannerCards(paymentData?.customisations?.payment_customisation_config?.banner?.banners);
    }
  }, [paymentData]);

  useEffect(() => {
    if (paymentData?.customisations?.payment_customisation_config) {
      setCards(paymentData?.customisations?.payment_customisation_config.payment_methods);
      dispatch(setPaymentCustomization(paymentData.customisations?.payment_customisation_config));
    }
  }, [paymentData?.customisations?.payment_customisation_config]);

  useEffect(() => {
    const hasChanges = !isEqual(paymentData?.customisations.payment_customisation_config, paymentCustomization);
    if (hasChanges) {
      if (!paymentLoading) setShowActions(true);
      if (saveButtonClicked) {
        handleSave();
      }
      if (cancelButtonClicked) {
        handleCancel();
      }
    } else {
      setShowActions(false);
    }
  }, [
    paymentData?.customisations.payment_customisation_config,
    paymentCustomization,
    saveButtonClicked,
    cancelButtonClicked
  ]);

  const handleLayoutStyle = (value: MethodLayoutStyle) => {
    const data = {
      ...paymentCustomization,
      method_layout_style: value
    };
    dispatch(setPaymentCustomization(data));
    trackEvents(EventCategory.PAYMENT_CUSTOMIZATION, PaymentCustomizationEvents.LAYOUT_CHANGED, {
      method_layout_style: value
    });
  };

  const handleShowBusinessGst = (value: boolean) => {
    const data = {
      ...paymentCustomization,
      business_gst: {
        visibility: value
      }
    };
    dispatch(setPaymentCustomization(data));
    trackEvents(EventCategory.PAYMENT_CUSTOMIZATION, PaymentCustomizationEvents.BILLING_GST);
  };

  if (paymentError?.networkError) return <ErrorHandling />;

  return (
    <>
      <CustomizationLayout
        tab={'Payment'}
        lockCoupons={false}
        loading={paymentLoading}
        tabData={paymentCustomization}
        placeholder="Proceed to Pay"
        setCustomization={setPaymentCustomization}
        cards={bannerCards}
        setCards={setBannerCards}
      />

      <Separator />
      <Label size={'md'}>Billing & GST</Label>
      <div className="flex items-center gap-2 mt-4">
        <Switch
          id="show_business_gst"
          checked={paymentCustomization?.business_gst?.visibility}
          onCheckedChange={handleShowBusinessGst}
        />
        <Label size={'paragraph'} className="text-[#595F74]" htmlFor="show_business_gst">
          Enable billing & GST
        </Label>
      </div>

      <Separator />
      <Label size={'md'}>Payment method UI</Label>
      <RadioGroup
        value={paymentCustomization.method_layout_style || MethodLayoutStyle.Layout_1}
        className="flex flex-col mt-3 transition-colors ease-linear"
        onValueChange={handleLayoutStyle}
      >
        <div className="flex items-center space-x-2 ">
          <Label
            htmlFor={MethodLayoutStyle.Layout_1}
            className={classNames(
              'w-full sm:w-fit h-fit border border-border p-[10px] rounded-3xl cursor-pointer hover:bg-slate-50',
              {
                'border-2 border-primary': paymentCustomization.method_layout_style === MethodLayoutStyle.Layout_1
              }
            )}
          >
            <div className="flex w-full h-full justify-between flex-col">
              <div className="flex items-center h-full space-x-2">
                <RadioGroupItem value={MethodLayoutStyle.Layout_1} id={MethodLayoutStyle.Layout_1} />
                <Label size="paragraph">{capitalizeFirstLetter(MethodLayoutStyle.Layout_1?.replace(/_/g, ' '))}</Label>
              </div>
              <MethodLayoutCard>
                <div className="flex items-center">
                  <MethodLayout1Content data={paymentMethod1} />
                </div>
              </MethodLayoutCard>
            </div>
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <Label
            htmlFor={MethodLayoutStyle.Layout_2}
            className={classNames(
              'w-full sm:w-fit h-fit border border-border p-[10px] rounded-3xl cursor-pointer hover:bg-slate-50',
              {
                'border-2 border-primary': paymentCustomization.method_layout_style === MethodLayoutStyle.Layout_2
              }
            )}
          >
            <div className="flex w-full h-full justify-between flex-col">
              <div className="flex items-center h-full space-x-2">
                <RadioGroupItem value={MethodLayoutStyle.Layout_2} id={MethodLayoutStyle.Layout_2} />
                <Label size="paragraph">{capitalizeFirstLetter(MethodLayoutStyle.Layout_2?.replace(/_/g, ' '))}</Label>
              </div>
              <MethodLayout2 data={paymentMethod1} />
            </div>
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <Label
            htmlFor={MethodLayoutStyle.Layout_3}
            className={classNames(
              'w-full sm:w-fit h-fit border border-border p-[10px] rounded-3xl cursor-pointer hover:bg-slate-50',
              {
                'border-2 border-primary': paymentCustomization.method_layout_style === MethodLayoutStyle.Layout_3
              }
            )}
          >
            <div className="flex w-full h-full justify-between flex-col">
              <div className="flex items-center h-full space-x-2">
                <RadioGroupItem value={MethodLayoutStyle.Layout_3} id={MethodLayoutStyle.Layout_3} />
                <Label size="paragraph">{capitalizeFirstLetter(MethodLayoutStyle.Layout_3?.replace(/_/g, ' '))}</Label>
              </div>
              <MethodLayout3 data={paymentMethod1} />
            </div>
          </Label>
        </div>
      </RadioGroup>

      {!paymentLoading && (
        <>
          <Separator />
          <Label size={'md'}>Payment methods</Label>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <div className="mt-3">
              <div className="rounded-lg overflow-hidden">
                {cards
                  // ?.filter((each: PaymentUiConfigs) => each.key !== 'PARTIAL_COD')
                  ?.filter((each: PaymentUiConfigs) => each.key !== 'PARTIAL_COD' && each.key !== 'SNAPMINT')
                  ?.map((card: PaymentUiConfigs, i: number) => renderPaymentMethodsCard(card, i))}
              </div>
            </div>
          </DndContext>
        </>
      )}
    </>
  );
};

export default PaymentsTab;
