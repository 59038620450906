import {
  Card,
  Input,
  Label,
  RadioGroup,
  Select as SelectCN,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator
} from '../flexyui';
import {
  useGetBusinessDetailQuery,
  useGetMerchantLogoQuery,
  useUpdateBusinessDetailMutation,
  useUpdateMerchantLogoMutation
} from '../../graphql';
import { useFormik } from 'formik';
import Dropzone from 'react-dropzone';
import { StepContent } from './step-content';
import * as Unicons from '@iconscout/react-unicons';
import { useMutation } from '@tanstack/react-query';
import { indianStates } from '../../constants/settings';
import React, { useEffect, useMemo, useState } from 'react';
import { StepCallback } from '@/src/pages/onboarding/onboarding';
import { imageUpload } from '../../network/customization/customization';
import { RadioWithLabel } from '../shared/radio-with-label/radio-with-label';
import { businessDetailsSchema } from '../../utils/validation-schemas/onboarding';

type FileData = {
  image: string;
  filename: string;
};

type Props = {
  activeStep: number;
  setStepCallback: (callback: StepCallback) => void;
  setDisabled: (disabled: boolean) => void;
};

export const BusinessDetails: React.FC<Props> = ({ activeStep, setStepCallback, setDisabled }) => {
  const { data, loading } = useGetMerchantLogoQuery();
  const { data: businessDetails, refetch: businessDetailsRefetch } = useGetBusinessDetailQuery();
  const [updateBusinessDetails] = useUpdateBusinessDetailMutation();
  const [updateMerchantLogo] = useUpdateMerchantLogoMutation();

  const [useGst, setUseGst] = useState<'YES' | 'NO'>('YES');
  const [selectImage, setSelectImage] = useState<FileData | null>(null);

  const imageUploadMutation = useMutation({
    mutationFn: imageUpload
  });

  const handleFileChange = (files: File[]) => {
    const file = files[0];
    if (!file) return;

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = async (event) => {
      try {
        const body = {
          image: String(event?.target?.result || ''),
          filename: file.name
        };

        setSelectImage(body);
        await imageUploadMutation.mutateAsync(body);
        await updateMerchantLogo({
          variables: {
            logo: body.image
          }
        });
        // await refetch();
      } catch (e) {
        console.error(e);
      }
    };

    fileReader.onerror = (e) => console.error(e);
  };

  const handleSave = async (values: any) => {
    try {
      await updateBusinessDetails({
        variables: {
          detail: {
            ...businessDetails?.getBusinessDetail,
            ...values
          }
        }
      });

      await businessDetailsRefetch();
    } catch (e) {
      console.error(e);
    } finally {
      setDisabled(false);
    }
  };

  const initialValues = useMemo(() => {
    return {
      legal_name: businessDetails?.getBusinessDetail?.legal_name || '',
      trade_name: businessDetails?.getBusinessDetail?.trade_name || '',
      GSTIN: businessDetails?.getBusinessDetail?.GSTIN || '',
      company_name_on_gst: businessDetails?.getBusinessDetail?.company_name_on_gst || '',
      address: {
        address1: businessDetails?.getBusinessDetail?.address?.address1 || '',
        address2: businessDetails?.getBusinessDetail?.address?.address2 || '',
        city: businessDetails?.getBusinessDetail?.address?.city || '',
        state: businessDetails?.getBusinessDetail?.address?.state || '',
        country: 'India',
        postal_code: businessDetails?.getBusinessDetail?.address?.postal_code || ''
      }
    };
  }, [businessDetails?.getBusinessDetail]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validateOnChange: true,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: businessDetailsSchema(useGst === 'YES'),
    onSubmit: handleSave
  });

  useEffect(() => {
    formik.validateForm();
  }, [useGst]);

  useEffect(() => {
    setStepCallback(formik.submitForm);
  }, [setStepCallback]);

  useEffect(() => {
    setDisabled(Object.keys(formik.errors).length > 0);
  }, [formik.errors]);

  return (
    <>
      <StepContent activeStep={activeStep} />

      <div className="mb-3">
        <Label size="md" className="text-base text-[#2A324C]">
          Store Logo
        </Label>
        <div className="text-[#888D9B] text-xs">
          Upload your store's logo to display across your checkout and storefront.
        </div>
      </div>
      <div className="pl-2 flex items-center gap-4">
        <img
          src={selectImage?.image || data?.getMerchantLogo || 'https://static.flexype.in/assets/flexype-full-black.svg'}
          alt="Logo Image"
          className="aspect-auto h-[60px] px-2 w-[150px] rounded-md object-contain border border-muted"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = 'https://static.flexype.in/assets/flexype-full-black.svg';
          }}
        />

        <Dropzone
          onDrop={(acceptedFiles) => {
            handleFileChange(acceptedFiles);
          }}
          maxFiles={1}
          multiple={false}
          accept={{
            'image/*': ['.jpeg', '.png']
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Card {...getRootProps()} className="dropzone w-full h-[60px] flex-1 flex items-center gap-3 py-2 px-3">
              <div className="bg-muted p-2 w-fit rounded-full">
                <Unicons.UilCloudUpload />
              </div>
              <div>
                <input name="logo" {...getInputProps()} />
                <p className="text-xs">
                  <span className="font-medium underline cursor-pointer">Click here to upload</span>
                  <span className="no-underline ml-1 text-[#82889A]">
                    or drag and drop WEBP, PNG, JPG or GIF (max 150 x 40 px)
                  </span>
                </p>
              </div>
            </Card>
          )}
        </Dropzone>
      </div>

      <Separator className="my-8" />

      <div className="mb-3">
        <Label size="md" className="text-base text-[#2A324C]">
          Business Details
        </Label>
        <div className="text-[#888D9B] text-xs">
          Provide your business details to personalize your store and invoices.
        </div>
      </div>
      <div className="pl-2">
        <Label className="required-field">Legal Name</Label>
        <Input
          {...formik.getFieldProps('legal_name')}
          error={formik.touched.legal_name && !!formik.errors.legal_name}
          className="text-[#121b38] mt-1 mb-2"
        />
        <Label className="required-field">Trade Name</Label>
        <Input
          {...formik.getFieldProps('trade_name')}
          error={formik.touched.trade_name && !!formik.errors.trade_name}
          className="text-[#121b38] mt-1 mb-2"
        />
        <Label className="required-field">Address line 1</Label>
        <Input
          {...formik.getFieldProps('address.address1')}
          error={formik.touched.address?.address1 && !!formik.errors.address?.address1}
          className="text-[#121b38] mt-1 mb-2"
        />
        <Label>Address line 2</Label>
        <Input
          {...formik.getFieldProps('address.address2')}
          error={formik.touched.address?.address2 && !!formik.errors.address?.address2}
          className="text-[#121b38] mt-1 mb-2"
        />
        <Label className="required-field">Pincode</Label>
        <Input
          {...formik.getFieldProps('address.postal_code')}
          type="number"
          error={formik.touched.address?.postal_code && !!formik.errors.address?.postal_code}
          className="text-[#121b38] mt-1 mb-2"
        />

        <div className="grid grid-cols-2 gap-2">
          <div>
            <Label className="required-field">City</Label>
            <Input
              {...formik.getFieldProps('address.city')}
              error={formik.touched.address?.city && !!formik.errors.address?.city}
              className="text-[#121b38] mt-1"
            />
          </div>
          <div>
            <Label className="required-field">State</Label>
            <SelectCN
              value={formik.values.address?.state}
              onValueChange={(value) => {
                formik.setFieldValue('address.state', value);
              }}
            >
              <SelectTrigger className="h-10 mt-1 w-full">
                <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
                  <SelectValue placeholder="Select a state" />
                </div>
              </SelectTrigger>
              <SelectContent side="bottom" longList={true}>
                {indianStates
                  .filter((states) => states.state_code !== 'DEFAULT')
                  .map((state) => (
                    <SelectItem key={state.state_code} value={state.state}>
                      {state.state}
                    </SelectItem>
                  ))}
              </SelectContent>
            </SelectCN>
          </div>
        </div>
      </div>

      <Separator className="my-8" />

      <div className="mb-3">
        <Label size="md" className="text-base text-[#2A324C]">
          GST Details
        </Label>
        <div className="text-[#888D9B] text-xs">
          Provide your GST details to ensure tax compliance and proper invoicing.
        </div>
      </div>
      <div className="pl-2">
        <RadioGroup value={useGst} className="flex items-center gap-6 mb-2">
          <RadioWithLabel label="Yes, I have GST" value="YES" onClick={() => setUseGst('YES')} />
          <RadioWithLabel label="No, I don’t have GST" value="NO" onClick={() => setUseGst('NO')} />
        </RadioGroup>

        {useGst === 'YES' && (
          <>
            <Label className="required-field">GSTIN</Label>
            <Input
              {...formik.getFieldProps('GSTIN')}
              error={formik.touched.GSTIN && !!formik.errors.GSTIN}
              className="text-[#121b38] mt-1 mb-2"
            />
            <Label className="required-field">Company name as on GST</Label>
            <Input
              {...formik.getFieldProps('company_name_on_gst')}
              error={formik.touched.company_name_on_gst && !!formik.errors.company_name_on_gst}
              className="text-[#121b38] mt-1 mb-2"
            />
          </>
        )}
      </div>
    </>
  );
};
