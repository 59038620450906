import React from 'react';
import { Card, Label } from '../../../flexyui';
import IntegrationItem from './integration-item';
import * as Unicons from '@iconscout/react-unicons';
import { AnalyticsStatusQuery } from '../../../../graphql';
import { ReactComponent as ElevenZa } from '../../../../assets/images/11za.svg';
import { ReactComponent as MetaSVG } from '../../../../assets/images/facebook.svg';
import { ReactComponent as GoogleAdsSVG } from '../../../../assets/images/google-ads.svg';
import { ReactComponent as GoogleAnalyticsSVG } from '../../../../assets/images/google-analytics.svg';

type Props = {
  analyticsData: AnalyticsStatusQuery | undefined;
  loading: boolean;
};

export const IntegrationsOptions: React.FC<Props> = ({ analyticsData, loading }) => {
  return (
    <>
      <Label size="md" className="text-base text-[#2A324C]">
        Ads & Analytics
      </Label>
      <Card className="h-fit mt-4 mb-8 overflow-hidden">
        <IntegrationItem
          loading={loading}
          icon={<MetaSVG />}
          integration="META_PIXELS"
          serviceName="Meta Pixels"
          route="/settings/integrations/meta"
          enabled={analyticsData?.analytics?.meta?.enabled || false}
        />
        <hr />
        <IntegrationItem
          loading={loading}
          icon={<GoogleAnalyticsSVG />}
          integration="GOOGLE_ANALYTICS"
          serviceName="Google Analytics"
          route="/settings/integrations/google-analytics"
          enabled={analyticsData?.analytics?.google_analytics?.enabled || false}
        />
        <hr />
        <IntegrationItem
          loading={loading}
          icon={<GoogleAdsSVG />}
          integration="GOOGLE_ADS"
          serviceName="Google Ads"
          route="/settings/integrations/google-ads"
          enabled={analyticsData?.analytics?.google_ads?.enabled || false}
        />
      </Card>

      <Label size="md" className="text-base text-[#2A324C]">
        Marketing Automation
      </Label>
      <Card className="h-fit mt-4 mb-8 overflow-hidden">
        <IntegrationItem
          loading={loading}
          icon={<ElevenZa />}
          integration="11_ZA"
          serviceName="11za"
          route="/settings/integrations/11za"
          enabled="NONE"
        />
        <hr />
        <IntegrationItem
          loading={loading}
          icon={<Unicons.UilShoppingCart />}
          integration="ACR_WEBHOOKS"
          serviceName="Abandoned Cart Webhooks"
          route="/settings/integrations/acr"
          enabled="NONE"
        />
      </Card>
    </>
  );
};
